// @flow
import React from "react";

import "./styles.scss";

import { useNavigate } from "react-router";

import {
  Notices,
  FileUpload /*, HealthStatus */,
  FileProcessingStatus
} from "./components/";

import { Page, Header /* Contact */ } from "../../components";

import { isMobile } from "react-device-detect";

type Props = { handleUpload: Function };

const Dashboard = (props: Props) => {
  const navigate = useNavigate();

  return (
    <Page title="dashboard" {...props}>
      <div
        className={`DashboardPageContent ${
          isMobile ? "mobile" : "browser"
        }`}
      >
        <div
          style={{
            display: "grid",
            gridGap: "1em",
            gridTemplateRows: "1fr 1fr 1fr"
          }}
        >
          <Header title="Welcome"></Header>
          <input
            style={{ width: "100%" }}
            type="button"
            value="Invite a Friend"
            onClick={() => navigate("/app/account/invite")}
          />

          <input
            style={{ width: "100%" }}
            type="button"
            value="Get Help"
            onClick={() => navigate("/app/account/contact")}
          />
        </div>

        <Notices {...props} />
        {/* <HealthStatus {...props} /> */}

        <FileProcessingStatus {...props} />
        <FileUpload
          showToast={props.showToast}
          fileUploading={props.fields.components.upload_files.loading}
          handleUpload={(files) => props.uploadFiles(files)}
        />
      </div>
    </Page>
  );
};

Dashboard.defaultProps = {
  handleUpload: () => console.log("Dashboard - handleUpload()")
};
export default Dashboard;
