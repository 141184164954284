// @flow

import React from "react";

import { Contact } from "../../../components";

type Props = {};

const GetHelp = (props: Props) => {
  return (
    <div className="RightPanelContent">
      <Contact {...props} />
    </div>
  );
};

GetHelp.defaultProps = {};
export default GetHelp;
