import React from "react";

import "./styles.scss";

const Toolbox = (props) => {
  return (
    <div className={`ToolboxLayout`}>
      <div className="ToolboxWrapper">
        <div className="Toolbox">
          <header>
            <h1>{props.title}</h1>
          </header>
          <div className="Tools">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Toolbox;
