export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";
export const CONSOLE_LOG = "CONSOLE_LOG";
export const FLY_TO = "FLY_TO";
export const SET_ZOOM = "SET_ZOOM";
export const DISPLAY_MODAL = "DISPLAY_MODAL";
export const DISMISS_MODAL = "DISMISS_MODAL";

export const DISMISS_BROWSER_WARNING = "DISMISS_BROWSER_WARNING";
export const DISMISS_INVESTMENT_BANNER = "DISMISS_INVESTMENT_BANNER";

export const DISPLAY_OK_MODAL = "DISPLAY_OK_MODAL";
export const DISMISS_OK_MODAL = "DISMISS_OK_MODAL";

export const SET_LAYER_VARIABLE = "SET_LAYER_VARIABLE";

export const SELECT_FIELD = "SELECT_FIELD";
export const SELECT_REPORT = "SELECT_REPORT";
export const SELECT_SIMULATION = "SELECT_SIMULATION";
export const SELECT_FARM = "SELECT_FARM";
export const SELECT_PROFILE = "SELECT_PROFILE";
export const SELECT_YIELD_ENVIRONMENT = "SELECT_YIELD_ENVIRONMENT";
export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";

export const GET_FILE_PROCESSING_STATUS =
  "GET_FILE_PROCESSING_STATUS";
export const GET_FILE_PROCESSING_STATUS_SUCCESS =
  "GET_FILE_PROCESSING_STATUS_SUCCESS";
export const GET_FILE_PROCESSING_STATUS_FAIL =
  "GET_FILE_PROCESSING_STATUS_FAIL";

export const GET_FIELDS = "GET_FIELDS";
export const GET_FIELDS_SUCCESS = "GET_FIELDS_SUCCESS";
export const GET_FIELDS_FAIL = "GET_FIELDS_FAIL";

export const GET_NEARBY_FIELDS = "GET_NEARBY_FIELDS";
export const GET_NEARBY_FIELDS_SUCCESS = "GET_NEARBY_FIELDS_SUCCESS";
export const GET_NEARBY_FIELDS_FAIL = "GET_NEARBY_FIELDS_FAIL";

export const UPDATE_FIELD = "UPDATE_FIELD";
export const UPDATE_FIELD_SUCCESS = "UPDATE_FIELD_SUCCESS";
export const UPDATE_FIELD_FAIL = "UPDATE_FIELD_FAIL";

export const DELETE_FIELD = "DELETE_FIELD";
export const DELETE_FIELD_SUCCESS = "DELETE_FIELD_SUCCESS";
export const DELETE_FIELD_FAIL = "DELETE_FIELD_FAIL";

export const UPDATE_BOUNDARY = "UPDATE_BOUNDARY";
export const UPDATE_BOUNDARY_SUCCESS = "UPDATE_BOUNDARY_SUCCESS";
export const UPDATE_BOUNDARY_FAIL = "UPDATE_BOUNDARY_FAIL";

export const GET_FARMS = "GET_FARMS";
export const GET_FARMS_SUCCESS = "GET_FARMS_SUCCESS";
export const GET_FARMS_FAIL = "GET_FARMS_FAIL";
export const GET_FARMS_CLEAR = "GET_FARMS_CLEAR";

export const ADD_FARM = "ADD_FARM";
export const ADD_FARM_SUCCESS = "ADD_FARM_SUCCESS";
export const ADD_FARM_FAIL = "ADD_FARM_FAIL";
export const ADD_FARM_CLEAR = "ADD_FARM_CLEAR";

export const UPDATE_FARM = "UPDATE_FARM";
export const UPDATE_FARM_SUCCESS = "UPDATE_FARM_SUCCESS";
export const UPDATE_FARM_FAIL = "UPDATE_FARM_FAIL";
export const UPDATE_FARM_CLEAR = "UPDATE_FARM_CLEAR";

export const DELETE_FARM = "DELETE_FARM";
export const DELETE_FARM_SUCCESS = "DELETE_FARM_SUCCESS";
export const DELETE_FARM_FAIL = "DELETE_FARM_FAIL";
export const DELETE_FARM_CLEAR = "DELETE_FARM_CLEAR";

export const ASSIGN_FIELD_TO_FARM = "ASSIGN_FIELD_TO_FARM";
export const ASSIGN_FIELD_TO_FARM_SUCCESS =
  "ASSIGN_FIELD_TO_FARM_SUCCESS";
export const ASSIGN_FIELD_TO_FARM_FAIL = "ASSIGN_FIELD_TO_FARM_FAIL";
export const ASSIGN_FIELD_TO_FARM_CLEAR =
  "ASSIGN_FIELD_TO_FARM_CLEAR";

export const GET_HEALTH_STATUS = "GET_HEALTH_STATUS";
export const GET_HEALTH_STATUS_SUCCESS = "GET_HEALTH_STATUS_SUCCESS";
export const GET_HEALTH_STATUS_FAIL = "GET_HEALTH_STATUS_FAIL";

export const GET_AVAILABLE_LAYERS = "GET_AVAILABLE_LAYERS";
export const GET_AVAILABLE_LAYERS_SUCCESS =
  "GET_AVAILABLE_LAYERS_SUCCESS";
export const GET_AVAILABLE_LAYERS_FAIL = "GET_AVAILABLE_LAYERS_FAIL";

export const GET_LAYER = "GET_LAYER";
export const GET_LAYER_SUCCESS = "GET_LAYER_SUCCESS";
export const GET_LAYER_FAIL = "GET_LAYER_FAIL";

export const REMOVE_LAYER = "REMOVE_LAYER";
export const REMOVE_LAYER_SUCCESS = "REMOVE_LAYER_SUCCESS";
export const REMOVE_LAYER_FAIL = "REMOVE_LAYER_FAIL";

export const GET_LAYER_GEOSPATIAL = "GET_LAYER_GEOSPATIAL";
export const GET_LAYER_GEOSPATIAL_SUCCESS =
  "GET_LAYER_GEOSPATIAL_SUCCESS";
export const GET_LAYER_GEOSPATIAL_FAIL = "GET_LAYER_GEOSPATIAL_FAIL";

export const GET_BOUNDARY_GEOJSON = "GET_BOUNDARY_GEOJSON";
export const GET_BOUNDARY_GEOJSON_SUCCESS =
  "GET_BOUNDARY_GEOJSON_SUCCESS";
export const GET_BOUNDARY_GEOJSON_FAIL = "GET_BOUNDARY_GEOJSON_FAIL";

export const RUN_SIMULATION = "RUN_SIMULATION";
export const RUN_SIMULATION_SUCCESS = "RUN_SIMULATION_SUCCESS";
export const RUN_SIMULATION_FAIL = "RUN_SIMULATION_FAIL";

export const DOWNLOAD_PRESCRIPTION_SHAPEFILE =
  "DOWNLOAD_PRESCRIPTION_SHAPEFILE";
export const DOWNLOAD_PRESCRIPTION_SHAPEFILE_SUCCESS =
  "DOWNLOAD_PRESCRIPTION_SHAPEFILE_SUCCESS";
export const DOWNLOAD_PRESCRIPTION_SHAPEFILE_FAIL =
  "DOWNLOAD_PRESCRIPTION_SHAPEFILE_FAIL";

export const LOG_IN = "LOG_IN";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAIL = "LOG_IN_FAIL";

export const LOG_IN_STATUS = "LOG_IN_STATUS";
export const LOG_IN_STATUS_SUCCESS = "LOG_IN_STATUS_SUCCESS";
export const LOG_IN_STATUS_FAIL = "LOG_IN_STATUS_FAIL";

export const LOG_OUT = "LOG_OUT";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_FAIL = "LOG_OUT_FAIL";

export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const INVITE = "INVITE";
export const INVITE_SUCCESS = "INVITE_SUCCESS";
export const INVITE_FAIL = "INVITE_FAIL";

export const GET_REFERRALS = "GET_REFERRALS";
export const GET_REFERRALS_SUCCESS = "GET_REFERRALS_SUCCESS";
export const GET_REFERRALS_FAIL = "GET_REFERRALS_FAIL";

export const GET_FILE_STATUS = "GET_FILE_STATUS";
export const GET_FILE_STATUS_SUCCESS = "GET_FILE_STATUS_SUCCESS";
export const GET_FILE_STATUS_FAIL = "GET_FILE_STATUS_FAIL";

export const GET_FILE_YEARS = "GET_FILE_YEARS";
export const GET_FILE_YEARS_SUCCESS = "GET_FILE_YEARS_SUCCESS";
export const GET_FILE_YEARS_FAIL = "GET_FILE_YEARS_FAIL";

export const REPROCESS_FILE = "REPROCESS_FILE";
export const REPROCESS_FILE_SUCCESS = "REPROCESS_FILE_SUCCESS";
export const REPROCESS_FILE_FAIL = "REPROCESS_FILE_FAIL";

export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL";

export const REPROCESS_SEED_FIT = "REPROCESS_SEED_FIT";
export const REPROCESS_SEED_FIT_SUCCESS =
  "REPROCESS_SEED_FIT_SUCCESS";
export const REPROCESS_SEED_FIT_FAIL = "REPROCESS_SEED_FIT_FAIL";

export const REPROCESS_FERTILITY = "REPROCESS_FERTILITY";
export const REPROCESS_FERTILITY_SUCCESS =
  "REPROCESS_FERTILITY_SUCCESS";
export const REPROCESS_FERTILITY_FAIL = "REPROCESS_FERTILITY_FAIL";

export const VALIDATE_SEEDS = "VALIDATE_SEEDS";
export const VALIDATE_SEEDS_SUCCESS = "VALIDATE_SEEDS_SUCCESS";
export const VALIDATE_SEEDS_FAIL = "VALIDATE_SEEDS_FAIL";

export const GET_PLANTED_SEEDS = "GET_PLANTED_SEEDS";
export const GET_PLANTED_SEEDS_SUCCESS = "GET_PLANTED_SEEDS_SUCCESS";
export const GET_PLANTED_SEEDS_FAIL = "GET_PLANTED_SEEDS_FAIL";

export const UPDATE_SEED_NAME = "UPDATE_SEED_NAME";
export const UPDATE_SEED_NAME_SUCCESS = "UPDATE_SEED_NAME_SUCCESS";
export const UPDATE_SEED_NAME_FAIL = "UPDATE_SEED_NAME_FAIL";

export const REPROCESS_SIMULATION = "REPROCESS_SIMULATION";
export const REPROCESS_SIMULATION_SUCCESS =
  "REPROCESS_SIMULATION_SUCCESS";
export const REPROCESS_SIMULATION_FAIL = "REPROCESS_SIMULATION_FAIL";

export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const REQUEST_PASSWORD_RESET_SUCCESS =
  "REQUEST_PASSWORD_RESET_SUCCESS";
export const REQUEST_PASSWORD_RESET_FAIL =
  "REQUEST_PASSWORD_RESET_FAIL";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT";
export const CONFIRM_ACCOUNT_SUCCESS = "CONFIRM_ACCOUNT_SUCCESS";
export const CONFIRM_ACCOUNT_FAIL = "CONFIRM_ACCOUNT_FAIL";

export const UPDATE_ACCOUNT_SETTINGS = "UPDATE_ACCOUNT_SETTINGS";
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS =
  "UPDATE_ACCOUNT_SETTINGS_SUCCESS";
export const UPDATE_ACCOUNT_SETTINGS_FAIL =
  "UPDATE_ACCOUNT_SETTINGS_FAIL";
export const UPDATE_ACCOUNT_SETTINGS_CLEAR =
  "UPDATE_ACCOUNT_SETTINGS_CLEAR";

export const UPDATE_LOGIN_INFO = "UPDATE_LOGIN_INFO";
export const UPDATE_LOGIN_INFO_SUCCESS = "UPDATE_LOGIN_INFO_SUCCESS";
export const UPDATE_LOGIN_INFO_FAIL = "UPDATE_LOGIN_INFO_FAIL";
export const UPDATE_LOGIN_INFO_CLEAR = "UPDATE_LOGIN_INFO_CLEAR";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const GET_INBOX = "GET_INBOX";
export const GET_INBOX_SUCCESS = "GET_INBOX_SUCCESS";
export const GET_INBOX_FAIL = "GET_INBOX_FAIL";

export const MARK_MESSAGE_READ = "MARK_MESSAGE_READ";
export const MARK_MESSAGE_READ_SUCCESS = "MARK_MESSAGE_READ_SUCCESS";
export const MARK_MESSAGE_READ_FAIL = "MARK_MESSAGE_READ_FAIL";

export const ARCHIVE_MESSAGE = "ARCHIVE_MESSAGE";
export const ARCHIVE_MESSAGE_SUCCESS = "ARCHIVE_MESSAGE_SUCCESS";
export const ARCHIVE_MESSAGE_FAIL = "ARCHIVE_MESSAGE_FAIL";

export const GET_TYPEAHEAD_SUGGESTIONS = "GET_TYPEAHEAD_SUGGESTIONS";
export const GET_TYPEAHEAD_SUGGESTIONS_SUCCESS =
  "GET_TYPEAHEAD_SUGGESTIONS_SUCCESS";
export const GET_TYPEAHEAD_SUGGESTIONS_FAIL =
  "GET_TYPEAHEAD_SUGGESTIONS_FAIL";

export const CLEAR_TYPEAHEAD_SUGGESTIONS =
  "CLEAR_TYPEAHEAD_SUGGESTIONS";

export const CONTACT_TEAM = "CONTACT_TEAM";
export const CONTACT_TEAM_SUCCESS = "CONTACT_TEAM_SUCCESS";
export const CONTACT_TEAM_FAIL = "CONTACT_TEAM_FAIL";

export const REQUEST_TO_BUY_UNITS = "REQUEST_TO_BUY_UNITS";
export const REQUEST_TO_BUY_UNITS_SUCCESS =
  "REQUEST_TO_BUY_UNITS_SUCCESS";
export const REQUEST_TO_BUY_UNITS_FAIL = "REQUEST_TO_BUY_UNITS_FAIL";

export const GET_SEED_COMPANIES = "GET_SEED_COMPANIES";
export const GET_SEED_COMPANIES_SUCCESS =
  "GET_SEED_COMPANIES_SUCCESS";
export const GET_SEED_COMPANIES_FAIL = "GET_SEED_COMPANIES_FAIL";

export const GET_SEEDS_BY_COMPANY = "GET_SEEDS_BY_COMPANY";
export const GET_SEEDS_BY_COMPANY_SUCCESS =
  "GET_SEEDS_BY_COMPANY_SUCCESS";
export const GET_SEEDS_BY_COMPANY_FAIL = "GET_SEEDS_BY_COMPANY_FAIL";

export const UPDATE_MANAGED_SEED = "UPDATE_MANAGED_SEED";
export const UPDATE_MANAGED_SEED_SUCCESS =
  "UPDATE_MANAGED_SEED_SUCCESS";
export const UPDATE_MANAGED_SEED_FAIL = "UPDATE_MANAGED_SEED_FAIL";

export const DELETE_MANAGED_SEED = "DELETE_MANAGED_SEED";
export const DELETE_MANAGED_SEED_SUCCESS =
  "DELETE_MANAGED_SEED_SUCCESS";
export const DELETE_MANAGED_SEED_FAIL = "DELETE_MANAGED_SEED_FAIL";

export const ADMIN_GET_ALL_USERS = "ADMIN_GET_ALL_USERS";
export const ADMIN_GET_ALL_USERS_SUCCESS =
  "ADMIN_GET_ALL_USERS_SUCCESS";
export const ADMIN_GET_ALL_USERS_FAIL = "ADMIN_GET_ALL_USERS_FAIL";

export const ADMIN_UPDATE_USER = "ADMIN_UPDATE_USER";
export const ADMIN_UPDATE_USER_SUCCESS = "ADMIN_UPDATE_USER_SUCCESS";
export const ADMIN_UPDATE_USER_FAIL = "ADMIN_UPDATE_USER_FAIL";

export const ADMIN_DELETE_USER = "ADMIN_DELETE_USER";
export const ADMIN_DELETE_USER_SUCCESS = "ADMIN_DELETE_USER_SUCCESS";
export const ADMIN_DELETE_USER_FAIL = "ADMIN_DELETE_USER_FAIL";

export const GET_PRODUCT_PRICES = "GET_PRODUCT_PRICES";
export const GET_PRODUCT_PRICES_SUCCESS =
  "GET_PRODUCT_PRICES_SUCCESS";
export const GET_PRODUCT_PRICES_FAIL = "GET_PRODUCT_PRICES_FAIL";

export const GET_UNASSIGNED_PRODUCT_PRICES =
  "GET_UNASSIGNED_PRODUCT_PRICES";
export const GET_UNASSIGNED_PRODUCT_PRICES_SUCCESS =
  "GET_UNASSIGNED_PRODUCT_PRICES_SUCCESS";
export const GET_UNASSIGNED_PRODUCT_PRICES_FAIL =
  "GET_UNASSIGNED_PRODUCT_PRICES_FAIL";

export const GET_PRACTICE_PRODUCTS = "GET_PRACTICE_PRODUCTS";
export const GET_PRACTICE_PRODUCTS_SUCCESS =
  "GET_PRACTICE_PRODUCTS_SUCCESS";
export const GET_PRACTICE_PRODUCTS_FAIL =
  "GET_PRACTICE_PRODUCTS_FAIL";

export const UPDATE_PRODUCT_PRICE = "UPDATE_PRODUCT_PRICE";
export const UPDATE_PRODUCT_PRICE_SUCCESS =
  "UPDATE_PRODUCT_PRICE_SUCCESS";
export const UPDATE_PRODUCT_PRICE_FAIL = "UPDATE_PRODUCT_PRICE_FAIL";

export const UPDATE_PRACTICE_PRODUCT = "UPDATE_PRACTICE_PRODUCT";
export const UPDATE_PRACTICE_PRODUCT_SUCCESS =
  "UPDATE_PRACTICE_PRODUCT_SUCCESS";
export const UPDATE_PRACTICE_PRODUCT_FAIL =
  "UPDATE_PRACTICE_PRODUCT_FAIL";

export const COPY_PRACTICE_PRODUCT = "COPY_PRACTICE_PRODUCT";
export const COPY_PRACTICE_PRODUCT_SUCCESS =
  "COPY_PRACTICE_PRODUCT_SUCCESS";
export const COPY_PRACTICE_PRODUCT_FAIL =
  "COPY_PRACTICE_PRODUCT_FAIL";

export const UPDATE_FIELD_PRACTICES = "UPDATE_FIELD_PRACTICES";
export const UPDATE_FIELD_PRACTICES_SUCCESS =
  "UPDATE_FIELD_PRACTICES_SUCCESS";
export const UPDATE_FIELD_PRACTICES_FAIL =
  "UPDATE_FIELD_PRACTICES_FAIL";

export const DELETE_PRODUCT_PRICE = "DELETE_PRODUCT_PRICE";
export const DELETE_PRODUCT_PRICE_SUCCESS =
  "DELETE_PRODUCT_PRICE_SUCCESS";
export const DELETE_PRODUCT_PRICE_FAIL = "DELETE_PRODUCT_PRICE_FAIL";

export const DELETE_PRACTICE_PRODUCT = "DELETE_PRACTICE_PRODUCT";
export const DELETE_PRACTICE_PRODUCT_SUCCESS =
  "DELETE_PRACTICE_PRODUCT_SUCCESS";
export const DELETE_PRACTICE_PRODUCT_FAIL =
  "DELETE_PRACTICE_PRODUCT_FAIL";

export const DELETE_FIELD_PRACTICE = "DELETE_FIELD_PRACTICE";
export const DELETE_FIELD_PRACTICE_SUCCESS =
  "DELETE_FIELD_PRACTICE_SUCCESS";
export const DELETE_FIELD_PRACTICE_FAIL =
  "DELETE_FIELD_PRACTICE_FAIL";

export const GET_DATA_YEARS = "GET_DATA_YEARS";
export const GET_DATA_YEARS_SUCCESS = "GET_DATA_YEARS_SUCCESS";
export const GET_DATA_YEARS_FAIL = "GET_DATA_YEARS_FAIL";
