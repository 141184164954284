import * as ACTION from "../constants";

export const fieldsReducer = (
  state = {
    summary: [],
    detail: {},
    components: {
      get_fields: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_field: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_boundary: {
        loading: false,
        error: undefined,
        success: undefined
      },
      get_available_layers: {
        loading: false,
        error: undefined,
        success: undefined
      },
      upload_files: {
        loading: false,
        error: undefined,
        success: undefined
      },
      get_layer: {
        loading: false,
        error: undefined,
        success: undefined
      },
      get_layer_geospatial: {
        loading: false,
        error: undefined,
        success: undefined
      },
      run_simulation: {
        loading: false,
        error: undefined,
        success: undefined
      },
      get_boundary_geojson: {
        loading: false,
        error: undefined,
        success: undefined
      },
      download_prescription_shapefile: {
        loading: false,
        error: undefined,
        success: undefined
      },
      assign_field_to_farm: {
        loading: false,
        error: undefined,
        success: undefined
      }
    }
  },
  action
) => {
  switch (action.type) {
    case ACTION.GET_AVAILABLE_LAYERS:
      return {
        ...state,
        components: {
          ...state.components,
          get_available_layers: {
            ...state.components.get_available_layers,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.GET_AVAILABLE_LAYERS_SUCCESS:
      var field = action.data.field_id;
      var fieldDetails = state.detail[field];

      if (!fieldDetails) {
        fieldDetails = { layers: {}, geospatial_layers: {} };
      }

      action.data.layers.forEach((element) => {
        element.dropdown_name = `${element.display_name} ${
          element.year ? element.year : ""
        }`;
        element.layer_key = `${element.name} ${element.year}`;
      });

      fieldDetails.availableLayers = action.data.layers;

      return {
        ...state,
        components: {
          ...state.components,
          get_available_layers: {
            ...state.components.get_available_layers,
            loading: false,
            response: action.data,
            success: action.data
          }
        },
        detail: {
          ...state.detail,
          [field]: fieldDetails
        }
      };
    case ACTION.GET_AVAILABLE_LAYERS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_available_layers: {
            ...state.components.get_available_layers,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.GET_NEARBY_FIELDS:
      return {
        ...state,
        components: {
          ...state.components,
          get_fields: {
            ...state.components.get_fields,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.GET_NEARBY_FIELDS_SUCCESS:
      field = action.data.field_id;
      fieldDetails = state.detail[field];

      if (!fieldDetails) {
        fieldDetails = { layers: {}, geospatial_layers: {} };
      }

      fieldDetails.layers.nearby_fields = {
        name: "nearby_fields",
        display_name: "Nearby Fields",
        loc_name: "nearby_fields",
        isOpen: false
      };

      fieldDetails.geospatial_layers.nearby_fields = {
        sublayers: {
          "Nearby Fields": {
            display_name: "Nearby Fields",
            name: "nearby_fields",
            data: action.data.geojson,
            showOnMap: true,
            opacity: 100
          }
        }
      };

      return {
        ...state,
        components: {
          ...state.components,
          get_fields: {
            ...state.components.get_fields,
            loading: false,
            response: action.data,
            success: action.data
          }
        },
        detail: {
          ...state.detail,
          [field]: fieldDetails
        }
      };
    case ACTION.GET_NEARBY_FIELDS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_fields: {
            ...state.components.get_fields,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.GET_LAYER:
      return {
        ...state,
        components: {
          ...state.components,
          get_layer: {
            ...state.components.get_layer,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.GET_LAYER_SUCCESS:
      //TODO: report years
      field = action.data.field_id;
      var name = action.data.name;
      var year = action.data.year;
      var loc_name = action.data.loc_name;
      fieldDetails = state.detail[field];

      if (!fieldDetails) {
        fieldDetails = { layers: {}, geospatial_layers: {} };
      }

      if (!fieldDetails.layers[`${name}-${year}`]) {
        fieldDetails.layers[`${name}-${year}`] = {};
      }

      fieldDetails.layers[`${name}-${year}`] = {
        ...fieldDetails.layers[`${name}-${year}`],
        ...action.data,
        isOpen: true
      };

      return {
        ...state,
        components: {
          ...state.components,
          get_layer: {
            ...state.components.get_layer,
            loading: false,
            response: action.data,
            success: true
          }
        },
        detail: {
          ...state.detail,
          [field]: fieldDetails
        }
      };
    case ACTION.GET_LAYER_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_layer: {
            ...state.components.get_layer,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.REMOVE_LAYER:
      //TODO: report years
      field = action.fieldId;
      name = action.name;
      year = action.year;
      fieldDetails = state.detail[field];

      if (!fieldDetails) {
        fieldDetails = { layers: {}, geospatial_layers: {} };
      }

      if (!fieldDetails.layers[`${name}-${year}`]) {
        fieldDetails.layers[`${name}-${year}`] = {};
      }

      loc_name = fieldDetails.layers[`${name}-${year}`].loc_name;

      delete fieldDetails.layers[`${name}-${year}`];

      //only clean up the geo layer if nobody else is using it
      if (
        !Object.values(fieldDetails.layers).some(
          (layer) =>
            layer.loc_name === loc_name && layer.year === year
        )
      ) {
        delete fieldDetails.geospatial_layers[`${loc_name}-${year}`];
      }

      return {
        ...state,
        components: {
          ...state.components,
          get_layer: {
            ...state.components.get_layer,
            loading: false,
            response: action.data,
            success: true
          }
        },
        detail: {
          ...state.detail,
          [field]: fieldDetails
        }
      };

    case ACTION.GET_LAYER_GEOSPATIAL:
      return {
        ...state,
        components: {
          ...state.components,
          get_layer_geospatial: {
            ...state.components.get_layer_geospatial,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.GET_LAYER_GEOSPATIAL_SUCCESS:
      //TODO report years???
      field = action.data.field_id;
      year = action.data.year;
      loc_name = action.data.name;
      var display_name = action.data.display_name;
      var contentType = action.data.loc_type;

      fieldDetails = state.detail[field];

      if (!fieldDetails) {
        fieldDetails = { layers: {}, geospatial_layers: {} };
      }

      if (!fieldDetails.geospatial_layers[`${loc_name}-${year}`]) {
        fieldDetails.geospatial_layers[`${loc_name}-${year}`] = {};
      }

      if (contentType === "png") {
        //loop thru rasters

        var sublayers = {};
        action.data.png_layers.map((raster_layer) => {
          sublayers = {
            ...sublayers,
            [raster_layer.display_name]: {
              display_name: raster_layer.display_name,
              bounds: [
                [
                  raster_layer.boundaries.bottom_right[1],
                  raster_layer.boundaries.bottom_right[0]
                ],
                [
                  raster_layer.boundaries.top_left[1],
                  raster_layer.boundaries.top_left[0]
                ]
              ],
              showOnMap: true,
              opacity: 100,
              content_type: contentType,
              data: raster_layer.url
            }
          };
        });
        fieldDetails.geospatial_layers[`${loc_name}-${year}`] = {
          year: year,
          sublayers: sublayers
        };
      } else {
        fieldDetails.geospatial_layers[`${loc_name}-${year}`] = {
          year: year,
          sublayers: {
            [display_name]: {
              display_name: display_name,
              showOnMap: true,
              opacity: 100,
              content_type: contentType,
              data: action.data.geojson
            }
          }
        };
      }

      return {
        ...state,
        components: {
          ...state.components,
          get_layer_geospatial: {
            ...state.components.get_layer_geospatial,
            loading: false,
            success: true
          }
        },
        detail: {
          ...state.detail,
          [field]: fieldDetails
        }
      };
    case ACTION.GET_LAYER_GEOSPATIAL_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_layer_geospatial: {
            ...state.components.get_layer_geospatial,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.RUN_SIMULATION:
      return {
        ...state,
        components: {
          ...state.components,
          run_simulation: {
            ...state.components.run_simulation,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.RUN_SIMULATION_SUCCESS:
      //TODO: report years
      field = action.data.field_id;
      var reportType = action.data.name;
      var reportYear = action.data.year;
      fieldDetails = state.detail[field];

      if (!fieldDetails) {
        fieldDetails = { layers: {}, geospatial_layers: {} };
      }

      if (!fieldDetails.layers[`${reportType}-${reportYear}`]) {
        fieldDetails.layers[`${reportType}-${reportYear}`] = {};
      }

      fieldDetails.layers[`${reportType}-${reportYear}`] = {
        ...fieldDetails.layers[`${reportType}-${reportYear}`],
        ...action.data,
        isOpen: true
      };

      return {
        ...state,
        components: {
          ...state.components,
          run_simulation: {
            ...state.components.run_simulation,
            loading: false,
            response: action.data,
            success: true
          }
        },
        detail: {
          ...state.detail,
          [field]: fieldDetails
        }
      };
    case ACTION.RUN_SIMULATION_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          run_simulation: {
            ...state.components.run_simulation,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.SET_LAYER_VARIABLE:
      //TODO report years???
      field = action.data.field_id;
      let layerGroup = action.data.layerGroup;
      let layer = action.data.layer;
      let sublayer = action.data.sublayer;
      let variableName = action.data.variableName;
      let variableValue = action.data.variableValue;

      fieldDetails = state.detail[field];
      if (layerGroup === "geospatial_layers") {
        fieldDetails[layerGroup][layer].sublayers[sublayer][
          variableName
        ] = variableValue;
      } else {
        fieldDetails[layerGroup][layer][variableName] = variableValue;
      }

      return {
        ...state,
        detail: {
          ...state.detail,
          [field]: fieldDetails
        }
      };

    case ACTION.GET_BOUNDARY_GEOJSON:
      return {
        ...state,
        components: {
          ...state.components,
          get_boundary_geojson: {
            ...state.components.get_boundary_geojson,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.GET_BOUNDARY_GEOJSON_SUCCESS:
      //TODO report years???
      field = action.data.field_id;
      reportType = action.data.type;
      reportYear = action.data.year;

      fieldDetails = state.detail[field];

      if (!fieldDetails) {
        fieldDetails = { layers: {}, geospatial_layers: {} };
      }

      fieldDetails.layers.boundary = {
        name: "boundary",
        display_name: "Boundary",
        loc_name: "boundary",
        isOpen: false
      };

      fieldDetails.geospatial_layers.boundary = {
        sublayers: {
          Boundary: {
            display_name: "Boundary",
            name: "boundary",
            data: action.data.geojson,
            showOnMap: true,
            opacity: 100
          }
        }
      };

      return {
        ...state,
        components: {
          ...state.components,
          get_boundary_geojson: {
            ...state.components.get_boundary_geojson,
            loading: false,
            success: true
          }
        },
        detail: {
          ...state.detail,
          [field]: fieldDetails
        }
      };
    case ACTION.GET_BOUNDARY_GEOJSON_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_boundary_geojson: {
            ...state.components.get_boundary_geojson,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.DOWNLOAD_PRESCRIPTION_SHAPEFILE:
      return {
        ...state,
        components: {
          ...state.components,
          download_prescription_shapefile: {
            ...state.components.download_prescription_shapefile,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.DOWNLOAD_PRESCRIPTION_SHAPEFILE_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          download_prescription_shapefile: {
            ...state.components.download_prescription_shapefile,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.DOWNLOAD_PRESCRIPTION_SHAPEFILE_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          download_prescription_shapefile: {
            ...state.components.download_prescription_shapefile,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.UPDATE_BOUNDARY:
      return {
        ...state,
        components: {
          ...state.components,
          update_boundary: {
            ...state.components.update_boundary,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.UPDATE_BOUNDARY_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_boundary: {
            ...state.components.update_boundary,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.UPDATE_BOUNDARY_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_boundary: {
            ...state.components.update_boundary,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.UPDATE_FIELD:
      return {
        ...state,
        components: {
          ...state.components,
          update_field: {
            ...state.components.update_field,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.UPDATE_FIELD_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_field: {
            ...state.components.update_field,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.UPDATE_FIELD_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_field: {
            ...state.components.update_field,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.UPLOAD_FILE:
      return {
        ...state,
        components: {
          ...state.components,
          upload_files: {
            ...state.components.upload_files,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.UPLOAD_FILE_SUCCESS:
      //TODO: Files API doesn't reurn the fields summary??
      return {
        ...state,
        components: {
          ...state.components,
          upload_files: {
            ...state.components.upload_files,
            loading: false,
            success: true
          }
        }
        // summary: action.data.features
      };
    case ACTION.UPLOAD_FILE_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          upload_files: {
            ...state.components.upload_files,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.GET_FIELDS:
      return {
        ...state,
        components: {
          ...state.components,
          get_fields: {
            ...state.components.get_fields,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.GET_FIELDS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          get_fields: {
            ...state.components.get_fields,
            loading: false,
            success: true
          }
        },
        summary: action.data.features
      };
    case ACTION.GET_FIELDS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_fields: {
            ...state.components.get_fields,
            loading: false,
            error: action.error
          }
        }
      };

    default:
      return state;
  }
};
