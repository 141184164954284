import React from "react";

import { OpacitySlider } from "../";
import { Header } from "../../../../components";

import "./styles.scss";

const CollapsibleLayer = (props) => {
  const { activeFieldId } = props.app;

  const {
    setLayerVariable,
    layer,
    layerKey,
    geoLayerKey,
    geospatial_layer
  } = props;

  return (
    <div className="CollapsibleLayer">
      {geospatial_layer &&
        geospatial_layer.sublayers &&
        Object.keys(geospatial_layer.sublayers).map((sublayerKey) => (
          <div
            className="CollapsibleLayerHeader"
            key={`${geoLayerKey}-${sublayerKey}`}
          >
            {geospatial_layer.sublayers[sublayerKey] && (
              <input
                name="showOnMap"
                type="checkbox"
                checked={
                  geospatial_layer.sublayers[sublayerKey].showOnMap
                }
                onChange={(event) =>
                  setLayerVariable(
                    activeFieldId,
                    "geospatial_layers",
                    geoLayerKey,
                    sublayerKey,
                    "showOnMap",
                    event.target.checked
                  )
                }
              />
            )}

            <Header
              title={`${sublayerKey} ${
                layer.year ? layer.year : ""
              } `}
            ></Header>

            {geospatial_layer.sublayers[sublayerKey] && (
              <OpacitySlider
                value={
                  geospatial_layer.sublayers[sublayerKey].opacity
                }
                onChange={(opacity) =>
                  setLayerVariable(
                    activeFieldId,
                    "geospatial_layers",
                    geoLayerKey,
                    sublayerKey,
                    "opacity",
                    opacity
                  )
                }
              />
            )}
          </div>
        ))}
      {props.children && (
        <div className="Expander">
          <div />
          <span
            onClick={() =>
              setLayerVariable(
                activeFieldId,
                "layers",
                layerKey,
                null,
                "isOpen",
                !layer.isOpen
              )
            }
          >
            {layer.isOpen ? "Collapse Report" : "Expand Report"}
          </span>

          <div />
        </div>
      )}

      {layer.isOpen && (
        <div className="LayerContent"> {props.children}</div>
      )}
    </div>
  );
};

export default CollapsibleLayer;
