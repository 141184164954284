import * as ACTION from "../constants";

import { buildJsonRequest, getErrorMessage } from "../utils";

import { showToast } from "./toast";

export const getInbox = () => {
  return buildJsonRequest(
    {
      url: "/user/notifications",
      method: "GET"
    },
    ACTION.GET_INBOX
  );
};

export const markMessageRead = (MESSAGE_ID, READ = true) => {
  return buildJsonRequest(
    {
      url: "/user/notifications",
      method: "PUT",
      data: {
        id: MESSAGE_ID,
        has_read: READ
      }
    },
    ACTION.MARK_MESSAGE_READ
  );
};

export const archiveMessage = (MESSAGE_ID, ARCHIVED = true) => {
  return buildJsonRequest(
    {
      url: "/user/notifications",
      method: "PUT",
      data: {
        id: MESSAGE_ID,
        has_archived: ARCHIVED
      }
    },
    ACTION.ARCHIVE_MESSAGE
  );
};

export const contactTeam = (message, dropdownOption) => {
  return buildJsonRequest(
    {
      url: "/user/contact",
      method: "POST",
      data: {
        message: message,
        dropdown_option: dropdownOption
      }
    },
    ACTION.CONTACT_TEAM,
    [(response) => showToast("Message sent.")],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const requestToBuyUnits = (unitBlocksRequested) => {
  return buildJsonRequest(
    {
      url: "/user/units",
      method: "POST",
      data: {
        unit_blocks_requested: unitBlocksRequested
      }
    },
    ACTION.REQUEST_TO_BUY_UNITS,
    [(response) => showToast("Your request has been sent.")],
    [(error) => showToast(getErrorMessage(error))]
  );
};

//TODO use API
// export const dismissItem = (item: object) => ({
//   type: ACTION.DISMISS_INBOX_ITEM,
//   item: item
// });
