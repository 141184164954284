// @flow

import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import {
  Header,
  TypeaheadInput,
  SliderModal
} from "../../../components";

type Props = {};

const SeedDataManagement = (props: Props) => {
  const { planted_fields, years } = props.files;
  const { updateSeedName, getPlantedSeeds, getFileYears } = props;
  const loading =
    props.files.components.update_seed_name.loading ||
    props.files.components.planted_seeds.loading;

  const [isOpen, setIsOpen] = useState(false);
  const [seedName, setSeedName] = useState("");
  const [newSeedName, setNewSeedName] = useState("");
  const [cropType, setCropType] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [updateAllFields, setUpdateAllFields] = useState(false);
  const [selectedYear, setSelectedYear] = useState(undefined);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (selectedYear) {
      getPlantedSeeds(selectedYear);
    } else if (years && years[0]) {
      //Load first year by default
      setSelectedYear(years[0]);
    } else {
      getFileYears();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years, selectedYear, getFileYears, getPlantedSeeds]);

  return (
    <div className="RightPanelContent">
      <ReactTooltip
        id="DataManagementTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />

      <Header title="Manage My Seed Data" loading={loading} />
      <p>
        <i>
          Loading this page and saving/reviewing changes can take
          several minutes due to the large amount of planting data in
          the system. Thank you for your patience.
        </i>
      </p>

      <div className="DataManagement">
        <form>
          <label>
            <span>Year:</span>
            <select
              onChange={(evt) => {
                getPlantedSeeds(evt.target.value);
                setSelectedYear(evt.target.value);
              }}
            >
              {years &&
                years
                  .sort(function (a, b) {
                    return b - a;
                  })
                  .map((option) => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
            </select>
          </label>
        </form>
        {planted_fields &&
          planted_fields
            .sort(function (a, b) {
              var textA = a.field_name.toUpperCase();
              var textB = b.field_name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .map((field) => {
              const { seeds } = field;
              return (
                <div className="Field" key={field.field_id}>
                  <h3>{field.field_name}</h3>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "1em" }}></th>
                        <th style={{ width: "1em" }} />
                        <th> Seed Company Name </th>
                        <th> Seed Name </th>
                        <th> Crop Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {seeds &&
                        seeds.map((seed, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <FontAwesomeIcon
                                  icon="pencil-alt"
                                  className="ActionIcon"
                                  data-tip="Edit line item"
                                  data-for="DataManagementTooltip"
                                  onClick={() => {
                                    setSeedName(seed.seed_name);
                                    setNewSeedName(seed.seed_name);
                                    setFieldId(field.field_id);
                                    setFieldName(field.field_name);
                                    setCropType(
                                      seed.crop_type
                                        ? seed.crop_type
                                        : "CORN"
                                    ); //default to CORN if an unknown seed
                                    setIsOpen(true);
                                  }}
                                />
                              </td>
                              <td>
                                {!seed.seed_valid && (
                                  <FontAwesomeIcon
                                    className="ActionIcon"
                                    icon="exclamation-triangle"
                                    data-tip="Invalid Seed Name"
                                    data-for="DataManagementTooltip"
                                  />
                                )}
                              </td>
                              <td>{seed.seed_company_name} </td>
                              <td>{seed.seed_name} </td>
                              <td>{seed.crop_type} </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              );
            })}
      </div>

      <SliderModal
        title={`Edit Seed Name for ${fieldName}`}
        isOpen={isOpen}
        onOutsideClick={() => setIsOpen(false)}
        footer={
          <div
            style={{
              width: "100%",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            <input
              type="button"
              value="Update Seed Name"
              onClick={() => {
                updateSeedName(
                  newSeedName,
                  seedName,
                  updateAllFields ? undefined : fieldId,
                  selectedYear,
                  cropType
                );
                setIsOpen(false);
              }}
            />

            <input
              type="button"
              value="Cancel"
              onClick={() => setIsOpen(false)}
            />
          </div>
        }
      >
        <form>
          <label>{/* <span>Seed Company:</span> */}</label>
          <label>
            <span>Seed Name:</span>
            <TypeaheadInput
              name="newSeedName"
              type="text"
              endpoint={"managed_seeds"}
              value={newSeedName}
              onChange={(e) => setNewSeedName(e.target.value)}
            />
          </label>
          <label>
            <span>Crop Type:</span>
            <select
              value={cropType}
              onChange={(evt) => {
                setCropType(evt.target.value);
              }}
            >
              {["CORN", "SOYBEANS"].map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </label>
          <label>
            <span>
              Apply this update to all my fields with this seed
              planted
            </span>
            <input
              name="terms"
              type="checkbox"
              checked={updateAllFields}
              onChange={(event) =>
                setUpdateAllFields(event.target.checked)
              }
            />
          </label>
        </form>
      </SliderModal>
    </div>
  );
};

SeedDataManagement.defaultProps = {};
export default SeedDataManagement;
