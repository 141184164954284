// @flow

import React from "react";

import { Page, FieldCard } from "../../components";

type Props = {};

const Profile = (props: Props) => {
  return (
    <Page>
      <FieldCard {...props} />
    </Page>
  );
};

Profile.defaultProps = {};
export default Profile;
