import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./styles.scss";

const AlertBar = (props) => {
  const { type, style, dismissAction } = props;

  var className, icon;
  switch (type) {
    case "success":
      className = "Success";
      icon = "check";
      break;
    case "warning":
    case "error":
      className = "Alert";
      icon = "exclamation-triangle";
      break;
    case "info":
      className = "Success";
      icon = "seedling";
      break;
    default:
      className = "Alert";
      icon = "seedling";
      break;
  }

  return (
    <div className="AlertBar" style={style}>
      <div className={`Content ${className}`}>
        {icon ? <FontAwesomeIcon icon={icon} size="1x" /> : <span />}
        {props.children}

        {dismissAction && (
          <div onClick={dismissAction} className="Close">
            x
          </div>
        )}
      </div>
    </div>
  );
};

AlertBar.defaultProps = {
  type: "warning"
};
export default AlertBar;
