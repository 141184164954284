// @flow

import React from "react";
import { Progress } from "../../../components";
import { useInterval } from "../../../utils";

type Props = {
  health: Array
};

const FileProcessingStatus = (props: Props) => {
  const { fileProcessingStatus } = props.user;
  const { isAuthenticated } = props.session;
  // var fileUploadInProgress = props.fields.components.upload_files.loading;
  const { refreshFileProcessingStatus } = props;

  useInterval(() => {
    if (isAuthenticated) {
      refreshFileProcessingStatus();
    }
  }, 30000);

  let total =
    fileProcessingStatus.pending +
    fileProcessingStatus.processed +
    fileProcessingStatus.failed;
  let progress =
    (fileProcessingStatus.processed / total).toFixed(2) * 100;

  if (total === 0) return null;

  return (
    <div className="FileProcessingStatus">
      <header>
        <h2>File Processing Status</h2>
      </header>

      <div>
        {fileProcessingStatus.processed}/{total} files processed
      </div>

      <Progress progress={`${progress}%`} />

      {/* <div className="ReportInfo">
        <span>Pending:</span>
        <span>{fileProcessingStatus.pending}</span>
      </div>
      <div className="ReportInfo">
        <span>Processed:</span>
        <span>{fileProcessingStatus.processed}</span>
      </div>
      <div className="ReportInfo">
        <span>Failed:</span>
        <span>{fileProcessingStatus.failed}</span>
      </div> */}
    </div>
  );
};

FileProcessingStatus.defaultProps = {};
export default FileProcessingStatus;
