// @flow

import React, { useState, useEffect } from "react";

import { Header } from "../../../../components";

import { Multiselect } from "multiselect-react-dropdown";

type Props = {};

const SelectLayer = (props: Props) => {
  const {
    getLayer,
    runSimulation,
    removeLayer,
    getLayerGeospatial
  } = props;

  const {
    activeFieldName,
    activeFieldId,
    activeFieldCoords
  } = props.app;

  const { detail } = props.fields;

  const {
    get_layer,
    get_available_layers,
    run_simulation
  } = props.fields.components;

  let availableLayers =
    detail && detail[activeFieldId]
      ? detail[activeFieldId].availableLayers
      : undefined;

  let loading =
    get_layer.loading ||
    get_available_layers.loading ||
    run_simulation.loading;

  const [predictiveYear, setPredictiveYear] = useState(undefined);
  const [predictiveCropType, setPredictiveCropType] = useState(
    undefined
  );

  var loadedLayers =
    activeFieldId &&
    detail[activeFieldId] &&
    detail[activeFieldId].layers
      ? Object.keys(detail[activeFieldId].layers)
      : [];

  var loadedGeoLayers =
    activeFieldId &&
    detail[activeFieldId] &&
    detail[activeFieldId].layers
      ? Object.keys(detail[activeFieldId].geospatial_layers)
      : [];

  var preselectedLayers = [];
  if (availableLayers) {
    availableLayers.forEach((layer, index) => {
      if (loadedLayers.includes(`${layer.name}-${layer.year}`)) {
        preselectedLayers.push(layer);
      }
    });
  }
  const [selectedLayers, setSelectedLayers] = useState(
    preselectedLayers
  );

  useEffect(() => {
    var preselectedLayers = [];
    if (availableLayers) {
      availableLayers.forEach((layer, index) => {
        if (loadedLayers.includes(`${layer.name}-${layer.year}`)) {
          preselectedLayers.push(layer);
        }
      });
    }
    setSelectedLayers(preselectedLayers);
  }, [activeFieldId, setSelectedLayers]);

  return (
    <React.Fragment>
      <div>
        <Header title="Data Layers" loading={loading} />

        <div className="ReportInfo">
          {activeFieldName ? (
            <React.Fragment>
              <span>Selected Field: </span>{" "}
              <span> {activeFieldName} </span>{" "}
            </React.Fragment>
          ) : (
            <div>Select a field to get started.</div>
          )}
        </div>

        {activeFieldName && !availableLayers && !loading && (
          <div>No Layers available.</div>
        )}

        {activeFieldName && availableLayers && (
          <div>
            <form>
              <Multiselect
                //selectionLimit="1"
                style={{
                  multiselectContainer: {
                    // To change css for multiselect (Width,height,etc..)
                  },
                  searchBox: {
                    // To change search box element look
                    border: "none",
                    color: "#f5d870 !important",
                    minHeight: "2.4em"
                  },
                  inputField: {
                    // To change input field position or margin
                    margin: "5px",
                    border: "1px solid #f5d870",
                    borderRadius: "8px",
                    color: "#f5d870",

                    height: "2.4em"
                  },
                  chips: {
                    // To change css chips(Selected options)
                    backgroundColor: "forest-green",
                    color: "#f5d870",
                    height: "1em"
                  },
                  optionContainer: {
                    // To change css for option container
                    border: "1px solid #f5d870",
                    backgroundColor: "#3e342e"
                  },
                  option: {
                    // To change css for dropdown options
                  },
                  groupHeading: {
                    // To chanage group heading style
                  }
                }}
                placeholder={"Select layers..."}
                options={availableLayers} // Options to display in the dropdown
                selectedValues={selectedLayers} // Preselected value to persist in dropdown
                onSelect={(evt) => {
                  setSelectedLayers(evt);
                  evt.forEach((element) => {
                    if (
                      element.name !== "predictive_seed_fit" &&
                      !loadedLayers.includes(
                        `${element.name}-${element.year}`
                      )
                    ) {
                      // console.log(
                      //   "loading layer-" + element.name + element.year
                      // );
                      getLayer(
                        element.name,
                        activeFieldId,
                        element.year,
                        activeFieldCoords
                      );

                      if (
                        !loadedGeoLayers.includes(
                          `${element.loc_name}-${element.year}`
                        )
                      ) {
                        getLayerGeospatial(
                          element.loc_name,
                          activeFieldId,
                          element.year,
                          activeFieldCoords
                        );
                      }
                    }
                  });
                }} // Function will trigger on select event
                onRemove={(evt, layer) => {
                  setSelectedLayers(evt);
                  removeLayer(layer.name, activeFieldId, layer.year);
                }} // Function will trigger on remove event
                displayValue="dropdown_name" // Property name to display in the dropdown options
              />{" "}
            </form>
          </div>
        )}
      </div>

      {selectedLayers.some(
        (layer) => layer.name === "predictive_seed_fit"
      ) && (
        <div>
          <Header title="Predictive Seed Fit Options" />

          <form>
            <label>
              <span>Crop Type:</span>
              <select
                value={predictiveCropType}
                onChange={(evt) =>
                  setPredictiveCropType(evt.target.value)
                }
              >
                <option value={undefined}>Select</option>;
                {["CORN", "SOYBEANS"].map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
            </label>
            <label>
              <span>Year:</span>

              <select
                value={predictiveYear}
                onChange={(evt) => {
                  setPredictiveYear(evt.target.value);
                }}
              >
                <option value={undefined}>Select</option>;
                {["2021", "2020", "2019", "2018"].map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
            </label>

            <span className="ReportInfo">
              <input
                type="button"
                value="Run Simulation"
                disabled={
                  !predictiveYear || !predictiveCropType || loading
                }
                onClick={() =>
                  runSimulation(
                    "predictive_seed_fit",
                    activeFieldId,
                    predictiveYear,
                    predictiveCropType
                  )
                }
              />
            </span>
          </form>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectLayer;
