import React from "react";

import "./styles.scss";

const HeatmapLegend = (props) => {
  return (
    <div className="HeatmapLegend" {...props}>
      {props.children}
    </div>
  );
};
export default HeatmapLegend;
