export const validateEmail = (email) => {
  if (
    // eslint-disable-next-line
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  ) {
    return {
      message: "\u2022  Email must be a valid email address.\n",
      status: false
    };
  }
  return { status: true };
};

export const validatePassword = (password) => {
  if (
    password &&
    !/^(?=.*[\d])(?=.*[a-z])[\w!@#$%^&*]{7,}$/.test(password)
  ) {
    return {
      message:
        "\u2022 Password must be at least 7 characters long and must include letters and numbers.\n",
      status: false
    };
  }
  return { status: true };
};

export const validatePhone = (phone) => {
  if (
    !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone)
  ) {
    return {
      message: "\u2022 Please enter a valid phone number.\n",
      status: false
    };
  }
  return { status: true };
};
