// @flow

import React from "react";

import "./styles.scss";

type Props = {
  backgroundColor: string
};

const StatusIndicator = (props: Props) => {
  const { backgroundColor } = props;
  return (
    <span
      className="StatusIndicator"
      style={{ backgroundColor: backgroundColor }}
    />
  );
};

StatusIndicator.defaultProps = { backgroundColor: "#cccccc" };
export default StatusIndicator;
