// @flow

import React from "react";

import "../styles.scss";
import { getForegroundColor } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  farms: Array
};

const onDragOver = (ev) => {
  ev.preventDefault();
};

const FarmList = (props: Props) => {
  const { farms } = props.farms;

  const {
    selectFarm,
    assignFieldToFarm,
    editFarm,
    deleteFarm
  } = props;
  const { activeFarmId } = props.app;

  return (
    <div className="Farms">
      {farms.length > 0 && (
        <ul>
          {farms
            .sort(function (a, b) {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .map((farm) => {
              return (
                <li
                  key={farm.name}
                  style={{
                    backgroundColor: farm.color,
                    color: getForegroundColor(farm.color)
                  }}
                  className={
                    activeFarmId === farm.id ? "active" : null
                  }
                  onClick={() =>
                    selectFarm(farm.id, farm.name, farm.color)
                  }
                  onDragOver={(e) => onDragOver(e)}
                  onDrop={(ev) => {
                    // console.log("DROP!!!");

                    assignFieldToFarm(
                      ev.dataTransfer.getData("id"),
                      farm.id
                    );
                  }}
                >
                  {farm.name}
                  <span>
                    {editFarm && (
                      <FontAwesomeIcon
                        className="Icon"
                        icon="pencil-alt"
                        data-tip="Edit Farm"
                        data-for={`farm_tooltip`}
                        onClick={() =>
                          editFarm(farm.id, farm.name, farm.color)
                        }
                      />
                    )}
                    {/* {deleteFarm && <FontAwesomeIcon
                      className="Icon"
                      icon="trash-alt"
                      data-tip="Delete Farm"
                      data-for={`farm_tooltip`}
                      onClick={() => deleteFarm(farm.id, farm.name)}
                    />} */}
                  </span>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

FarmList.defaultProps = {
  farms: []
};
export default FarmList;
