import React from "react";
import videojs from "video.js";

import "../../../node_modules/video.js/dist/video-js.css";

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    const videoJsOptions = {
      autoplay: this.props.autoplay,
      controls: this.props.controls,
      height: this.props.height ? this.props.height : 500,
      poster: this.props.poster,

      sources: [
        {
          src: this.props.src,
          type: "video/mp4"
        }
      ]
    };

    // instantiate Video.js
    this.player = videojs(
      this.videoNode,
      videoJsOptions,
      function onPlayerReady() {
        // console.log('onPlayerReady', this)
      }
    );
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>
        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className="video-js"
          ></video>
        </div>
      </div>
    );
  }
}
