import * as ACTION from "../constants";

import {
  buildJsonRequest,
  getErrorMessage,
  buildProcessingJsonRequest
} from "../utils";
import { showToast } from "./";

export const getFileStatusByYear = (year) => {
  return buildJsonRequest(
    {
      url: `/files/files?year=${year}`,
      method: "GET"
    },
    ACTION.GET_FILE_STATUS
  );
};

export const getFileYears = () => {
  return buildJsonRequest(
    {
      url: "/files/years",
      method: "GET"
    },
    ACTION.GET_FILE_YEARS
  );
};

export const reprocessFiles = (file_ids, file_names, year) => {
  showToast(`Reprocessing File(s) ${file_names}`);
  setTimeout(() => getFileStatusByYear(year), 60000);
  return buildProcessingJsonRequest(
    {
      url: "/files/reprocess",
      method: "PUT",
      data: {
        fileIds: file_ids,
        inBackground: false,
        inBatch: true
      }
    },
    ACTION.REPROCESS_FILE,
    [
      (response) => showToast(`Reprocessed File(s) ${file_names}`),
      () => getFileStatusByYear(year)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const deleteFiles = (file_ids, file_names, year) => {
  showToast(`Deleting File(s) ${file_names}`);
  return buildJsonRequest(
    {
      url: "/files/filedelete",
      method: "POST",
      data: {
        fileIds: file_ids
        // forceForegroundExec: true
      }
    },
    ACTION.DELETE_FILE,
    [
      (response) => showToast(`Deleted File(s) ${file_names}`),
      () => getFileStatusByYear(year)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const reprocessSeedFit = (field_ids, field_names, year) => {
  showToast(`Reprocessing Seed Fit(s) ${field_names}`);
  return buildProcessingJsonRequest(
    {
      url: "/simulations/reprocess_by_field",
      method: "POST",
      data: {
        field_id: field_ids[0],
        year: year,
        inBackground: false,
        inBatch: true
      }
    },
    ACTION.REPROCESS_SEED_FIT,
    [
      (response) =>
        showToast(`Reprocessed Seed Fit(s) ${field_names}`),
      () => getFileStatusByYear(year)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const reprocessFertility = (field_ids, field_names) => {
  showToast(`Reprocessing Fertility for ${field_names}`);
  return buildProcessingJsonRequest(
    {
      url: "/prescriptions/fertilityrx ",
      method: "POST",
      data: {
        field_ids: field_ids
      }
    },
    ACTION.REPROCESS_FERTILITY,
    [
      (response) =>
        showToast(`Reprocessed Fertility for ${field_names}`)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const validateSeeds = () => {
  showToast(`Validating seeds for current account.`);
  return buildJsonRequest(
    {
      url: "/data/utils/validate_seeds_for_user",
      method: "POST"
    },
    ACTION.VALIDATE_SEEDS,
    [(response) => showToast(`Validated seeds.`)],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const getPlantedSeeds = (year) => {
  return buildJsonRequest(
    {
      url: `/data/planted_seeds?year=${year}`,
      method: "GET"
    },
    ACTION.GET_PLANTED_SEEDS,
    [],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const updateSeedName = (
  new_seed_name,
  old_seed_name,
  field_id,
  year,
  crop_type
) => {
  var data = {
    new_seed_name: new_seed_name,
    old_seed_name: old_seed_name,
    crop_type: crop_type
  };
  if (field_id) {
    data["field_id"] = field_id;
  }
  if (year) {
    data["year"] = year;
  }
  return buildJsonRequest(
    {
      url: "/data/utils/set_seed_name",
      method: "POST",
      data: data
    },
    ACTION.UPDATE_SEED_NAME,

    [
      () =>
        showToast(
          `Updated seed name ${old_seed_name} to ${new_seed_name}`
        ),
      () => getPlantedSeeds(year)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};
