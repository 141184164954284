// @flow

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import {
  Header,
  Input,
  AlertBar,
  SliderModal
} from "../../../components";
import typeaheadOptions from "../../../redux/utils/typeaheadOptions.js";

type Props = {};

const sortAlphabetical = (field) => {
  return (a, b) => {
    var textA = a && a[field] ? a[field].toUpperCase() : "";
    var textB = b && b[field] ? b[field].toUpperCase() : "";
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  };
};

const FieldPracticeDataManagement = (props: Props) => {
  const { years, unassigned_product_prices } = props.prices;
  const { practice_products } = props.practices;
  const { fields } = props.files;

  const {
    getDataYears,
    getPracticeProducts,
    getUnassignedProductPrices,
    deletePracticeProduct,
    updatePracticeProduct,
    copyPracticeProducts,
    getFileStatusByYear,
    updateFieldPractices,
    deleteFieldPractice
  } = props;
  const { loading } =
    props.admin.components.get_all_users ||
    props.admin.components.update_user;

  const [isPracticeOpen, setIsPracticeOpen] = useState(false);

  const [isCopyOpen, setIsCopyOpen] = useState(false);
  const [copyYear, setCopyYear] = useState(undefined);
  // const [
  //   keepFieldPracticeWhenCopying,
  //   setKeepFieldPracticeWhenCopying
  // ] = useState(false);

  const [practiceProductId, setPracticeProductId] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [inputPracticeName, setInputPracticeName] = useState("");
  const [inputPracticeDesc, setInputPracticeDesc] = useState("");
  const [inputTiming, setInputTiming] = useState("");

  const [practiceDesc, setPracticeDesc] = useState("");
  const [practiceMetadata, setPracticeMetadata] = useState({});

  const [productPriceId, setProductPriceId] = useState(undefined);
  const [productUnitsPerAcre, setProductUnitsPerAcre] = useState(
    undefined
  );
  const [
    productApplicationUnit,
    setProductApplicationUnit
  ] = useState(undefined);
  const [applicationCost, setApplicationCost] = useState(undefined);
  const [timing, setTiming] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedPractices, setSelectedPractices] = useState([]);

  const [selectedYear, setSelectedYear] = useState(undefined);
  const [validationError, setValidationError] = useState("");
  const [sortFunction, setSortFunction] = useState(() =>
    sortAlphabetical("name")
  );

  /****NEW PRODUCT */
  const [productName, setProductName] = useState("");
  const [inputProductName, setInputProductName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [pricePerUnit, setPricePerUnit] = useState("");
  const [purchasingUnit, setPurchasingUnit] = useState("");

  const unit_options = ["gal", "qt", "pt", "oz", "lbs", "ton"];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (selectedYear) {
      //TODO: Get Seed Prices
      getPracticeProducts(selectedYear);
      getUnassignedProductPrices(selectedYear, "input");
      getFileStatusByYear(selectedYear);
    } else if (years && years[0]) {
      //Load first year by default
      setSelectedYear(years[0]);
    } else {
      getDataYears();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    years,
    selectedYear,
    getDataYears,
    getPracticeProducts,
    getFileStatusByYear,
    getUnassignedProductPrices
  ]);
  return (
    <div className="RightPanelContent">
      <ReactTooltip
        id="AccountManagementTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />

      <Header title="Manage Farming Practices" loading={loading} />

      {practice_products && (
        <div className="DataManagement">
          <form>
            <label>
              <span>Year:</span>
              <select
                onChange={(evt) => {
                  getPracticeProducts(evt.target.value, "input");
                  getFileStatusByYear(evt.target.value);
                  setSelectedPractices([]);

                  setSelectedYear(evt.target.value);
                }}
              >
                {years &&
                  years
                    .sort(function (a, b) {
                      return b - a;
                    })
                    .map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
              </select>
            </label>
          </form>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "1em"
            }}
          >
            <div>
              <h2>Practices</h2>
              <input
                type="button"
                value={`Add practice`}
                onClick={() => {
                  setPracticeProductId(null);
                  setPracticeName("");
                  setPracticeDesc("");
                  setPracticeMetadata({});

                  setInputPracticeName("");
                  setInputPracticeDesc("");
                  setInputTiming("");
                  setProductPriceId(undefined);
                  setProductApplicationUnit(undefined);
                  setProductUnitsPerAcre(undefined);
                  setApplicationCost(undefined);
                  setTiming("");

                  setProductName("");
                  setInputProductName("");
                  setSupplierName("");
                  setPricePerUnit("");
                  setPurchasingUnit("");

                  setIsPracticeOpen(true);
                }}
              />
              <input
                type="button"
                disabled={selectedPractices.length <= 0}
                value={`Copy selected practices`}
                onClick={() => {
                  setIsCopyOpen(true);
                }}
              />
              <div className="table_wrapper">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "1em" }}>
                        {" "}
                        <input
                          name="selectPractices"
                          type="checkbox"
                          checked={
                            // eslint-disable-next-line
                            selectedPractices.length ==
                            practice_products.length
                          }
                          onChange={(event) => {
                            if (event.target.checked) {
                              var practice_productsToSelect = [];
                              practice_products.forEach(
                                (practice_product) => {
                                  practice_productsToSelect.push(
                                    practice_product.practice_product_id
                                  );
                                }
                              );
                              setSelectedPractices(
                                practice_productsToSelect
                              );
                            } else {
                              setSelectedPractices([]);
                            }
                          }}
                        />
                      </th>
                      <th style={{ width: "1em" }}></th>
                      <th style={{ width: "1em" }}></th>

                      <th
                        className="Link"
                        data-tip="Sort by Name"
                        data-for="AccountManagementTooltip"
                        onClick={() =>
                          setSortFunction(() =>
                            sortAlphabetical("practice_name")
                          )
                        }
                      >
                        {" "}
                        Practice Name{" "}
                      </th>
                      <th> Description </th>
                      <th> Product Name </th>
                      <th> Application Unit </th>
                      <th> Product Units Per Acre </th>
                      <th> Application Cost</th>
                      <th> Timing</th>
                    </tr>
                  </thead>
                  <tbody>
                    {practice_products
                      .sort(sortFunction)
                      .map((practice, index) => {
                        return (
                          <tr key={practice + index}>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                name="select_practice"
                                value={practice.practice_product_id}
                                checked={selectedPractices.includes(
                                  practice.practice_product_id
                                )}
                                onChange={(event) => {
                                  var selectedPracticesUpdate = [
                                    ...selectedPractices
                                  ];

                                  if (event.target.checked) {
                                    selectedPracticesUpdate.push(
                                      practice.practice_product_id
                                    );
                                    setSelectedPractices(
                                      selectedPracticesUpdate
                                    );
                                  } else {
                                    var index = selectedPracticesUpdate.indexOf(
                                      practice.practice_product_id
                                    );
                                    if (index !== -1) {
                                      selectedPracticesUpdate.splice(
                                        index,
                                        1
                                      );
                                    }

                                    setSelectedPractices(
                                      selectedPracticesUpdate
                                    );
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <FontAwesomeIcon
                                icon="pencil-alt"
                                className="ActionIcon"
                                data-tip="Edit line item"
                                data-for="AccountManagementTooltip"
                                onClick={() => {
                                  setIsPracticeOpen(true);
                                  setPracticeProductId(
                                    practice.practice_product_id
                                  );
                                  setPracticeName(
                                    Object.keys(
                                      typeaheadOptions.quickform
                                    ).includes(practice.practice_name)
                                      ? practice.practice_name
                                      : "other"
                                  );

                                  setPracticeMetadata(
                                    practice.practice_metadata
                                  );

                                  setPracticeDesc(
                                    typeaheadOptions.quickform[
                                      practice.practice_name
                                    ] &&
                                      Object.keys(
                                        typeaheadOptions.quickform[
                                          practice.practice_name
                                        ]
                                      ).includes(
                                        practice.practice_desc
                                      )
                                      ? practice.practice_desc
                                      : "other"
                                  );

                                  setTiming(
                                    typeaheadOptions.quickform[
                                      practice.practice_name
                                    ] &&
                                      typeaheadOptions.quickform[
                                        practice.practice_name
                                      ][practice.practice_desc] &&
                                      typeaheadOptions.quickform[
                                        practice.practice_name
                                      ][
                                        practice.practice_desc
                                      ].timing.includes(
                                        practice.timing
                                      )
                                      ? practice.timing
                                      : "other"
                                  );

                                  setInputPracticeName(
                                    practice.practice_name
                                  );
                                  setInputPracticeDesc(
                                    practice.practice_desc
                                  );
                                  setInputTiming(practice.timing);

                                  setProductPriceId(
                                    practice.product_price_id
                                  );
                                  setProductApplicationUnit(
                                    practice.product_application_unit
                                  );
                                  setProductUnitsPerAcre(
                                    practice.product_units_per_acre
                                  );
                                  setApplicationCost(
                                    practice.application_cost
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <FontAwesomeIcon
                                icon="trash-alt"
                                className="ActionIcon"
                                size="1x"
                                onClick={() =>
                                  deletePracticeProduct(practice)
                                }
                                data-tip="Delete Product Price"
                                data-for="DataManagementTooltip"
                              />
                            </td>

                            <td>{practice.practice_name}</td>
                            <td>
                              {practice.practice_desc}{" "}
                              {practice.practice_metadata &&
                                `${Object.keys(
                                  practice.practice_metadata
                                ).concat()}`}
                            </td>
                            <td>
                              {practice.product_price
                                ? practice.product_price.product_name
                                : null}
                            </td>
                            <td>
                              {practice.product_application_unit}
                            </td>

                            <td>{practice.product_units_per_acre}</td>
                            <td>
                              $
                              {practice.application_cost
                                ? practice.application_cost.toFixed(2)
                                : ""}
                            </td>
                            <td>{practice.timing}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <h2>Fields</h2>
              <input
                type="button"
                disabled={
                  selectedFields.length <= 0 ||
                  selectedPractices.length <= 0
                }
                value={`Apply practice to fields`}
                onClick={() => {
                  var fieldPractices = [];
                  selectedFields.forEach((field) => {
                    selectedPractices.forEach((selectedPractice) => {
                      var filterField = fields.filter(
                        (fieldToFilter) => {
                          var filteredPractice = fieldToFilter.field_practices.filter(
                            (field_practice) => {
                              return (
                                // eslint-disable-next-line
                                field_practice.practice_product_id ==
                                selectedPractice
                              );
                            }
                          );
                          return (
                            // eslint-disable-next-line
                            fieldToFilter.field_id == field &&
                            filteredPractice.length > 0
                          );
                        }
                      );

                      if (filterField.length <= 0) {
                        // prevent duplicates

                        fieldPractices.push({
                          field_id: field,
                          practice_product_id: selectedPractice,
                          year: selectedYear
                        });
                      }
                    });
                  });
                  if (fieldPractices.length > 0) {
                    updateFieldPractices(fieldPractices);
                  }
                }}
              />
              <div className="table_wrapper">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "2em" }}>
                        <input
                          name="selectFields"
                          type="checkbox"
                          checked={
                            // eslint-disable-next-line
                            selectedFields.length == fields.length
                          }
                          onChange={(event) => {
                            if (event.target.checked) {
                              var fieldsToSelect = [];
                              fields.forEach((field) => {
                                fieldsToSelect.push(field.field_id);
                              });
                              setSelectedFields(fieldsToSelect);
                            } else {
                              setSelectedFields([]);
                            }
                          }}
                        />
                      </th>
                      <th
                        className="Link"
                        data-tip="Sort by Name"
                        data-for="AccountManagementTooltip"
                        onClick={() =>
                          setSortFunction(() =>
                            sortAlphabetical("field_name")
                          )
                        }
                      >
                        {" "}
                        Field Name{" "}
                      </th>
                      <th> Practices </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.sort(sortFunction).map((field, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              name="selectField"
                              type="checkbox"
                              checked={selectedFields.includes(
                                field.field_id
                              )}
                              onChange={(event) => {
                                var selectedFieldsUpdate = [
                                  ...selectedFields
                                ];

                                if (event.target.checked) {
                                  selectedFieldsUpdate.push(
                                    field.field_id
                                  );
                                  setSelectedFields(
                                    selectedFieldsUpdate
                                  );
                                } else {
                                  var index = selectedFieldsUpdate.indexOf(
                                    field.field_id
                                  );
                                  if (index !== -1) {
                                    selectedFieldsUpdate.splice(
                                      index,
                                      1
                                    );
                                  }

                                  setSelectedFields(
                                    selectedFieldsUpdate
                                  );
                                }
                              }}
                            />
                          </td>

                          <td>{field.field_name}</td>
                          <td>
                            {field.field_practices.map(
                              (field_practice, index) =>
                                // eslint-disable-next-line
                                field_practice.year ==
                                selectedYear ? (
                                  <div key={field_practice + index}>
                                    {" "}
                                    <span>
                                      <FontAwesomeIcon
                                        icon="trash-alt"
                                        className="ActionIcon"
                                        size="1x"
                                        onClick={() =>
                                          deleteFieldPractice(
                                            field_practice
                                          )
                                        }
                                        data-tip="Delete Practice from this field"
                                        data-for="DataManagementTooltip"
                                      />{" "}
                                      {" | "}{" "}
                                    </span>
                                    <span>
                                      {`${
                                        field_practice
                                          .practice_product
                                          .practice_name
                                      } 
                                  - ${
                                    field_practice.practice_product
                                      .practice_desc
                                  } 
                                  - ${
                                    field_practice.practice_product
                                      .product_price
                                      ? field_practice
                                          .practice_product
                                          .product_price.product_name
                                      : "N/A"
                                  }`}
                                    </span>
                                    <br />
                                  </div>
                                ) : null
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      <SliderModal
        title="Update Farming Practice"
        isOpen={isPracticeOpen}
        onOutsideClick={() => setIsPracticeOpen(false)}
        footer={
          <div
            style={{
              width: "100%",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            <input
              type="button"
              value="Update"
              onClick={() => {
                setValidationError("");
                var validationMessage = "";

                //TODO: Validation
                if (
                  !practiceName ||
                  !practiceDesc ||
                  !applicationCost
                ) {
                  validationMessage =
                    "Practice name, description, and application cost / acre are required.  Please fill out all the input fields to continue.";
                }

                if (productPriceId === "new") {
                  if (
                    !productName ||
                    !pricePerUnit ||
                    !purchasingUnit
                  ) {
                    validationMessage +=
                      "Product name, price per unit, and purchasing unit are required.  Please fill out all the input fields to continue.";
                  }
                }

                if (validationMessage !== "") {
                  setValidationError(validationMessage);
                } else {
                  var practice = {
                    practice_product_id: practiceProductId,
                    product_price_id: productPriceId,

                    practice_name:
                      practiceName !== "other"
                        ? practiceName
                        : inputPracticeName,
                    practice_desc:
                      practiceDesc !== "other"
                        ? practiceDesc
                        : inputPracticeDesc,
                    product_units_per_acre: productUnitsPerAcre,
                    product_application_unit: productApplicationUnit,
                    application_cost: applicationCost,
                    timing: timing !== "other" ? timing : inputTiming,
                    practice_metadata: practiceMetadata,
                    year: selectedYear
                  };

                  if (productPriceId === "new") {
                    practice.product_price_id = null;
                    practice.product_price = {
                      product_price_id: null,
                      product_name:
                        productName !== "other"
                          ? productName
                          : inputProductName,

                      supplier_name: supplierName,
                      price_per_unit: pricePerUnit,
                      purchasing_unit: purchasingUnit,
                      product_type: practiceName.toLowerCase(),
                      year: selectedYear
                    };
                  }

                  updatePracticeProduct(practice);
                  setIsPracticeOpen(false);
                  setPracticeProductId(undefined);
                  setPracticeMetadata(undefined);
                  setPracticeName("");
                  setInputPracticeName("");
                  setPracticeDesc("");
                  setInputPracticeDesc("");
                  setInputTiming("");
                  setProductPriceId("");
                  setProductApplicationUnit(undefined);
                  setProductUnitsPerAcre(undefined);
                  setApplicationCost(undefined);
                  setTiming("");
                  setProductName("");
                  setInputProductName("");
                  setSupplierName("");
                  setPricePerUnit("");
                  setPurchasingUnit("");
                }
              }}
            />

            <input
              type="button"
              value="Cancel"
              onClick={() => setIsPracticeOpen(false)}
            />
          </div>
        }
      >
        <form>
          {validationError && (
            <AlertBar>
              {validationError ? (
                <div>
                  {validationError.split("\n").map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
              {/* {error} */}
            </AlertBar>
          )}

          <label>
            <span>Practice Name:</span>

            <select
              value={practiceName}
              onChange={(evt) => {
                var descriptions = typeaheadOptions.quickform[
                  evt.target.value
                ]
                  ? Object.keys(
                      typeaheadOptions.quickform[evt.target.value]
                    )
                  : undefined;

                if (descriptions && descriptions.length === 1) {
                  setPracticeDesc(descriptions[0]);
                } else if (evt.target.value) {
                  setPracticeDesc(undefined);
                } else {
                  setPracticeDesc("");
                }

                setPracticeName(
                  evt.target.value === "Select"
                    ? undefined
                    : evt.target.value
                );
              }}
            >
              <option key={undefined} value={undefined}>
                Select
              </option>

              {Object.keys(typeaheadOptions.quickform)
                .sort()
                .map((practice) => {
                  return (
                    <option key={practice} value={practice}>
                      {practice}
                    </option>
                  );
                })}

              <option key="other" value="other">
                other
              </option>
            </select>
          </label>
          {practiceName === "other" && (
            <label>
              <span>Enter Practice Name:</span>

              <Input
                name="inputPracticeName"
                type="text"
                value={inputPracticeName}
                onChange={(e) => setInputPracticeName(e.target.value)}
              />
            </label>
          )}
          <label>
            <span>Practice Description:</span>

            <select
              value={practiceDesc}
              onChange={(evt) => {
                setPracticeDesc(
                  evt.target.value === "Select"
                    ? undefined
                    : evt.target.value
                );
              }}
            >
              <option key={undefined} value={undefined}>
                Select
              </option>

              {typeaheadOptions.quickform[practiceName] &&
                Object.keys(typeaheadOptions.quickform[practiceName])
                  .sort()
                  .map((practice) => {
                    return (
                      <option key={practice} value={practice}>
                        {practice}
                      </option>
                    );
                  })}

              <option key="other" value="other">
                other
              </option>
            </select>
          </label>

          {practiceName === "foliar" && (
            <React.Fragment>
              <label>
                <span>Foliar Ingredients:</span>
              </label>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridColumnGap: "2em",
                  lineHeight: "1em"
                }}
              >
                {Object.keys(
                  typeaheadOptions.quickform.foliar[
                    "Foliar application"
                  ].products
                )
                  .sort()
                  .map((product) => {
                    return (
                      <label>
                        <span> {product}</span>
                        <input
                          name="selectFoliar"
                          type="checkbox"
                          // eslint-disable-next-line
                          checked={
                            practiceMetadata &&
                            practiceMetadata[product]
                          }
                          onChange={(event) => {
                            if (
                              practiceMetadata &&
                              practiceMetadata[product]
                            ) {
                              setPracticeMetadata({
                                ...practiceMetadata,
                                [product]: undefined
                              });
                            } else {
                              setPracticeMetadata({
                                ...practiceMetadata,
                                [product]: true
                              });
                            }
                          }}
                        />{" "}
                      </label>
                    );
                  })}
              </div>
            </React.Fragment>
          )}

          {practiceDesc === "other" && (
            <label>
              <span>Enter Practice Desc:</span>

              <Input
                name="inputPracticeDesc"
                type="text"
                value={inputPracticeDesc}
                onChange={(e) => setInputPracticeDesc(e.target.value)}
              />
            </label>
          )}

          <label>
            <span>Product:</span>

            <select
              value={productPriceId}
              onChange={(evt) => {
                setProductPriceId(
                  evt.target.value === "Select"
                    ? undefined
                    : evt.target.value
                );
                if (evt.target.value !== "new") {
                  var productForAutopop = unassigned_product_prices.filter(
                    (product_price) => {
                      return (
                        // eslint-disable-next-line
                        evt.target.value ==
                        product_price.product_price_id
                      );
                    }
                  )[0];
                  if (
                    productForAutopop &&
                    typeaheadOptions.quickform[practiceName] &&
                    typeaheadOptions.quickform[practiceName][
                      practiceDesc
                    ] &&
                    typeaheadOptions.quickform[practiceName][
                      practiceDesc
                    ].products[productForAutopop.product_name]
                  ) {
                    setProductApplicationUnit(
                      typeaheadOptions.quickform[practiceName][
                        practiceDesc
                      ].products[productForAutopop.product_name]
                    );
                  }
                }
              }}
            >
              <option key={undefined} value={undefined}>
                Select
              </option>

              {unassigned_product_prices.map((product) => {
                return (
                  <option
                    key={product.product_price_id}
                    value={product.product_price_id}
                  >
                    {product.product_name}{" "}
                    {product.supplier_name &&
                      `(${product.supplier_name})`}
                    {`$${product.price_per_unit} / ${product.purchasing_unit}`}
                  </option>
                );
              })}
              <option key="new" value="new">
                New...
              </option>
            </select>
          </label>

          {/**** Make a new Product Price here if they select "New Product*/}

          {productPriceId === "new" && (
            <React.Fragment>
              ------------------------------------------------------
              <label>
                <span>New Product Name:</span>

                <select
                  value={productName}
                  onChange={(evt) => {
                    setProductName(
                      evt.target.value === "Select"
                        ? undefined
                        : evt.target.value
                    );

                    // autopopulate the unit
                    if (
                      typeaheadOptions.quickform[practiceName] &&
                      typeaheadOptions.quickform[practiceName][
                        practiceDesc
                      ] &&
                      typeaheadOptions.quickform[practiceName][
                        practiceDesc
                      ].products[evt.target.value]
                    ) {
                      setProductApplicationUnit(
                        typeaheadOptions.quickform[practiceName][
                          practiceDesc
                        ].products[evt.target.value]
                      );
                    }
                  }}
                >
                  <option key={undefined} value={undefined}>
                    Select
                  </option>

                  {typeaheadOptions.quickform[practiceName] &&
                    typeaheadOptions.quickform[practiceName][
                      practiceDesc
                    ] &&
                    typeaheadOptions.quickform[practiceName][
                      practiceDesc
                    ].products &&
                    Object.keys(
                      typeaheadOptions.quickform[practiceName][
                        practiceDesc
                      ].products
                    )
                      .sort()
                      .map((product) => {
                        return (
                          <option key={product} value={product}>
                            {product}
                          </option>
                        );
                      })}

                  <option key="other" value="other">
                    other
                  </option>
                </select>
              </label>
              {productName === "other" && (
                <label>
                  <span>Enter Product Name:</span>

                  <Input
                    name="inputProductName"
                    type="text"
                    value={inputProductName}
                    onChange={(e) =>
                      setInputProductName(e.target.value)
                    }
                  />
                </label>
              )}
              <label>
                <span>Supplier:</span>

                <Input
                  name="supplierName"
                  type="text"
                  value={supplierName}
                  onChange={(e) => setSupplierName(e.target.value)}
                />
              </label>
              <label>
                <span>Price per unit:</span>

                <Input
                  name="price_per_unit"
                  type="text"
                  mask="[000000000].00"
                  value={pricePerUnit}
                  onChange={(e) => setPricePerUnit(e.target.value)}
                />
              </label>
              <label>
                <span>Purchasing Unit:</span>
                <select
                  value={purchasingUnit}
                  onChange={(evt) => {
                    setPurchasingUnit(evt.target.value);
                  }}
                >
                  <option key={null} value={null}>
                    Select
                  </option>

                  {unit_options.map((option) => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
              </label>
              ------------------------------------------------------
            </React.Fragment>
          )}
          {/****END  new product ***/}

          <label>
            <span>Application Unit:</span>

            <select
              value={productApplicationUnit}
              onChange={(evt) => {
                setProductApplicationUnit(
                  evt.target.value === "Select"
                    ? undefined
                    : evt.target.value
                );
              }}
            >
              <option key={undefined} value={undefined}>
                Select
              </option>

              {["gal", "qt", "pt", "oz", "lbs"].map((unit) => {
                return (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                );
              })}
            </select>
          </label>

          <label>
            <span>Units Per Acre:</span>

            <Input
              name="product_units_per_acre"
              type="text"
              mask="[000000000].00"
              value={productUnitsPerAcre}
              onChange={(e) => setProductUnitsPerAcre(e.target.value)}
            />
          </label>

          <label>
            <span>Application Cost / Acre:</span>

            <Input
              name="application_cost"
              type="text"
              mask="[000000000].00"
              value={applicationCost}
              onChange={(e) => setApplicationCost(e.target.value)}
            />
          </label>

          <label>
            <span>Timing:</span>

            <select
              value={timing}
              onChange={(evt) => {
                setTiming(
                  evt.target.value === "Select"
                    ? undefined
                    : evt.target.value
                );
              }}
            >
              <option key={undefined} value={undefined}>
                Select
              </option>
              {typeaheadOptions.quickform[practiceName] &&
                typeaheadOptions.quickform[practiceName][
                  practiceDesc
                ] &&
                typeaheadOptions.quickform[practiceName][
                  practiceDesc
                ].timing.map((stage) => {
                  return (
                    <option key={stage} value={stage}>
                      {stage}
                    </option>
                  );
                })}{" "}
              <option key="other" value="other">
                other
              </option>
            </select>
          </label>
          {timing === "other" && (
            <label>
              <span>Enter Timing:</span>

              <Input
                name="inputTiming"
                type="text"
                value={inputTiming}
                onChange={(e) => setInputTiming(e.target.value)}
              />
            </label>
          )}
        </form>
      </SliderModal>

      <SliderModal
        title="Copy Farming Practices"
        isOpen={isCopyOpen}
        onOutsideClick={() => setIsCopyOpen(false)}
        footer={
          <div
            style={{
              width: "100%",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            <input
              type="button"
              value="Copy"
              onClick={() => {
                setValidationError("");
                var validationMessage = "";

                if (!copyYear) {
                  validationMessage +=
                    "Year is required.  Please fill out all the input fields to continue.";
                }

                if (validationMessage !== "") {
                  setValidationError(validationMessage);
                } else {
                  copyPracticeProducts(selectedPractices, copyYear);
                  setIsCopyOpen(false);
                  setCopyYear(undefined);
                  //setKeepFieldPracticeWhenCopying(undefined);
                }
              }}
            />

            <input
              type="button"
              value="Cancel"
              onClick={() => setIsPracticeOpen(false)}
            />
          </div>
        }
      >
        <form>
          {validationError && (
            <AlertBar>
              {validationError ? (
                <div>
                  {validationError.split("\n").map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
              {/* {error} */}
            </AlertBar>
          )}

          <label>
            <span>Copy to Year:</span>
            <select
              onChange={(evt) => {
                setCopyYear(evt.target.value);
              }}
            >
              <option key={undefined} value={undefined}>
                Select
              </option>
              {years &&
                years
                  .sort(function (a, b) {
                    return b - a;
                  })
                  .map((option) => {
                    return option === selectedYear ? null : (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
            </select>
          </label>
          {/* <label>
            <span>
              Keep field/practice association in the selected year
            </span>
            <input
              name="terms"
              type="checkbox"
              checked={keepFieldPracticeWhenCopying}
              onChange={(event) =>
                setKeepFieldPracticeWhenCopying(event.target.checked)
              }
            />
          </label> */}
        </form>
      </SliderModal>
    </div>
  );
};

FieldPracticeDataManagement.defaultProps = {};
export default FieldPracticeDataManagement;
