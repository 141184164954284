import * as ACTION from "../constants";

export const showToast = (text: string) => {
  return (dispatch) => {
    dispatch({
      type: ACTION.SHOW_TOAST,
      toast: {
        timestamp: Date.now(),
        text
      }
    });
    setTimeout(
      () =>
        dispatch(
          hideToast({
            text
          })
        ),
      10000
    );
  };
};

export const hideToast = (toast: object) => ({
  type: ACTION.HIDE_TOAST,
  toast: toast
});
