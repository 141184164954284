// @flow

import React from "react";

import { useNavigate } from "react-router";

import * as APP_ATTRIBUTE from "../../../entitlements/appAttributes";
import { hasAppAttribute } from "../../../entitlements";

const Footer = (props: Props) => {
  const navigate = useNavigate();
  const { user } = props;

  return (
    <div className="Footer" id="footer">
      <div>
        My Farm Intelligence, LLC | 2 Fairway Drive Monmouth, IL 61462
      </div>
      <div>
        <a target="_blank" href="/termsandconditions.html">
          Terms and Conditions
        </a>
        {" | "}
        <a target="_blank" href="/privacypolicy.html">
          Privacy Policy
        </a>
      </div>

      <div>
        {" "}
        {hasAppAttribute(APP_ATTRIBUTE.FARMER_APP, user) && (
          <a
            href="/app/dashboard"
            onClick={() => navigate("/app/dashboard")}
          >
            <span> My Account</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default Footer;
