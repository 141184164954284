import React from "react";
import { ReactComponent as LoadingIcon } from "../../../assets/three-dots.svg";

import "./styles.scss";

const Loading = (props) => {
  return (
    <div className="Loading">
      <LoadingIcon />
    </div>
  );
};
export default Loading;
