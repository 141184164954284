import * as ACTION from "../constants";
import { updateAccountSettings } from "../actions";

export const appReducer = (
  state = {
    activeFieldId: undefined,
    activeFieldName: undefined,
    activeFieldBoundaryLocked: undefined,
    activeReport: undefined,
    activeFarmId: undefined,
    activeFarmName: undefined,
    activeFarmColor: undefined,
    activeYieldEnv: undefined,
    activeProfileId: undefined,
    modal: {
      visible: false,
      confirmButtonLabel: "",
      cancelButtonLabel: "",
      message: ""
    },
    showMap: true,
    zoom: 8,
    center: [40.9144228, -90.6386727]
  },
  action
) => {
  switch (action.type) {
    case ACTION.CONSOLE_LOG:
      // console.log(action.content);
      return state;
    case ACTION.GET_USER_SUCCESS:
      if (
        !action.data.has_accepted_terms ||
        action.data.role === "demo_user"
      ) {
        return {
          ...state,
          modal: {
            visible: true,
            confirmButtonLabel: "I agree",
            onConfirm: () =>
              updateAccountSettings({
                has_accepted_terms: true,
                role:
                  action.data.role === "demo_user"
                    ? "nda_user"
                    : action.data.role
              })
          }
        };
      }
      return state;
    case ACTION.DISPLAY_MODAL:
      return {
        ...state,
        modal: {
          visible: true,
          video: action.video,
          message: action.message,
          confirmButtonLabel: action.confirmButtonLabel,
          cancelButtonLabel: action.cancelButtonLabel,
          onConfirm: action.onConfirm,
          onCancel: action.onCancel
        }
      };
    case ACTION.DISMISS_MODAL:
      return {
        ...state,
        modal: {
          visible: false
        }
      };
    case ACTION.FLY_TO:
      return {
        ...state,
        center: action.coords ? action.coords : state.center
        //zoom: action.zoom ? action.zoom : state.zoom
      };
    case ACTION.SET_ZOOM:
      return {
        ...state,
        zoom: action.zoom ? action.zoom : state.zoom
      };
    case ACTION.SELECT_FIELD:
      return {
        ...state,
        activeFieldName:
          state.activeFieldName !== action.fieldName
            ? action.fieldName
            : undefined,
        activeFieldCoords:
          state.activeFieldCoords !== action.fieldCoords
            ? action.fieldCoords
            : undefined,
        activeFieldId:
          state.activeFieldId !== action.fieldId
            ? action.fieldId
            : undefined,
        activeFieldBoundaryLocked: action.fieldBoundaryLocked,
        activeReport: undefined,
        activeReportYear: undefined,
        activeSimulation: undefined,
        activeSimulationYear: undefined,
        zoom: state.activeFieldId !== action.fieldId ? 15 : 8
      };
    case ACTION.SELECT_REPORT:
      return {
        ...state,
        activeReport: action.report,
        activeReportYear: action.year
      };
    case ACTION.SELECT_SIMULATION:
      return {
        ...state,
        activeSimulation: action.simulation,
        activeSimulationYear: action.year
      };
    case ACTION.SELECT_PROFILE:
      return {
        ...state,
        activeProfileId: action.profileId,
        activeProfileName: action.profileName
      };
    case ACTION.SELECT_FARM:
      return {
        ...state,
        activeFarmName:
          state.activeFarmName !== action.farmName
            ? action.farmName
            : undefined,
        activeFarmId:
          state.activeFarmId !== action.farmId
            ? action.farmId
            : undefined,
        activeFarmColor:
          state.activeFarmColor !== action.farmColor
            ? action.farmColor
            : undefined
      };
    case ACTION.SELECT_YIELD_ENVIRONMENT:
      return {
        ...state,
        activeYieldEnv:
          state.activeYieldEnv !== action.env ? action.env : undefined
      };
    default:
      return state;
  }
};
