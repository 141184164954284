import * as ACTION from "../constants";

export const farmsReducer = (
  state = {
    farms: [],
    components: {
      get_farms: {
        loading: false,
        error: undefined,
        success: undefined
      },
      manage_farms: {
        loading: false,
        error: undefined,
        success: undefined
      }
    }
  },
  action
) => {
  switch (action.type) {
    case ACTION.ADD_FARM:
    case ACTION.UPDATE_FARM:
    case ACTION.ASSIGN_FIELD_TO_FARM:
    case ACTION.DELETE_FARM:
      return {
        ...state,
        components: {
          ...state.components,
          manage_farms: {
            ...state.components.manage_farms,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };

    case ACTION.ADD_FARM_SUCCESS:
    case ACTION.UPDATE_FARM_SUCCESS:
    case ACTION.DELETE_FARM_SUCCESS:
    case ACTION.ASSIGN_FIELD_TO_FARM_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          manage_farms: {
            ...state.components.manage_farms,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.UPDATE_FARM_CLEAR:
      return {
        ...state,
        components: {
          ...state.components,
          manage_farms: {
            ...state.components.manage_farms,
            loading: false,
            success: undefined,
            error: undefined
          }
        }
      };

    case ACTION.ADD_FARM_FAIL:
    case ACTION.UPDATE_FARM_FAIL:
    case ACTION.ASSIGN_FIELD_TO_FARM_FAIL:
    case ACTION.DELETE_FARM_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          manage_farms: {
            ...state.components.manage_farms,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.GET_FARMS:
      return {
        ...state,
        components: {
          ...state.components,
          get_farms: {
            ...state.components.get_farms,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };

    case ACTION.GET_FARMS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          get_farms: {
            ...state.components.get_farms,
            loading: false,
            success: true
          }
        },
        farms: action.data.farms
      };

    case ACTION.GET_FARMS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_farms: {
            ...state.components.get_farms,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.GET_FARMS_CLEAR:
      return {
        ...state,
        components: {
          ...state.components,
          get_farms: {
            ...state.components.get_farms,
            loading: false,
            success: undefined,
            error: undefined
          }
        }
      };

    default:
      return state;
  }
};
