// @flow

import React from "react";
import handleViewport from "react-in-viewport";

const Block = (props) => {
  return <div ref={props.innerRef}>{props.children}</div>;
};

const ViewportBlock = handleViewport(
  Block /** options: {}, config: {} **/
);

const Section = (props) => {
  return (
    <div id={props.id}>
      <ViewportBlock
        onEnterViewport={() =>
          props.onVisibilityChange(props.id, true)
        }
        // onLeaveViewport={() => props.onVisibilityChange(props.id, false)}
      >
        {props.children}
      </ViewportBlock>
    </div>
  );
};

export default Section;
