import React, { useState } from "react";

import { AlertBar, Header } from "../../../components";

const NDAForm = (props: Props) => {
  const { updateAccountSettings } = props;
  const { phone, name } = props.user;

  const [ndaSigned, setNdaSigned] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const { loading } = props.user.components.update_account_settings;

  return (
    <div>
      <p>
        <Header title="Learn more" loading={loading} />
        <div>
          {validationError ? (
            <AlertBar>
              {validationError ? (
                <div>Select the checkbox to sign the NDA.</div>
              ) : null}
            </AlertBar>
          ) : null}
          To view a technical demo, see our product roadmap for
          2020-2021, and hear from our farmer members, please sign the
          confidentiality agreement. By signing this agreement, you
          agree not to share or use any of the confidential
          information presented.
        </div>

        <form>
          <label>
            <span>
              I agree to the{" "}
              <a target="_blank" href="/ndaagreement.html">
                Confidentiality Agreement{" "}
              </a>
            </span>
            <input
              name="terms"
              type="checkbox"
              checked={ndaSigned}
              onChange={(event) => setNdaSigned(event.target.checked)}
            />
          </label>

          <input
            type="button"
            value="Sign Agreement"
            onClick={
              ndaSigned
                ? () =>
                    updateAccountSettings({
                      name: name,
                      phone: phone,
                      role: "nda_user"
                    })
                : () => setValidationError(true)
            }
          />
        </form>
      </p>
    </div>
  );
};

export default NDAForm;
