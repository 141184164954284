export const hereCredentials = {
  id: "hwxD5JUBUjBjszXI34s5",
  code: "FPFUudZFDWlsMXqMghGR9g"
};

export const hereTileUrl = `https://4.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?app_id=${hereCredentials.id}&app_code=${hereCredentials.code}&lg=eng`;

export const maxIsolineRangeLookup = {
  time: 20000,
  distance: 400000
};
