import "react-app-polyfill/ie11";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";

import React, { Fragment, StrictMode } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "./redux/store";

import App from "./App/container";
import DataLoader from "./DataLoader/container";

import * as serviceWorker from "./serviceWorker";

render(
  <Provider store={store}>
    <StrictMode>
      <Fragment>
        <BrowserRouter>
          <DataLoader />
          <App />
        </BrowserRouter>
      </Fragment>
    </StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
