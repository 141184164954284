import React from "react";
import { Toolbar } from "..";

import "./styles.scss";

import { isMobile } from "react-device-detect";

const Page = (props) => {
  const { title } = props;
  return (
    <div className={`Page ${isMobile ? "mobile" : "browser"}`}>
      <Toolbar activePage={title} />
      <div className="PageContentWrapper">
        <div className="PageContent">{props.children}</div>
      </div>
    </div>
  );
};

export default Page;
