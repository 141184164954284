// @flow

import React from "react";

import { FieldList } from "../../";

type Props = {
  farms: Array
};

class FieldsInFarm extends React.Component<Props> {
  render() {
    let props = this.props;

    const { activeFarmId, activeFarmName } = props.app;

    const { assignFieldToFarm } = this.props;

    var summary = props.fields.summary.filter(function (field) {
      return field.properties.farm_id === activeFarmId;
    });

    var filteredProps = {
      ...props,
      fields: {
        ...props.fields,
        summary: summary
      }
    };

    return activeFarmId ? (
      <FieldList
        placeholder={" Search fields in: " + activeFarmName}
        {...filteredProps}
        onDrop={(fieldId) => assignFieldToFarm(fieldId, activeFarmId)}
      />
    ) : null;
  }
}

FieldsInFarm.defaultProps = {};
export default FieldsInFarm;
