// @flow

import React from "react";
import Section from "./Section";
import { VideoModal, VideoPlayer, Notice } from "../../../components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const videosAbout = [
  {
    title: "What is MFI?",
    src: "https://ddwzudgjmurcz.cloudfront.net/sam-whatismfi"
  },
  {
    title: "Founding Farmer Membership",
    src: "https://ddwzudgjmurcz.cloudfront.net/sam-foundingfarmer"
  },
  {
    title: "Farmer Data Cell",
    src: "https://ddwzudgjmurcz.cloudfront.net/sam-whatiscell"
  },
  {
    title: "An Independent Voice",
    src: "https://ddwzudgjmurcz.cloudfront.net/darin-independentvoice"
  }
  // {
  //   "title": "A Scalable Solution",
  //   "src": "https://testing.mfi.farm/media/pradeep-architecture"
  // }
];

const PremiumFeatures = (props: Props) => {
  return (
    <Section
      id="premium"
      onVisibilityChange={props.onVisibilityChange}
      style={{ minHeight: "105em" }}
    >
      <div className="Section XL" id="premium">
        <h1 className="SectionHeading">Our Story</h1>
        <p className="BigText">
          Find out what MFI is all about by hearing from our team!
        </p>

        <Notice>
          <FontAwesomeIcon icon="exclamation-triangle" size="1x" />
          <span>
            This content is protected by our confidentiality
            agreement. Do not share this content with individuals who
            have not signed the confidentiality agreement. If you wish
            to share this content with someone who has not signed the
            agreement, use the invite tool to send them an invite and
            make sure that they sign the agreement before continuing.
          </span>
        </Notice>
        <div className="PanelWrapper">
          <div className="PanelContent">
            <h1>Watch this first</h1>

            <p>
              <VideoPlayer
                height={320}
                src={
                  "https://ddwzudgjmurcz.cloudfront.net/watchfirst/video.mp4"
                }
                poster={
                  "https://ddwzudgjmurcz.cloudfront.net/watchfirst/poster.png"
                }
                autoplay={false}
                controls={true}
              />
            </p>

            <h2>More About Us</h2>
            <div className="Team">
              {videosAbout.map((video) => {
                return (
                  <VideoModal
                    {...props}
                    title={video.title}
                    img={`${video.src}/poster.png`}
                    src={`${video.src}/video.mp4`}
                  />
                );
              })}
            </div>
          </div>

          {/* <div>
            <h1>Available Now</h1>

            <p>
              <h2>Fertility Management</h2>
              Create unlimited customizable variable rate prescriptions
            </p>
            <p>
              <h2>Optimal Seed Placement Tool </h2>
              Generate optimum seeding rate prescription and financial analysis
              of seed choice
            </p>
          </div> */}
          <div className="PanelContent">
            <h1>Coming Soon</h1>
            <p>
              {" "}
              <i>
                New functionality coming this fall and next spring
              </i>
            </p>

            <p>
              <h2>Water Drainage and Use Tracking </h2>
              Predict yield, financial impact, and potential water
              stress
            </p>

            <p>
              <h2>Unlimited Optimization Reports </h2>
              Ability to compare simulated outputs of different inputs
              and practices for effectiveness and cost optimization
            </p>

            <p>
              <h2>Automated Report Generation </h2>
              Receive notices from the system when opportunities are
              identified
            </p>

            <p>
              <h2>Enterprise Management </h2>
              Manage multiple farms and provide role-based accounts
              for farm workers
            </p>

            <p>
              <h2>Expert Insight </h2>
              <p>
                Collaborate with Agronomy professionals to identify
                opportunities for improvement
              </p>
            </p>

            <p>
              <h2>Personal Community </h2>
              <p>
                Access a community of like-minded farmers with
                complete control over what data you share
              </p>
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default PremiumFeatures;
