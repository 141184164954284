import React from "react";
import { Header } from "../../../components";

const JoinForm = (props: Props) => {
  const { completeInvite } = props;
  const { loading } = props.user.components.update_account_settings;

  return (
    <div>
      <Header title="Join for free" loading={loading} />
      Upon joining, you'll be sent a new password. A team member will
      contact you to help fill out your account information.
      <input
        type="button"
        className="LinkButton"
        value="Join"
        onClick={() => completeInvite()}
      />
    </div>
  );
};

export default JoinForm;
