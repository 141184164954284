import * as ACTION from "../constants";

import { buildJsonRequest, getErrorMessage } from "../utils";
import { showToast } from "./";

export const getSeedCompanies = () => {
  return buildJsonRequest(
    {
      url: `/data/reference/managed_seeds/companies`,
      method: "GET"
    },
    ACTION.GET_SEED_COMPANIES
  );
};

export const getSeedsByCompany = (seedCompanyName) => {
  return buildJsonRequest(
    {
      url: `/data/reference/managed_seeds?company_name=${seedCompanyName}`,
      method: "GET"
    },
    ACTION.GET_SEEDS_BY_COMPANY
  );
};

export const updateManagedSeeds = (seed) => {
  return buildJsonRequest(
    {
      url: `/data/reference/managed_seeds`,
      method: "POST",
      data: {
        seed: seed
      }
    },
    ACTION.UPDATE_MANAGED_SEED,
    [
      () =>
        showToast(
          `Updated seed: ${seed.seed_company_name} ${seed.seed_name}`
        ),
      () => getSeedsByCompany(seed.seed_company_name)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const deleteManagedSeed = (seed) => {
  return buildJsonRequest(
    {
      url: "/data/reference/managed_seeds",
      method: "DELETE",
      data: {
        seed: seed
      }
    },
    ACTION.DELETE_MANAGED_SEED,
    [
      () =>
        showToast(
          `Seed Deleted: ${seed.seed_company_name} ${seed.seed_name}`
        )
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};
