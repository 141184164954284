// @flow

import React from "react";

import "./styles.scss";

import {
  Fit
  /*LayersControl, Options */
} from "./components";

type Props = {};

const Simulations = (props: Props) => {
  return (
    <div className="SimulationsPageContent">
      <Fit {...props} />
      {/* TODO: Put this back when layers exist
        <LayersControl {...props} /> */}
    </div>
  );
};

Simulations.defaultProps = {};
export default Simulations;
