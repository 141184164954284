// @flow

import React from "react";
import Default from "../img/default.png";

const CircleImage = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center"
      }}
    >
      <img
        src={props.img ? props.img : Default}
        alt={props.name}
        style={{
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          backgroundColor: "white"
        }}
      />
      <span style={{ width: "100%", fontWeight: "bold" }}>
        {" "}
        {props.name}{" "}
      </span>
      <span style={{ width: "100%" }}> {props.position} </span>
    </div>
  );
};

export default CircleImage;
