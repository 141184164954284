import * as ACTION from "../constants";

import { buildJsonRequest, getErrorMessage } from "../utils";
import { showToast } from "./";

export const getDataYears = () => {
  return {
    type: ACTION.GET_DATA_YEARS_SUCCESS,
    data: { years: [2021, 2020, 2019, 2018] }
  };
};

export const getProductPrices = (year, mode) => {
  return buildJsonRequest(
    {
      url: `/data/prices/${mode}?year=${year}`,
      method: "GET"
    },
    ACTION.GET_PRODUCT_PRICES
  );
};

export const getUnassignedProductPrices = (year, mode) => {
  return buildJsonRequest(
    {
      url: `/data/prices/${mode}/unassigned?year=${year}`,
      method: "GET"
    },
    ACTION.GET_UNASSIGNED_PRODUCT_PRICES
  );
};

export const updateProductPrice = (product, mode) => {
  return buildJsonRequest(
    {
      url: `/data/prices/${mode}`,
      method: "POST",
      data: {
        product_price: product
      }
    },
    ACTION.UPDATE_PRODUCT_PRICE,
    [
      () =>
        showToast(
          `Updated product price: ${product.product_name}${
            product.supplier_name ? ` (${product.supplier_name})` : ""
          }`
        ),
      () => getProductPrices(product.year, mode)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const deleteProductPrice = (product, mode) => {
  return buildJsonRequest(
    {
      url: `/data/prices/${mode}`,
      method: "DELETE",
      data: {
        product_price: product
      }
    },
    ACTION.DELETE_PRODUCT_PRICE,
    [
      () =>
        showToast(
          `Product Price Deleted: ${product.product_name}${
            product.supplier_name ? ` (${product.supplier_name})` : ""
          }`
        ),
      () => getProductPrices(product.year, mode)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};
