// @flow

import React, { useState } from "react";

import Sticky from "react-stickynode";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useNavigate } from "react-router";

import { ReactComponent as Logo } from "../../assets/mfi-smooth.svg";

import "./styles.scss";

import * as APP_ATTRIBUTE from "../../entitlements/appAttributes";
import { hasAppAttribute } from "../../entitlements";

import { AlertBar } from "../../components";

import { isMobile } from "react-device-detect";

// import image3 from "../../assets/img/sunset-241806.jpg";

import {
  Summary,
  About,
  // Contact,
  GetStarted,
  PremiumFeatures,
  Footer,
  Testimonials,
  HowTo,
  JoinForm
} from "./components";

type Props = {};

const Home = (props: Props) => {
  const { user } = props;
  const navigate = useNavigate();

  const [visibility, setVisibility] = useState({
    summary: false,
    about: false,
    getstarted: false,
    premium: false,
    contact: false,
    testimonials: false,
    tutorials: false
  });

  const [sticky, setSticky] = useState(0);

  const handleVisibilityChange = (id, visible) => {
    // Commented out due to glitchy rendering issue when scrolling up the page
    // Need to replace react - scrollable - anchor and react -in -viewport with a better solution
    //console.log("Handling visibility change " + id);
    var visibilityState = { ...visibility };

    Object.keys(visibilityState).forEach((key) => {
      visibilityState[key] = false;
    });

    setVisibility({ ...visibilityState, [id]: visible });
  };

  return (
    <div className="Home">
      <Summary
        onVisibilityChange={(id, visible) =>
          handleVisibilityChange(id, visible)
        }
      />
      <div className="ContentNav">
        {/* <header style={{ backgroundImage: `url(${image3})` }}>
            <Logo className="Logo" />
          </header> */}
        <Sticky
          enabled={true}
          top={0}
          bottomBoundary={14000}
          innerZ={1000}
          onStateChange={(status) => setSticky(status.status)}
        >
          <div className="Navigation">
            {sticky === Sticky.STATUS_FIXED && !isMobile && (
              <a
                href="#summary"
                className={visibility.summary ? "active" : null}
              >
                <Logo className="InlineLogo" />
              </a>
            )}

            <a
              className={visibility.getstarted ? "active" : null}
              href="#getstarted"
            >
              <span> Get Started</span>
            </a>
            {hasAppAttribute(APP_ATTRIBUTE.NDA_CONTENT, user) && (
              <a
                className={visibility.premium ? "active" : null}
                href="#premium"
              >
                <span> Our Story</span>
              </a>
            )}
            {hasAppAttribute(APP_ATTRIBUTE.NDA_CONTENT, user) && (
              <a
                className={visibility.tutorials ? "active" : null}
                href="#tutorials"
              >
                <span> Tutorials</span>
              </a>
            )}
            {hasAppAttribute(APP_ATTRIBUTE.NDA_CONTENT, user) && (
              <a
                className={visibility.testimonials ? "active" : null}
                href="#testimonials"
              >
                <span> Testimonials</span>
              </a>
            )}
            {/* <a
                className={this.state.contact ? "active" : null}
                href="#contact"
              >
                <span> Contact</span>
              </a> */}
            {/* <a className={visibility.about ? "active" : null} href="#about">
              <span> About</span>
            </a> */}
            {hasAppAttribute(APP_ATTRIBUTE.FARMER_APP, user) && (
              <a href="/blog" onClick={() => navigate("/blog")}>
                <span> Blog </span>
              </a>
            )}
            {hasAppAttribute(APP_ATTRIBUTE.FARMER_APP, user) &&
              !isMobile && (
                <a
                  href="/app/dashboard"
                  onClick={() => navigate("/app/dashboard")}
                >
                  <span> My Account</span>
                </a>
              )}
          </div>
          {!hasAppAttribute(APP_ATTRIBUTE.FARMER_APP, user) && (
            <AlertBar
              style={{
                marginBottom: "0",
                width: "100%"
              }}
            >
              <JoinForm {...props} />
            </AlertBar>
          )}
        </Sticky>
      </div>

      <GetStarted
        {...props}
        onVisibilityChange={(id, visible) =>
          handleVisibilityChange(id, visible)
        }
      />
      {hasAppAttribute(APP_ATTRIBUTE.NDA_CONTENT, user) && (
        <PremiumFeatures
          {...props}
          onVisibilityChange={(id, visible) =>
            handleVisibilityChange(id, visible)
          }
        />
      )}
      {hasAppAttribute(APP_ATTRIBUTE.NDA_CONTENT, user) && (
        <HowTo
          {...props}
          onVisibilityChange={(id, visible) =>
            handleVisibilityChange(id, visible)
          }
        />
      )}
      {hasAppAttribute(APP_ATTRIBUTE.NDA_CONTENT, user) && (
        <Testimonials
          {...props}
          onVisibilityChange={(id, visible) =>
            handleVisibilityChange(id, visible)
          }
        />
      )}
      <About
        onVisibilityChange={(id, visible) =>
          handleVisibilityChange(id, visible)
        }
      />
      <Footer {...props} />
    </div>
  );
};

Home.defaultProps = {};
export default Home;
