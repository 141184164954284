import * as ACTION from "../constants";

import {
  buildJsonRequest,
  buildRasterRequest,
  buildProcessingJsonRequest,
  buildFileDownloadRequest,
  getErrorMessage
} from "../utils";
import { showToast } from "./toast";
import { consoleLog } from "./app";

export const getLayerGeospatial = (reportType, field_id, year) => {
  return buildJsonRequest(
    {
      url: `/layers/layer_loc?field_id=${field_id}&name=${reportType}&year=${year}`,
      method: "GET",
      metadata: {
        name: reportType,
        field_id: field_id,
        year: year,
        content_type: "geojson"
      }
    },
    ACTION.GET_LAYER_GEOSPATIAL,
    [(response) => consoleLog(response)],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const getLayerRaster = (reportType, field_id, year) => {
  return buildRasterRequest(
    {
      url: `/reports/${reportType}/geojson?field_id=${field_id}&year=${year}`,
      method: "GET",
      metadata: {
        name: reportType,
        field_id: field_id,
        year: year,
        content_type: "georaster"
      }
    },
    ACTION.GET_LAYER_GEOSPATIAL,
    [(response) => consoleLog(response)],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const getBoundaryGeojson = (field_id) => {
  return buildJsonRequest(
    {
      url: `/data/fields/boundary/geojson?field_id=${field_id}`,
      method: "GET"
    },
    ACTION.GET_BOUNDARY_GEOJSON,
    [(response) => consoleLog(response)],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const getAvailableLayers = (field_id) => {
  return buildJsonRequest(
    {
      url: `/layers/list?field_id=${field_id}`,
      method: "GET"
    },
    ACTION.GET_AVAILABLE_LAYERS,
    [(response) => consoleLog(response)],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const downloadPrescriptionShapefile = (
  field_id,
  field_name,
  rates,
  layer
) => {
  if (layer === 'fertility') {
    //Implement here
  } else  {
  return buildFileDownloadRequest(
    {
      url: `/prescriptions/shapefiles`,
      method: "POST",
      data: {
        field_id: field_id,
        rates: rates,
        layer: layer
      },
      outputFileName: `Rx ${field_name}.zip`
    },
    ACTION.DOWNLOAD_PRESCRIPTION_SHAPEFILE,
    [(response) => consoleLog(response)],
    [(error) => showToast(getErrorMessage(error))]
  );
  }
};

export const setLayerVariable = (
  field_id,
  layerGroup,
  layer,
  sublayer,
  variableName,
  variableValue
) => {
  return {
    type: ACTION.SET_LAYER_VARIABLE,

    data: {
      field_id,
      layerGroup,
      layer,
      sublayer,
      variableName,
      variableValue
    }
  };
};

export const getLayer = (name, field_id, year) => {
  return buildJsonRequest(
    {
      url: `/layers/layer_data?field_id=${field_id}&name=${name}&year=${year}`,
      method: "GET"
    },
    ACTION.GET_LAYER,
    [(response) => consoleLog(response)],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const removeLayer = (name, field_id, year) => {
  return {
    type: ACTION.REMOVE_LAYER,
    fieldId: field_id,
    name: name,
    year: year
  };
};

export const runSimulation = (
  simulationType,
  field_id,
  year,
  crop_type,
  options
) => {
  return buildProcessingJsonRequest(
    {
      url: `/simulations/${simulationType}/reprocess`,
      method: "POST",
      data: {
        field_id: field_id,
        year: year,
        crop_type: crop_type
        //hybrids: options
      }
    },
    ACTION.RUN_SIMULATION,
    [
      (response) => consoleLog(response),
      () => showToast(`Simulation reprocessed for field: ${field_id}`)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};
