import * as ACTION from "../constants";

export const displayModal = (
  message,
  confirmButtonLabel,
  onConfirm,
  cancelButtonLabel,
  onCancel
) => {
  return {
    type: ACTION.DISPLAY_MODAL,
    message: message,
    confirmButtonLabel: confirmButtonLabel,
    cancelButtonLabel: cancelButtonLabel,
    onConfirm: onConfirm,
    onCancel: onCancel
  };
};

export const displayVideoModal = (video) => {
  return {
    type: ACTION.DISPLAY_MODAL,
    video: video
  };
};

export const dismissModal = () => {
  return {
    type: ACTION.DISMISS_MODAL
  };
};

export const consoleLog = (content) => {
  return {
    type: ACTION.CONSOLE_LOG,
    content: content
  };
};

export const selectField = (
  fieldName,
  fieldId,
  fieldCoords,
  fieldBoundaryLocked
) => {
  return (dispatch) => {
    dispatch({
      type: ACTION.SELECT_FIELD,
      fieldName: fieldName,
      fieldId: fieldId,
      fieldCoords: fieldCoords,
      fieldBoundaryLocked: fieldBoundaryLocked
    });
  };
};

export const selectFarm = (farmId, farmName, farmColor) => {
  return {
    type: ACTION.SELECT_FARM,
    farmName: farmName,
    farmId: farmId,
    farmColor: farmColor
  };
};

export const flyTo = (coords, zoom) => {
  return {
    type: ACTION.FLY_TO,
    coords: coords,
    zoom: zoom
  };
};

export const setZoom = (zoom) => {
  return {
    type: ACTION.SET_ZOOM,
    zoom: zoom
  };
};

export const dispatchAtDelay = (action, delay = 10000) => {
  return (dispatch) => {
    setTimeout(
      () =>
        dispatch({
          type: action
        }),
      delay
    );
  };
};

export const dismissBrowserWarning = () => {
  return {
    type: ACTION.DISMISS_BROWSER_WARNING
  };
};

export const dismissInvestmentBanner = () => {
  return {
    type: ACTION.DISMISS_INVESTMENT_BANNER
  };
};
