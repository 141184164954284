// @flow

import React, { useState } from "react";
import { AuthenticationPage } from ".";
import "./styles.scss";

import { Input, AlertBar, Loading } from "../../components/";

//type Props = {};
import { useNavigate, useParams } from "react-router";

const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [validationError, setValidationError] = useState(undefined);

  const validate = () => {
    var validationMessage = "";

    if (!password) {
      validationMessage =
        validationMessage + "\u2022 Password is required.\n";
    }
    if (
      password &&
      !/^(?=.*[\d])(?=.*[a-z])[\w!@#$%^&*]{7,}$/.test(password)
    ) {
      validationMessage =
        validationMessage +
        "\u2022 Password must be at least 7 characters long and must include letters and numbers.\n";
    }
    setValidationError(validationMessage);
    return validationMessage === "";
  };

  const navigate = useNavigate();

  const {
    error,
    loading,
    success
  } = props.user.components.reset_password;

  const { resetPassword } = props;
  const { token } = useParams();

  return (
    <AuthenticationPage>
      <div className="LoginHeader">
        <span className="active">Reset Password</span>
      </div>

      <div className="FormContainer">
        {validationError || error ? (
          <AlertBar>
            {validationError ? (
              <div>
                {" "}
                {validationError.split("\n").map((item) => {
                  return <div>{item}</div>;
                })}
              </div>
            ) : null}
            {error}
          </AlertBar>
        ) : null}
        {success && (
          <AlertBar type="success">
            Password updated successfully!
          </AlertBar>
        )}
        {loading ? <Loading /> : null}
        <form className="LoginForm">
          {success && (
            <React.Fragment>
              <input
                type="button"
                value="Go to Login"
                onClick={() => {
                  navigate("/app/login");
                }}
              />
            </React.Fragment>
          )}

          {!success && (
            <React.Fragment>
              <label>
                <span>Password:</span>
                <Input
                  className="PasswordInput"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(evt) => setPassword(evt.target.value)}
                />
              </label>

              <input
                type="button"
                value="Update Password"
                onClick={() => {
                  validate()
                    ? resetPassword(password, token)
                    : console.log("validation error");
                }}
              />
            </React.Fragment>
          )}
        </form>
      </div>
    </AuthenticationPage>
  );
};

ResetPassword.defaultProps = {};
export default ResetPassword;
