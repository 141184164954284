// @flow

import React from "react";

import { Header } from "../../../components";

type Props = {};

const DeleteUser = (props: Props) => {
  const {
    error,
    loading,
    success
  } = props.user.components.delete_user;
  const { deleteUser } = props;

  return (
    <React.Fragment>
      <Header
        className="DeleteWarning"
        title="Delete Account"
        loading={loading}
        success={success}
        error={error}
      />
      <div className="DeleteWarning">
        {" "}
        This option permanently deletes your account. All data
        associated with this account will be removed from the MFI
        system.
      </div>

      <form className="ProfileForm">
        <input
          type="button"
          className="DeleteButton"
          value="Delete My Account"
          onClick={() => deleteUser()}
        />
      </form>
    </React.Fragment>
  );
};

DeleteUser.defaultProps = {};
export default DeleteUser;
