// @flow

import React from "react";

import { getColorMapping, sortYieldEnvs } from "../../../../../utils";
import { Status, HeatmapLegend } from "../../../../../components";

type Props = {};

const Yield = (props: Props) => {
  const { layer } = props;

  let reportData =
    layer.name === "yield"
      ? layer.data.yield_env_financials
      : layer.data.yield_env_removal_rates;

  const colorMapping = getColorMapping("yield", reportData.length);

  return (
    <React.Fragment>
      <HeatmapLegend
        style={{ display: "grid", gridTemplateColumns: "1fr" }}
      >
        <div
          className="HeatmapLegendTitle"
          style={{
            display: "grid",
            gridTemplateColumns: "2em 1fr 1fr 1fr 1fr"
          }}
        >
          <span />
          <span>Yield Env.</span>
          <span>% Acres</span>

          <span>Avg Yield</span>
          <span>Dollar Value</span>
        </div>

        {reportData.sort(sortYieldEnvs).map((item, index) => (
          <div
            key={item.yield_env}
            style={{
              display: "grid",
              gridTemplateColumns: "2em 1fr 1fr 1fr 1fr"
            }}
          >
            <Status backgroundColor={colorMapping[item.yield_env]} />

            <span
            // style={{
            //   backgroundColor: getColorMapping("yield", 3)[item.yield_env]
            // }}
            >
              {index === 0
                ? `\uFF1E ${item.start_range}`
                : index + 1 === reportData.length
                ? `\uFF1C ${item.end_range}`
                : `${item.start_range} - ${item.end_range}`}
            </span>
            <span>{item.percent_acres}%</span>
            <span>{item.avg_yield}</span>
            <span>
              $
              {item.dollar_value
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </span>
          </div>
        ))}
      </HeatmapLegend>
    </React.Fragment>
  );
};

Yield.defaultProps = {};
export default Yield;
