import * as React from "react";

import "./styles.scss";
import { Toast } from "./components";

const Toasts = (props) => {
  const { toasts, hideToast } = props;

  return (
    <div className="Toasts">
      {toasts &&
        toasts.map((toast, index) => (
          <Toast
            key={index}
            toast={toast}
            hideToast={() => hideToast(toast)}
          />
        ))}
    </div>
  );
};

export default Toasts;
