import React from "react";
import { Toolbar, Map, FieldList, TabBar } from "..";

import "./styles.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { isMobile } from "react-device-detect";

const PageWithMap = (props) => {
  const { title } = props;

  return (
    <div className={`PageWithMap ${isMobile ? "mobile" : "browser"}`}>
      <Toolbar activePage={title} />
      {isMobile ? (
        <TabBar tabList={["Map", "Fields", "Data"]}>
          <Map {...props} />
          <FieldList placeholder={"Search all fields"} {...props} />
          <div className="PageContentWrapper">
            <div className="PageContent">{props.children}</div>
          </div>
        </TabBar>
      ) : (
        <React.Fragment>
          <div className="FieldsSelector">
            <Map {...props} />
            <FieldList placeholder={"Search all fields"} {...props} />
          </div>
          <div className="PageContentWrapper">
            <div className="PageContent">{props.children}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PageWithMap;
