import * as ACTION from "../constants";

import { buildJsonRequest, getErrorMessage } from "../utils";
import {
  showToast,
  getFileStatusByYear,
  getUnassignedProductPrices
} from "./";

export const getDataYears = () => {
  return {
    type: ACTION.GET_DATA_YEARS_SUCCESS,
    data: { years: [2021, 2020, 2019, 2018] }
  };
};

export const getPracticeProducts = (year) => {
  return buildJsonRequest(
    {
      url: `/data/practices/practice_product?year=${year}`,
      method: "GET"
    },
    ACTION.GET_PRACTICE_PRODUCTS
  );
};

export const updatePracticeProduct = (practice_product) => {
  return buildJsonRequest(
    {
      url: `/data/practices/practice_product`,
      method: "POST",
      data: {
        practice_product: practice_product
      }
    },
    ACTION.UPDATE_PRACTICE_PRODUCT,
    [
      () =>
        showToast(
          `Updated practice: ${practice_product.practice_name}`
        ),
      () => getPracticeProducts(practice_product.year),
      () => getUnassignedProductPrices(practice_product.year, "input")
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const copyPracticeProducts = (practice_ids, copy_to_year) => {
  return buildJsonRequest(
    {
      url: `/data/practices/practice_product/copy`,
      method: "POST",
      data: {
        practice_ids: practice_ids,
        copy_to_year: copy_to_year
      }
    },
    ACTION.COPY_PRACTICE_PRODUCT,
    [() => showToast(`Copied practices`)],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const deletePracticeProduct = (practice_product) => {
  return buildJsonRequest(
    {
      url: `/data/practices/practice_product`,
      method: "DELETE",
      data: {
        practice_product: practice_product
      }
    },
    ACTION.DELETE_PRACTICE_PRODUCT,
    [
      () =>
        showToast(
          `Practice Deleted: ${practice_product.practice_name}`
        ),
      () => getPracticeProducts(practice_product.year),
      () => getUnassignedProductPrices(practice_product.year, "input")
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const updateFieldPractices = (field_practices) => {
  return buildJsonRequest(
    {
      url: `/data/practices/field_practices`,
      method: "POST",
      data: {
        field_practices: field_practices
      }
    },
    ACTION.UPDATE_FIELD_PRACTICES,
    [
      () => showToast(`Updated field practices`),
      () => getPracticeProducts(field_practices[0].year),
      () => getFileStatusByYear(field_practices[0].year)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const deleteFieldPractice = (field_practice) => {
  return buildJsonRequest(
    {
      url: `/data/practices/field_practice`,
      method: "DELETE",
      data: {
        field_practice: field_practice
      }
    },
    ACTION.DELETE_FIELD_PRACTICE,
    [
      () => showToast(`Field Practice Deleted`),
      () => getPracticeProducts(field_practice.year),
      () => getFileStatusByYear(field_practice.year)
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};
