//prettier-ignore
import React from "react";

const Toast = (props: Props) => {
  const { toast, hideToast } = props;
  return (
    <div className="Toast">
      <div className="Message">{toast.text}</div>
      <div onClick={hideToast} className="Close">
        x
      </div>
    </div>
  );
};

export default Toast;
