import * as ACTION from "../constants";

import axios from "axios";

import TypeaheadOptions from "../utils/typeaheadOptions";

const apiURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API
    : process.env.REACT_APP_DEV_API;

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(endpoint, value) {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === "") {
    return [];
  }

  const regex = new RegExp("^" + escapedValue, "i");

  return TypeaheadOptions[endpoint].filter((practice) =>
    regex.test(practice)
  );
}

export const getTypeaheadSuggestions = (endpoint, typedValue) => {
  //TODO - handle difference lookups (some are api, some are local)
  switch (endpoint) {
    case "tillage":
    case "planting":
    case "fertility":
    case "harvest":
    case "units":
    case "fungicide_products":
    case "fertilizer_products":
      let suggestions = getSuggestions(endpoint, typedValue);
      return (dispatch) => {
        dispatch({
          type: ACTION.GET_TYPEAHEAD_SUGGESTIONS_SUCCESS,
          endpoint,
          data: {
            list: suggestions
          }
        });
      };
    case "products":
    case "managed_seeds":
    case "seedlist":
      let savedState = JSON.parse(sessionStorage.getItem("state"));

      let token = savedState ? savedState.session.token : null;

      var request = {
        url:
          apiURL +
          `/data/reference/typeahead/${endpoint}?query=${typedValue}`,
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json; charset=utf-8"
        }
      };

      return (dispatch) => {
        dispatch({
          type: ACTION.GET_TYPEAHEAD_SUGGESTIONS,
          endpoint
        });
        return axios
          .request(request)
          .then((response) => {
            dispatch({
              type: ACTION.GET_TYPEAHEAD_SUGGESTIONS_SUCCESS,
              data: response.data,
              endpoint
            });
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              dispatch({
                type: ACTION.LOG_OUT
              });
            } else {
              dispatch({
                type: ACTION.GET_TYPEAHEAD_SUGGESTIONS_FAIL,
                error: error.response
                  ? error.response.data.msg
                  : error.message,
                endpoint
              });
            }
          });
      };
    default:
      return (dispatch) => {
        dispatch({
          type: ACTION.CLEAR_TYPEAHEAD_SUGGESTIONS,
          endpoint
        });
      };
  }
};

export const clearTypeaheadSuggestions = (endpoint) => ({
  type: ACTION.CLEAR_TYPEAHEAD_SUGGESTIONS,
  endpoint
});
