import * as ACTION from "../constants";

export const filesReducer = (
  state = {
    fields: [],
    planted_fields: [],
    years: [],
    components: {
      get_file_status: {
        loading: false,
        error: undefined,
        success: undefined
      },
      get_file_years: {
        loading: false,
        error: undefined,
        success: undefined
      },
      delete_file: {
        loading: false,
        error: undefined,
        success: undefined
      },
      reprocess_file: {
        loading: false,
        error: undefined,
        success: undefined
      },
      reprocess_seed_fit: {
        loading: false,
        error: undefined,
        success: undefined
      },
      reprocess_fertility: {
        loading: false,
        error: undefined,
        success: undefined
      },
      validate_seeds: {
        loading: false,
        error: undefined,
        success: undefined
      },
      planted_seeds: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_seed_name: {
        loading: false,
        error: undefined,
        success: undefined
      }
    }
  },
  action
) => {
  switch (action.type) {
    case ACTION.GET_FILE_STATUS:
      return {
        ...state,
        components: {
          ...state.components,
          get_file_status: {
            ...state.components.get_file_status,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.GET_FILE_STATUS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          get_file_status: {
            ...state.components.get_file_status,
            loading: false,
            response: action.data,
            success: action.data
          }
        },
        fields: action.data.fields
      };
    case ACTION.GET_FILE_STATUS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_file_status: {
            ...state.components.get_file_status,
            loading: false,
            error: action.error
          }
        },
        fields: []
      };
    case ACTION.GET_FILE_YEARS:
      return {
        ...state,
        components: {
          ...state.components,
          get_file_years: {
            ...state.components.get_file_years,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.GET_FILE_YEARS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          get_file_years: {
            ...state.components.get_file_years,
            loading: false,
            response: action.data,
            success: action.data
          }
        },
        years: action.data.years
      };
    case ACTION.GET_FILE_YEARS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_file_years: {
            ...state.components.get_file_years,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.REPROCESS_FILE:
      return {
        ...state,
        components: {
          ...state.components,
          reprocess_file: {
            ...state.components.reprocess_file,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.REPROCESS_FILE_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          reprocess_file: {
            ...state.components.reprocess_file,
            loading: false,
            response: action.data,
            success: action.data
          }
        }
      };
    case ACTION.REPROCESS_FILE_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          reprocess_file: {
            ...state.components.reprocess_file,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.DELETE_FILE:
      return {
        ...state,
        components: {
          ...state.components,
          delete_file: {
            ...state.components.delete_file,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.DELETE_FILE_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          delete_file: {
            ...state.components.delete_file,
            loading: false,
            response: action.data,
            success: action.data
          }
        }
      };
    case ACTION.DELETE_FILE_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          delete_file: {
            ...state.components.delete_file,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.REPROCESS_SEED_FIT:
      return {
        ...state,
        components: {
          ...state.components,
          reprocess_seed_fit: {
            ...state.components.reprocess_seed_fit,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.REPROCESS_SEED_FIT_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          reprocess_seed_fit: {
            ...state.components.reprocess_seed_fit,
            loading: false,
            response: action.data,
            success: action.data
          }
        }
      };
    case ACTION.REPROCESS_SEED_FIT_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          reprocess_seed_fit: {
            ...state.components.reprocess_seed_fit,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.REPROCESS_FERTILITY:
      return {
        ...state,
        components: {
          ...state.components,
          reprocess_fertility: {
            ...state.components.reprocess_fertility,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.REPROCESS_FERTILITY_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          reprocess_fertility: {
            ...state.components.reprocess_fertility,
            loading: false,
            response: action.data,
            success: action.data
          }
        }
      };
    case ACTION.REPROCESS_FERTILITY_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          reprocess_fertility: {
            ...state.components.reprocess_fertility,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.VALIDATE_SEEDS:
      return {
        ...state,
        components: {
          ...state.components,
          validate_seeds: {
            ...state.components.validate_seeds,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.VALIDATE_SEEDS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          validate_seeds: {
            ...state.components.validate_seeds,
            loading: false,
            response: action.data,
            success: action.data
          }
        }
      };
    case ACTION.VALIDATE_SEEDS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          validate_seeds: {
            ...state.components.validate_seeds,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.GET_PLANTED_SEEDS:
      return {
        ...state,
        components: {
          ...state.components,
          planted_seeds: {
            ...state.components.planted_seeds,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.GET_PLANTED_SEEDS_SUCCESS:
      return {
        ...state,
        planted_fields: action.data.fields,
        components: {
          ...state.components,
          planted_seeds: {
            ...state.components.planted_seeds,
            loading: false,
            response: action.data,
            success: action.data
          }
        }
      };
    case ACTION.GET_PLANTED_SEEDS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          planted_seeds: {
            ...state.components.planted_seeds,
            loading: false,
            error: action.error
          }
        }
      };

    case ACTION.UPDATE_SEED_NAME:
      return {
        ...state,
        components: {
          ...state.components,
          update_seed_name: {
            ...state.components.update_seed_name,
            loading: true,
            success: undefined,
            error: undefined
          }
        }
      };
    case ACTION.UPDATE_SEED_NAME_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_seed_name: {
            ...state.components.update_seed_name,
            loading: false,
            response: action.data,
            success: action.data
          }
        }
      };
    case ACTION.UPDATE_SEED_NAME_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_seed_name: {
            ...state.components.update_seed_name,
            loading: false,
            error: action.error
          }
        }
      };
    default:
      return state;
  }
};
