import * as ACTION from "../constants";

export const inboxReducer = (
  state = {
    messages: [],
    components: {
      inbox: {
        loading: false,
        error: undefined,
        success: undefined
      },
      contact: {
        loading: false,
        error: undefined,
        success: undefined
      }
    }
  },
  action
) => {
  switch (action.type) {
    case ACTION.GET_INBOX:
      return {
        ...state,
        components: {
          ...state.components,
          inbox: {
            ...state.components.inbox,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.GET_INBOX_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          inbox: {
            ...state.components.inbox,
            error: undefined,
            loading: false,
            success: true
          }
        },
        messages: action.data.notifications
      };
    case ACTION.GET_INBOX_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          inbox: {
            ...state.components.inbox,
            error: action.error,
            loading: false
          }
        }
      };
    case ACTION.MARK_MESSAGE_READ:
      var message = state.messages.filter(function (message) {
        return message.id === action.request.id;
      })[0];
      message.has_read = action.request.has_read;
      return state;
    case ACTION.ARCHIVE_MESSAGE:
      let messages = state.messages.filter(function (message) {
        return message.id !== action.request.id;
      });
      return {
        ...state,
        messages: messages
      };
    case ACTION.CONTACT_TEAM:
    case ACTION.REQUEST_TO_BUY_UNITS:
      return {
        ...state,
        components: {
          ...state.components,
          contact: {
            ...state.components.contact,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.CONTACT_TEAM_SUCCESS:
    case ACTION.REQUEST_TO_BUY_UNITS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          contact: {
            ...state.components.contact,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.CONTACT_TEAM_FAIL:
    case ACTION.REQUEST_TO_BUY_UNITS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          contact: {
            ...state.components.contact,
            error: action.error,
            loading: false
          }
        }
      };
    default:
      return state;
  }
};
