import React from "react";

import "./styles.scss";

import Autosuggest from "react-autosuggest";

const theme = {
  container: {
    position: "relative"
  },
  // input: {
  //   width: 240,
  //   height: 30,
  //   padding: '10px 20px',
  //   fontFamily: 'Helvetica, sans-serif',
  //   fontWeight: 300,
  //   fontSize: 16,
  //   border: '1px solid #aaa',
  //   borderTopLeftRadius: 4,
  //   borderTopRightRadius: 4,
  //   borderBottomLeftRadius: 4,
  //   borderBottomRightRadius: 4,
  // },
  inputFocused: {
    outline: "none"
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  suggestionsContainer: {
    display: "none"
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 51,
    width: 200,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    color: "#272320"
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px"
  },
  suggestionHighlighted: {
    backgroundColor: "#ddd"
  }
};

class TypeaheadInput extends React.Component {
  render() {
    const {
      placeholder,
      value,
      onChange,
      name,
      endpoint
    } = this.props;

    let suggestions = this.props.typeahead[endpoint]
      ? this.props.typeahead[endpoint]
      : [];

    const inputProps = {
      name,
      placeholder,
      value,
      onChange: (event, { newValue, method }) => {
        if (method === "click") {
          onChange({
            target: {
              name,
              value: newValue
            }
          });
        } else {
          onChange(event);
        }
      }
    };

    return (
      <div className="InputWrapper">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={(value) => {
            if (value.value.length >= 2) {
              this.props.getTypeaheadSuggestions(
                endpoint,
                value.value
              );
            }
          }}
          onSuggestionsClearRequested={() =>
            this.props.clearTypeaheadSuggestions(endpoint)
          }
          getSuggestionValue={(suggestion) => {
            return suggestion;
          }}
          renderSuggestion={(suggestion) => <span>{suggestion}</span>}
          inputProps={inputProps}
          theme={theme}
        />
      </div>
    );
  }
}

TypeaheadInput.defaultProps = {};

export default TypeaheadInput;
