// @flow

import React from "react";
import "./styles.scss";

import { Toolbox, Page, Loading } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = { handleUpload: Function };

class Inbox extends React.Component<Props> {
  state = {
    selectedIndex: 0
  };
  render() {
    const { selectedIndex } = this.state;
    const { messages } = this.props.inbox;
    const { loading } = this.props.inbox.components.inbox;

    return (
      <Page>
        <Toolbox title="inbox">
          {loading && <Loading />}
          {messages &&
            messages.map((message, index) => {
              return (
                <div
                  className="Inbox Subject"
                  key={message.id}
                  style={{
                    fontWeight: message.has_read ? "light" : "bold",
                    color: message.has_read ? "" : "#f5d870"
                  }}
                  onClick={() => {
                    this.setState({ selectedIndex: index });
                    this.props.markMessageRead(message.id);
                  }}
                >
                  <FontAwesomeIcon
                    // className="Icon"
                    icon={
                      message.has_read
                        ? "envelope-open-text"
                        : "envelope"
                    }
                    size="1x"
                  />
                  {message.msg_subject}
                </div>
              );
            })}
        </Toolbox>
        {messages && messages[selectedIndex] && (
          <div className="Inbox Message" key={selectedIndex}>
            <div>
              <h2>From: {messages[selectedIndex].msg_sender}</h2>
            </div>
            <div>
              <h2>Subject: {messages[selectedIndex].msg_subject}</h2>
            </div>
            <div>{messages[selectedIndex].msg_content} </div>
          </div>
        )}
      </Page>
    );
  }
}

Inbox.defaultProps = {
  inbox: {
    messages: []
  }
};
export default Inbox;
