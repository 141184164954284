import React from "react";
import ReactTooltip from "react-tooltip";
import IMask from "imask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./styles.scss";

class Input extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      showPassword: false,
      type: props.type
    };
  }
  state = {
    showPassword: false
  };
  render() {
    return (
      <div className="InputWrapper">
        <ReactTooltip
          id="InputTooltip"
          border={true}
          borderColor="#f5d870"
          className="Tooltip"
        />

        <input
          {...this.props}
          type={
            this.state.type === "password" && !this.state.showPassword
              ? "password"
              : "text"
          }
          onChange={(event) => {
            if (this.props.mask) {
              var mask = IMask.createMask({
                mask: this.props.mask
                // ...and other options
              });
              var maskedValue = mask.resolve(event.target.value);

              event.target.value = maskedValue;
            }
            this.props.onChange(event);
          }}
        />
        {this.state.type === "password" ? (
          <React.Fragment>
            <FontAwesomeIcon
              className="ShowPasswordIcon"
              icon={this.state.showPassword ? "eye-slash" : "eye"}
              size="1x"
              data-for="InputTooltip"
              data-tip={this.state.showPassword ? "hide" : "show"}
              onClick={() => {
                this.setState({
                  showPassword: !this.state.showPassword
                });
              }}
            />
            <span
              className="ShowPasswordIcon"
              onClick={() => {
                this.setState({
                  showPassword: !this.state.showPassword
                });
              }}
            >
              {/* {this.state.showPassword ? "hide" : "show"} */}
            </span>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

Input.defaultProps = {};

export default Input;
