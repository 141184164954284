import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { loadState, saveState } from "./utils";

import _ from "lodash";

import { rootReducer } from "./reducers";

const loggerMiddleware = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error, // collapse if no errors
  duration: true,
  predicate: (getState, action) => {
    if (action) {
      let includes = (str) => action.type.includes(str);
      switch (true) {
        case !includes("_FAIL"):
          return false;
        default:
          return true;
      }
    } else {
      return null;
    }
  }
});

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  compose(applyMiddleware(thunkMiddleware, loggerMiddleware))
);

store.subscribe(
  _.throttle(() => {
    var session = store.getState().session;

    saveState({
      session: session
    });
  })
);

export default store;
