import React from "react";
import Slider from "react-slide-out";
import "react-slide-out/lib/index.css";
import "./styles.scss";

const SliderModal = (props) => {
  return (
    <Slider
      title={props.title}
      isOpen={props.isOpen}
      onOutsideClick={props.onOutsideClick}
      footer={props.footer}
    >
      {props.children}
    </Slider>
  );
};
export default SliderModal;
