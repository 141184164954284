// @flow

import React, { useState } from "react";

import Sticky from "react-stickynode";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useNavigate } from "react-router";

import { ReactComponent as Logo } from "../../assets/mfi-smooth.svg";

import "./styles.scss";

import { Post } from "./components";

import Figure1 from "./img/figure1.JPG";
import Figure2 from "./img/figure2.JPG";

type Props = {};

const Posts = [
  // Add new blog posts to the top of this ***************************************************************************************************************************
  {
    date: "11/14/20",
    title: "Progress Update: Overview of Progress Made",
    body: (
      <div>
        <p>
          We would like to share with everyone the progress we've made
          on the items listed in Value Roadmap.
        </p>
        <p>Learn more in this video.</p>
      </div>
    ),
    video: {
      src:
        "https://ddwzudgjmurcz.cloudfront.net/year-end-update/14Nov2020Presentation.mp4",
      poster:
        "https://ddwzudgjmurcz.cloudfront.net/share/mfi-splash.JPG"
    },
    slug: "11/14/20-year-update"
  },
  {
    date: "11-11-20",
    title: "Progress Update: Grant Strom",
    body: (
      <div>
        <p>
          Chairman of the Board Grant Strom gives an update on MFI.
        </p>
        <p>Learn more in this video.</p>
      </div>
    ),
    video: {
      src:
        "https://ddwzudgjmurcz.cloudfront.net/share/grant-update.mp4",
      poster:
        "https://ddwzudgjmurcz.cloudfront.net/share/mfi-splash.JPG"
    },
    slug: "11-11-20-grant-update"
  },
  {
    date: "11/3/20",
    title: "Feature Update: Seed Tool",
    body: (
      <div>
        <p>
          We are proud to announce the release of the MFI Seed
          Selection tool.
        </p>
        <p>Learn more in this video.</p>
      </div>
    ),
    video: {
      src: "https://ddwzudgjmurcz.cloudfront.net/share/seedtool.mp4",
      poster:
        "https://ddwzudgjmurcz.cloudfront.net/share/mfi-splash.JPG"
    },
    slug: "11/3/20-seed-selection"
  },
  {
    date: "9/28/20",
    title: "Dev Update: MFI Water Drainage Modeling",
    body: (
      <div>
        <p>
          The MFI Drainage Modeling is based on topographical data
          gathered from LiDAR overlayed with soils information from
          the gSSURGO database. Using GIS software and some creative
          coding, we can take a planter or harvest file and create a
          boundary of the field. This boundary is used to query the
          gSSURGO database, which contains detailed soils information.
          This process returns a map of the field and the surrounding
          area with detailed soils information from Available Water
          Storage (AWS) to National Commodity Crop Productivity Index
          (NCCPI) values.
        </p>
        <p>
          Simply pulling the Available Water Storage values for the
          different areas provides what data we need. By mapping the
          AWS values with GIS software and color coding by AWS values,
          it’s possible to prove a valuable assessment of the flood
          risk zones.{" "}
        </p>
        <img
          name="Figure 1"
          src={Figure1}
          alt="Empty"
          style={{
            width: "400px",
            height: "300px",
            backgroundColor: "white"
          }}
        />
        <p>
          In Figure 1, the background map shows the AWS from 0 to a
          depth of 20 cm in inches of water, and the points in the
          foreground represent yield, in bushels per acre. This is a
          field that has been drain tiled. The harvest data is from
          2019, which saw record amounts of rainfall. From this
          figure, it’s easy to see there is a strong correlation
          between the areas that are yielding less, shown in blue, and
          the areas of the field that have poor water storage
          properties.{" "}
        </p>
        <p>
          In addition to the soil properties, LiDAR data allows us to
          create detailed topographical maps of fields on demand.
          These maps allow us to create slope and gradient maps of
          fields, which combined with the expert knowledge of an
          Agronomist or the farmer, can provide insight into the
          happenings of the water flowing through the field.{" "}
        </p>
        <img
          name="Figure 2"
          src={Figure2}
          alt="Empty"
          style={{
            width: "400px",
            height: "400px",
            backgroundColor: "white"
          }}
        />
        <p>
          A lot of work has been done to come to this point of being
          able to readily combine different datasets and create the
          necessary workflow to visualize the data properly. From this
          steppingstone there is a lot of very interesting and
          valuable insights to be gained from this system. The gSSURGO
          dataset allows us to examine the water storage properties at
          different depths, which is the first step for modeling a
          more comprehensive water drainage environment.{" "}
        </p>
        <p>
          The addition of live and historical weather data will allow
          us to estimate the current levels of water in the soil and
          issue warning of areas that may be nearing or exceeding
          saturation of the ground layers resulting in standing water.
          This data isn’t just useful for the in-season timeframe, but
          for long term decisions as well. The ability to make an
          objective determination of the hydrological profile of a
          field from only having the boundary would open a whole world
          of possibilities. Using historical weather data would allow
          us to correct the modeling based on previous observations,
          as well as give a glimpse at the propensity for a field to
          flood over the years. Furthermore once the flow of water
          through the ground has been estimated it’s possible to
          account for the depletion of soil nutrients from rainfall
          and account for it with fertilizer applications in a
          precise, efficient way.{" "}
        </p>
      </div>
    ),
    slug: "09-28-20-dev-update-drainage"
  },
  {
    date: "9/23/20",
    title: "Feature Update: Seed Name Validation",
    body: (
      <div>
        <p>
          It's time to validate your planted seed names for 2020!
          These will be used by the seed fit tool for this year's data
          set.
        </p>
        <p>
          You can find the tool in a new location under the new Data
          Management section.
        </p>
        <p>
          Please note that it may take some time to process updates to
          seed names if you have a large amount of data loaded in the
          system. We'll be improving this tool in the near future to
          ensure that seed updates for large fields will be processed
          in the background.
        </p>
        <p>Learn more in this video.</p>
      </div>
    ),
    video: {
      src:
        "https://ddwzudgjmurcz.cloudfront.net/amanda-seedvalidation/video.mp4",
      poster:
        "https://ddwzudgjmurcz.cloudfront.net/amanda-seedvalidation/poster.png"
    },
    slug: "09-23-20-seed-validation"
  },
  {
    date: "9/23/20",
    title: "Feature Update: Seed Dealer Tool",
    body: (
      <div>
        <p>
          Calling all seed dealers! Our seed dealer data tool is ready
          to use. Please enter your seed data, and don't forget to
          include Days to Maturity information as well!{" "}
        </p>
        <p>
          You can access the tool under the new Data Management
          section.
        </p>
        <p> Watch this tutorial to learn more.</p>
      </div>
    ),
    video: {
      src:
        "https://ddwzudgjmurcz.cloudfront.net/amanda-seeddealer/video.mp4",
      poster:
        "https://ddwzudgjmurcz.cloudfront.net/amanda-seeddealer/poster.png"
    },
    slug: "09-23-20-seed-dealer"
  },
  {
    date: "9/23/20",
    title: "Feature Update: Seed Fit Tool Enhancements",
    body: (
      <div>
        <p>
          Just in time for fall, the seed fit tool has been enhanced
          with useful new capabilities. Be sure to validate your seed
          data soon to take advantage of these new options!
        </p>
        <p>
          {" "}
          <ul>
            {" "}
            <li>Filter seed fit results by brand</li>
            <li>
              Change seed prices used by the tool for profit analysis
            </li>
            <li>
              Run simulations for different crop type / year
              combinations than what was planted, also known as
              Predictive Seed Fit
            </li>
          </ul>
        </p>

        <p>
          {" "}
          Watch the video to learn how to use these new functions.
        </p>
      </div>
    ),
    video: {
      src:
        "https://ddwzudgjmurcz.cloudfront.net/amanda-seedfit/video.mp4",
      poster:
        "https://ddwzudgjmurcz.cloudfront.net/amanda-seedfit/poster.png"
    },
    slug: "09-23-20-seed-fit"
  },
  {
    date: "7/15/20",
    title: "New Features June 2020",
    body: (
      <div>
        <p>
          Did you know you can now send a reminder email to your
          invited friends?
        </p>
        <p>
          {" "}
          Visit your referrals page and use the paper airplane icon to
          send one.
        </p>
      </div>
    )
  }
];

const InfoCenter = (props: Props) => {
  const navigate = useNavigate();

  const [sticky, setSticky] = useState(0);

  return (
    <div className="InfoCenter">
      <div className="HeaderHome">
        <div className="HeaderText">
          <Logo className="Logo" />
          <span></span>
        </div>
      </div>

      <div className="ContentNav">
        <Sticky
          enabled={true}
          top={0}
          bottomBoundary={7000}
          innerZ={1000}
          onStateChange={(status) => setSticky(status.status)}
        >
          <div className="Navigation">
            {sticky === Sticky.STATUS_FIXED && (
              <a href="/">
                <Logo className="InlineLogo" />
              </a>
            )}

            <a className="active" href="/blog">
              <span> Blog </span>
            </a>
            <a href="/blog">
              <span> Help Center </span>
            </a>
            <a
              href="/app/dashboard"
              onClick={() => navigate("/app/dashboard")}
            >
              <span> My Account</span>
            </a>
          </div>
        </Sticky>
      </div>

      {Posts.map((post) => (
        <Post
          date={post.date}
          slug={post.slug}
          title={post.title}
          body={post.body}
          video={post.video}
        />
      ))}
    </div>
  );
};

InfoCenter.defaultProps = {};
export default InfoCenter;
