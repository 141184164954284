import { appReducer } from "./app.js";
import { combineReducers } from "redux";
import { fieldsReducer } from "./fields.js";
import { toastReducer } from "./toast.js";
import { inboxReducer } from "./inbox.js";
import { userReducer } from "./user.js";
import { farmsReducer } from "./farms.js";
import { sessionReducer } from "./session.js";
import { typeaheadReducer } from "./typeahead.js";
import { filesReducer } from "./files.js";
import { seedsReducer } from "./seeds.js";
import { adminReducer } from "./admin.js";
import { pricesReducer } from "./prices.js";
import { practicesReducer } from "./practices.js";

import * as ACTION from "../constants";

const reducers = combineReducers({
  app: appReducer,
  fields: fieldsReducer,
  toast: toastReducer,
  inbox: inboxReducer,
  farms: farmsReducer,
  user: userReducer,
  session: sessionReducer,
  typeahead: typeaheadReducer,
  files: filesReducer,
  seeds: seedsReducer,
  admin: adminReducer,
  prices: pricesReducer,
  practices: practicesReducer
});

export const rootReducer = (state, action) => {
  if (action.type === ACTION.LOG_OUT) {
    state = undefined;
  }

  return reducers(state, action);
};
