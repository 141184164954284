import * as ACTION from "../constants";

import { buildJsonRequest, getErrorMessage } from "../utils";

import {
  getFields,
  showToast,
  dispatchAtDelay,
  getBoundaryGeojson
} from "./";

export const getFarms = () => {
  return buildJsonRequest(
    {
      url: "/data/farms",
      method: "GET"
    },
    ACTION.GET_FARMS,
    [() => dispatchAtDelay(ACTION.GET_FARMS_CLEAR, 5000)]
  );
};

export const addFarm = (FARM_NAME, COLOR) => {
  return buildJsonRequest(
    {
      url: "/data/farms",
      method: "POST",
      data: {
        name: FARM_NAME,
        color: COLOR
      }
    },
    ACTION.ADD_FARM,
    [getFarms],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const updateFarm = (FARM_ID, FARM_NAME, COLOR) => {
  return buildJsonRequest(
    {
      url: "/data/farms",
      method: "PUT",
      data: {
        id: FARM_ID,
        name: FARM_NAME,
        color: COLOR
      }
    },
    ACTION.UPDATE_FARM,
    [getFarms, () => dispatchAtDelay(ACTION.UPDATE_FARM_CLEAR, 5000)],
    [
      (error) => showToast(getErrorMessage(error)),
      () => dispatchAtDelay(ACTION.UPDATE_FARM_CLEAR, 5000)
    ]
  );
};

export const assignFieldToFarm = (FIELD_ID, FARM_ID) => {
  return buildJsonRequest(
    {
      url: "/data/fields",
      method: "PUT",
      data: {
        field_id: FIELD_ID,
        farm_id: FARM_ID
      }
    },
    ACTION.ASSIGN_FIELD_TO_FARM,
    [getFields, () => getBoundaryGeojson(FIELD_ID)],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const deleteFarm = (FARM_ID) => {
  return buildJsonRequest(
    {
      url: "/data/farms",
      method: "DELETE"
    },
    ACTION.GET_FARMS
  );
};
