import { connect } from "react-redux";

import {
  getFields,
  getFarms,
  getInbox,
  getFileProcessingStatus,
  getUser,
  getReferrals,
  checkLoginStatus
} from "../redux/actions/";

import DataLoader from "./index";

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.session.isAuthenticated,
    fileUploadInProgress:
      state.fields.components.upload_files.loading,
    fields: state.fields,
    farms: state.farms
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkLoginStatus: () => {
      dispatch(checkLoginStatus());
    },
    loadPageContent: (location, props) => {
      switch (location.pathname) {
        case "/app/account":
          dispatch(getUser());
          dispatch(getReferrals());
          break;
        case "/app/inbox":
          dispatch(getInbox());
          break;
        case "/app/simulations":
        case "/app/prescriptions":
        case "/app/reports":
        case "/app/farms":
        // eslint-disable-next-line
        case "/app/dashboard":
          const { summary } = props.fields;
          const { loading } = props.fields.components.get_fields;

          if (summary.length === 0 && !loading) {
            dispatch(getFields());
          }
          dispatch(getFarms());
          dispatch(getFileProcessingStatus());

          break;
        default:
          break;
      }
    }
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataLoader);

export default Container;
