// @flow

import React from "react";

const NotFound = (props) => {
  return <div>{/* Not Found */}</div>;
};

NotFound.defaultProps = {};
export default NotFound;
