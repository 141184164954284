// @flow

import React from "react";

import "./styles.scss";

import { Input, AlertBar, Loading, Notice } from "../../components/";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthenticationPage } from ".";

import {
  validateEmail,
  validatePassword,
  validatePhone
} from "../../utils";

//type Props = {};

class Login extends React.Component {
  state = {
    mode: "login",
    email: "",
    password: "",
    code: "",
    phone: "",
    role: "",
    name: "",
    terms: false,
    validationError: undefined
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    const {
      mode,
      email,
      password,
      code,
      phone,
      role,
      terms
    } = this.state;
    var validationMessage = "";

    switch (mode) {
      case "login":
        if (!(email && password)) {
          validationMessage =
            validationMessage +
            "\u2022 Email and Password are required.\n";
        }
        break;
      case "register":
        if (!(email && password && code && phone)) {
          validationMessage =
            validationMessage +
            "\u2022 Email, Password, Phone, and Invite Code are required.\n";
        }
        var validateEmailResult = validateEmail(email);
        if (!validateEmailResult.status) {
          validationMessage =
            validationMessage + validateEmailResult.message;
        }

        var validatePasswordResult = validatePassword(password);
        if (!validatePasswordResult.status) {
          validationMessage =
            validationMessage + validatePasswordResult.message;
        }

        var validatePhoneResult = validatePhone(phone);
        if (!validatePhoneResult.status) {
          validationMessage =
            validationMessage + validatePhoneResult.message;
        }

        if (!terms) {
          validationMessage =
            validationMessage +
            "\u2022 Please accept terms and conditions and privacy policy to continue.\n";
        }
        break;
      case "next":
        if (!role) {
          validationMessage =
            validationMessage + "\u2022 Please select a user type.\n";
        }
        break;
      default:
        return false;
    }
    this.setState({
      validationError: validationMessage
    });
    return validationMessage === "";
  };

  doSubmit = (evt) => {
    evt.preventDefault();
    const {
      mode,
      name,
      email,
      password,
      code,
      phone,
      role
    } = this.state;
    const { login, registerUser, clearUserError } = this.props;

    if (mode === "register") {
      clearUserError();
      if (this.validate()) this.setState({ mode: "next" });
      else console.log("validation error");
    } else if (mode === "next") {
      if (this.validate())
        registerUser(name, email, password, code, phone, role);
      else console.log("validation error");
    } else {
      if (this.validate()) login(email, password);
      else console.log("validation error");
    }
  };

  render() {
    const { error, loading } = this.props.user.components.user;
    const { isRegistered } = this.props.user;

    const { clearUserError } = this.props;

    const {
      mode,
      email,
      password,
      code,
      phone,
      terms,
      role,
      name,
      validationError
    } = this.state;

    if (isRegistered && mode === "next") {
      //if Registration completed successfully, show login screen
      this.setState({
        mode: "login",
        email: "",
        password: "",
        code: "",
        phone: "",
        role: "",
        name: "",
        terms: false,
        validationError:
          "Registration successful.  Please login with your new credentials."
      });
    } else if (error && mode === "next") {
      //if Registration failed, go back to the previous screen to edit
      this.setState({
        mode: "register"
      });
    }

    return (
      <AuthenticationPage>
        <div className="LoginHeader">
          <span
            className={mode === "login" ? "active" : "inactive"}
            onClick={() => {
              this.setState({ mode: "login", validationError: "" });
              clearUserError();
            }}
          >
            Login
          </span>
          <span
            className={
              mode === "register" || mode === "next"
                ? "active"
                : "inactive"
            }
            onClick={() => {
              this.setState({
                mode: "register",
                validationError: ""
              });
              clearUserError();
            }}
          >
            Register
          </span>
        </div>

        <div className="FormContainer">
          {validationError || error ? (
            <AlertBar>
              {validationError ? (
                <div>
                  {" "}
                  {validationError.split("\n").map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
              {error}
            </AlertBar>
          ) : null}

          {loading ? <Loading /> : null}

          <form className="LoginForm" onSubmit={this.doSubmit}>
            {mode === "register" ? (
              <label>
                <span>Name:</span>
                <Input
                  name="name"
                  type="text"
                  value={name}
                  onChange={this.onChange}
                />
              </label>
            ) : null}
            {mode === "login" || mode === "register" ? (
              <label>
                <span>Email:</span>
                <Input
                  name="email"
                  type="text"
                  value={email}
                  onChange={this.onChange}
                  mask={/^\S*@?\S*$/}
                />
              </label>
            ) : null}
            {mode === "login" || mode === "register" ? (
              <label>
                <span>Password:</span>
                <Input
                  className="PasswordInput"
                  name="password"
                  type="password"
                  value={password}
                  onChange={this.onChange}
                />
              </label>
            ) : null}
            {mode === "register" ? (
              <React.Fragment>
                <label>
                  <span>Phone:</span>
                  <Input
                    name="phone"
                    type="text"
                    mask="+1 (000) 000-0000"
                    value={phone}
                    onChange={this.onChange}
                  />
                </label>
                <label>
                  <span>Invite Code:</span>
                  <Input
                    name="code"
                    type="text"
                    mask={/^\d+$/}
                    value={code}
                    onChange={this.onChange}
                  />
                </label>
                <label>
                  <span>
                    I agree to the{" "}
                    <a target="_blank" href="/ndaagreement.html">
                      Confidentiality Agreement
                    </a>
                    ,{" "}
                    <a
                      target="_blank"
                      href="/termsandconditions.html"
                    >
                      Terms and Conditions
                    </a>
                    , and
                    <a target="_blank" href="/privacypolicy.html">
                      Privacy Policy.
                    </a>
                  </span>
                  <input
                    name="terms"
                    type="checkbox"
                    checked={terms}
                    onChange={(event) =>
                      this.setState({ terms: event.target.checked })
                    }
                  />
                </label>
              </React.Fragment>
            ) : null}
            {mode === "next" ? (
              <React.Fragment>
                <span>Select your account type:</span>

                <label className="RegisterRadioOption">
                  <input
                    type="radio"
                    name="farmer"
                    value={role === "farmer"}
                    checked={role === "farmer"}
                    onChange={() => this.setState({ role: "farmer" })}
                  />
                  <span>Farmer</span>
                </label>
                <span className="Detail">
                  This account type lets MFI handle the details of
                  generating prescriptions, reports, and simulations.
                </span>

                <label className="RegisterRadioOption">
                  <input
                    type="radio"
                    name="tech_farmer"
                    value={role === "tech_farmer"}
                    checked={role === "tech_farmer"}
                    onChange={() =>
                      this.setState({ role: "tech_farmer" })
                    }
                  />
                  <span>Technical Farmer</span>
                </label>
                <span className="Detail">
                  This account type provides advanced options for
                  managing the generation of prescriptions, reports,
                  and simulations.
                </span>

                <Notice>
                  <FontAwesomeIcon icon="question-circle" size="1x" />
                  <span>
                    Don't worry, you can always change this option
                    later in your profile.
                  </span>
                </Notice>
              </React.Fragment>
            ) : null}
            <input
              type="submit"
              value={
                mode === "register"
                  ? "Next"
                  : mode === "next"
                  ? "Register"
                  : "Login"
              }
            />
            <Link to="/app/login/request">Forgot Password?</Link>
          </form>
        </div>
      </AuthenticationPage>
    );
  }
}

Login.defaultProps = {};
export default Login;
