// @flow

import React from "react";

import { Removals, Yield, ProfitDemo } from "./";

type Props = {};

const Summary = (props: Props) => {
  const { activeFieldId } = props.app;

  const { layer } = props;

  return (
    <React.Fragment>
      {activeFieldId && layer ? (
        <div>
          <React.Fragment>
            <div className="ReportInfo">
              <span>Product:</span>
              <span>{layer.data.product}</span>
            </div>
            <div className="ReportInfo">
              <span>Price:</span>
              <span>
                {`$${
                  layer.data && layer.data.product_price
                    ? layer.data.product_price.toFixed(2)
                    : ""
                } ${layer.data.product_price_units}`}
              </span>
            </div>
            <div className="ReportInfo">
              <span>Total Acres:</span>
              <span>{layer.data.total_acres}</span>
            </div>
            <div className="ReportInfo">
              <span>Total Yield (Bu):</span>
              <span>{layer.data.total_yield}</span>
            </div>
          </React.Fragment>

          {layer.name === "yield" || layer.name === "removals" ? (
            <React.Fragment>
              <Yield {...props} />
            </React.Fragment>
          ) : null}

          {layer.name === "yield" ? <ProfitDemo {...props} /> : null}

          {layer.name === "removals" ? <Removals {...props} /> : null}
        </div>
      ) : null}
    </React.Fragment>
  );
};

Summary.defaultProps = {};
export default Summary;
