// @flow

import React from "react";

import { Input, AlertBar, Header } from "../../../components";

import { validatePhone } from "../../../utils";

type Props = {};

class AccountSettings extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      phone: props.user.phone,
      role: props.user.role,
      name: props.user.name,
      validationError: undefined
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    const { name, phone, role } = this.state;
    var validationMessage = "";

    if (!phone || !name) {
      validationMessage =
        validationMessage + "\u2022 Name and Phone are required.\n";
    }

    var validatePhoneResult = validatePhone(phone);
    if (!validatePhoneResult.status) {
      validationMessage =
        validationMessage + validatePhoneResult.message;
    }

    if (!role) {
      validationMessage =
        validationMessage + "\u2022 Please select an Account Type.\n";
    }

    this.setState({
      validationError: validationMessage
    });
    return validationMessage === "";
  };

  render() {
    const {
      error,
      loading,
      success
    } = this.props.user.components.update_account_settings;
    const { updateAccountSettings, clearUserError } = this.props;

    const { phone, role, name, validationError } = this.state;

    return (
      <div className="RightPanelContent">
        <Header
          title="Edit My Account"
          loading={loading}
          success={success}
          error={error}
        />
        {validationError || error ? (
          <AlertBar>
            {validationError ? (
              <div>
                {validationError.split("\n").map((item) => {
                  return <div>{item}</div>;
                })}
              </div>
            ) : null}
            {error}
          </AlertBar>
        ) : null}

        <form className="ProfileForm">
          <label>
            <span>Name:</span>
            <Input
              name="name"
              type="text"
              value={name}
              onChange={this.onChange}
            />
          </label>

          <label>
            <span>Phone:</span>
            <Input
              name="phone"
              type="text"
              mask="+1 (000) 000-0000"
              value={phone}
              onChange={this.onChange}
            />
          </label>

          {/* <span>Select your account type:</span>

          <label className="RegisterRadioOption">
            <input
              type="radio"
              name="farmer"
              value={role === "farmer"}
              checked={role === "farmer"}
              onChange={() => this.setState({ role: "farmer" })}
            />
            <span>Farmer</span>
          </label>

          <label className="RegisterRadioOption">
            <input
              type="radio"
              name="tech_farmer"
              value={role === "tech_farmer"}
              checked={role === "tech_farmer"}
              onChange={() => this.setState({ role: "tech_farmer" })}
            />
            <span>Technical Farmer</span>
          </label> */}

          <input
            type="button"
            value="Update Account"
            onClick={() => {
              clearUserError();
              this.validate()
                ? updateAccountSettings({
                    name: name,
                    phone: phone,
                    role: role
                  })
                : console.log("validation error");
            }}
          />
        </form>
      </div>
    );
  }
}

AccountSettings.defaultProps = {};
export default AccountSettings;
