import React from "react";

import { Toolbar, TabBar } from "..";

import "./styles.scss";
import { isMobile } from "react-device-detect";

const PageWithMenu = (props) => {
  const { title } = props;

  return (
    <div
      className={`PageWithMenu ${isMobile ? "mobile" : "browser"}`}
    >
      <Toolbar activePage={title} />

      {isMobile ? (
        <TabBar tabList={["Menu", "Data"]}>{props.children}</TabBar>
      ) : (
        <React.Fragment>
          {props.children[0]}
          <div className="PageContentWrapper">
            <div className="PageContent">{props.children[1]}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PageWithMenu;
