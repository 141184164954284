// @flow

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { isMobile } from "react-device-detect";
import { VideoPlayer } from "../../components";

const VideoModal = (props: Props) => {
  const { src, img, title, watchVideo } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center"
      }}
    >
      {!isMobile ? (
        <div
          style={{
            width: "160px",
            height: "100px",
            backgroundColor: "white",
            alignItems: "center",
            textAlign: "center",
            verticalAlign: "middle",
            background: `url(${img})  0% 0% / 160px 100px`,
            borderRadius: "10px"
          }}
          className="VideoIconContainer"
        >
          {/* 
                <img className="image" src={img} alt={title} style={{
                    width: "100px",
                    height: "100px",
                    backgroundColor: "white"
                }} onClick={() => watchVideo(src)} /> */}

          <FontAwesomeIcon
            style={{
              margin: "35px",
              alignItems: "center",
              textAlign: "center",
              verticalAlign: "middle",
              zIndex: "2"
            }}
            className="Icon VideoIconOverlay"
            icon="play"
            size="2x"
            onClick={() => watchVideo(src)}
          />
        </div>
      ) : (
        <VideoPlayer
          width={160}
          height={100}
          src={src}
          poster={img}
          controls={true}
        />
      )}
      <span style={{ width: "100%", fontWeight: "bold" }}>
        {" "}
        {title}{" "}
      </span>
    </div>
  );
};

export default VideoModal;
