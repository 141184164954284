import React, { useState } from "react";

import "./styles.scss";

const TabBar = (props) => {
  const { tabList } = props;
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="TabBar">
      <div className="TabBarWrapper">
        <div
          className="Tabs"
          style={{
            gridTemplateColumns: ` repeat(${tabList.length}, 1fr)`
          }}
        >
          {tabList &&
            tabList.map((tab, index) => {
              return (
                <div
                  key={"tab" + index}
                  className={activeTab === index ? "ActiveTab" : ""}
                  onClick={() => setActiveTab(index)}
                >
                  {tab}
                </div>
              );
            })}
        </div>
        <div className="TabBarContent">
          {props.children[activeTab]}
        </div>
      </div>
    </div>
  );
};

export default TabBar;
