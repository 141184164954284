// @flow

import React, { useEffect } from "react";
import "./styles.scss";

import { useNavigate, useParams } from "react-router";

import { AlertBar, Loading } from "../../components/";
import { AuthenticationPage } from ".";

//type Props = {};

const ConfirmAccount = (props) => {
  const { confirmAccount } = props;

  const { token } = useParams();

  useEffect(() => {
    confirmAccount(token);
  }, [confirmAccount, token]);

  const {
    error,
    success,
    loading
  } = props.user.components.confirm_account;

  const navigate = useNavigate();

  return (
    <AuthenticationPage>
      <div className="LoginHeader">
        <span className="active">Confirm Account</span>
      </div>

      <div className="FormContainer">
        {error && <AlertBar>{error}</AlertBar>}
        {success && (
          <AlertBar type="success">
            Account confirmed successfully!
          </AlertBar>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {loading ? (
            <React.Fragment>
              <Loading />
              <div> Verifying your account...</div>
            </React.Fragment>
          ) : null}
        </div>
        {success && (
          <form className="LoginForm">
            <input
              type="button"
              value="Go to Login"
              onClick={() => {
                navigate("/app/login");
              }}
            />
          </form>
        )}
      </div>
    </AuthenticationPage>
  );
};

ConfirmAccount.defaultProps = {};
export default ConfirmAccount;
