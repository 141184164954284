// @flow

import React from "react";
import { /*Router,*/ useLocation, useNavigate } from "react-router";

import "./styles.scss";

import { Toolbox, PageWithMenu } from "../../components";
import {
  AccountSettings,
  LoginInfo,
  MyReferrals,
  GetHelp
} from "./components";

type Props = {};

const Profile = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { logout } = props;

  var Form;
  switch (location.pathname) {
    case "/app/account/invite":
      Form = MyReferrals;
      break;
    case "/app/account/login":
      Form = LoginInfo;
      break;
    case "/app/account/settings":
      Form = AccountSettings;
      break;
    case "/app/account/contact":
      Form = GetHelp;
      break;
    default:
      Form = MyReferrals;
      break;
  }

  return (
    <PageWithMenu title="account">
      <Toolbox title="account">
        <div>I want to...</div>

        <div
          className={`AccountTool ${
            location.pathname.includes("invite") ? "active" : ""
          }`}
          onClick={() => navigate("/app/account/invite")}
        >
          Invite A Friend
        </div>

        <div
          className={`AccountTool ${
            location.pathname.includes("settings") ? "active" : ""
          }`}
          onClick={() => navigate("/app/account/settings")}
        >
          Edit My Account
        </div>

        <div
          className={`AccountTool ${
            location.pathname.includes("login") ? "active" : ""
          }`}
          onClick={() => navigate("/app/account/login")}
        >
          Change My Email Or Password
        </div>

        <div
          className={`AccountTool ${
            location.pathname.includes("contact") ? "active" : ""
          }`}
          onClick={() => navigate("/app/account/contact")}
        >
          Get Help or Report a Problem
        </div>

        <div className="AccountTool" onClick={() => logout()}>
          Log Out
        </div>
      </Toolbox>

      <div className="AccountWrapper">
        {/*TODO: Fix router */}
        {/* <Router>
          <MyReferrals path="/invite" {...props} />
          <LoginInfo path="/login" {...props} />
          <AccountSettings path="/settings" {...props} />
          <DataManagement path="/seeds" {...props} />
          <FileStatus path="/files" {...props} />
        </Router> */}
        <Form {...props} showDropdownOption={true} />
      </div>
    </PageWithMenu>
  );
};

Profile.defaultProps = {};
export default Profile;
