// @flow

import React from "react";

//import "rc-slider/assets/index.css";
//import Slider from "rc-slider";
import Slider from "react-rangeslider";

import "react-rangeslider/lib/index.css";

class OpacitySlider extends React.Component {
  render() {
    const { min, max, value, label, onChange } = this.props;

    return (
      <span className="Slider">
        <span>{label}</span>
        <span>{value}%</span>

        <Slider
          min={min}
          max={max}
          value={value}
          onChange={(value) => {
            this.setState({ value: value });
            onChange(value);
          }}
          labels={{ 0: "0", 100: "100%" }}
        />
      </span>
    );

    //
  }
}

OpacitySlider.defaultProps = {
  min: 0,
  max: 100,
  value: 100,
  label: ""
};
export default OpacitySlider;
