// @flow
import React from "react";
import ReactDOMServer from "react-dom/server";

import centroid from "@turf/centroid";

import { getColorMapping } from "../../../utils";

import { GeoJSON } from "react-leaflet";
import L from "leaflet";

const GeoJSONLayer = (props) => {
  const {
    // activeFieldCoords,
    activeReport,
    data,
    id,
    opacity,
    onClick,
    boundariesToMerge
  } = props;

  return (
    <React.Fragment>
      <GeoJSON
        key={id}
        bubblingMouseEvents={false}
        pointToLayer={(feature, latlng) => {
          return L.circleMarker(latlng, {
            radius: 10
          });
        }}
        style={(feature) => {
          if (boundariesToMerge) {
            //TODO style selected boundaries
          }

          let color = feature.properties.yield_env
            ? getColorMapping(activeReport, data.features.length)[
                feature.properties.yield_env
              ]
            : feature.properties.color
            ? feature.properties.color
            : "#cccccc";

          return {
            className: id,
            color: color,
            fillColor: color,
            opacity: 1,
            fillOpacity: 1
          };
        }}
        onEachFeature={(feature, layer) => {
          if (feature.properties.field_name) {
            layer.bindPopup(
              ReactDOMServer.renderToString(
                <div className="FieldPopup">
                  <span> Field:</span>
                  <span>{feature.properties.field_name}</span>
                  {/* <span> Product: </span>
                  <span>{feature.properties.product}</span> */}
                  <span> Farm:</span>
                  <span>
                    {feature.properties.farm_name
                      ? feature.properties.farm_name
                      : "unassigned"}
                  </span>
                  <span>{id}</span>
                </div>
              ),

              {
                maxWidth: "500",
                className: "custom"
              }
            );
          }

          layer.on("click", (evt) => {
            var layer = evt.target;

            var centroidVal = centroid(layer.feature.geometry)
              .geometry.coordinates;

            onClick(
              [centroidVal[1], centroidVal[0]],
              layer.feature.properties
            );
          });
          layer.on("mouseover", function () {
            layer.openPopup();
          });
          layer.on("mouseout", function () {
            layer.closePopup();
          });
        }}
        data={data}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
      path.${id}.leaflet-interactive { opacity: ${opacity / 100} }
    `
        }}
      />
    </React.Fragment>
  );
};

export default GeoJSONLayer;
