// @flow

import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import { HeatmapLegend } from "../../../../../components";
import { faBorderNone } from "@fortawesome/free-solid-svg-icons";

import { Multiselect } from "multiselect-react-dropdown";

const sortByProfit = (a, b) => {
  let comparison = 0;
  if (a.profit_per_acre > b.profit_per_acre) {
    comparison = 1;
  } else if (a.profit_per_acre < b.profit_per_acre) {
    comparison = -1;
  }
  return comparison * -1;
};

const sortByYield = (a, b) => {
  let comparison = 0;
  if (a.yield_per_acre > b.yield_per_acre) {
    comparison = 1;
  } else if (a.yield_per_acre < b.yield_per_acre) {
    comparison = -1;
  }
  return comparison * -1;
};

const sortBySoilTypePercent = (a, b) => {
  let comparison = 0;
  if (a.percent_of_field > b.percent_of_field) {
    comparison = 1;
  } else if (a.percent_of_field < b.percent_of_field) {
    comparison = -1;
  }
  return comparison * -1;
};

type Props = {};

const FitTable = (props: Props) => {
  const { fit } = props;

  const [rowExpandedState, setRowExpandedState] = useState({});
  const [sortState, setSortState] = useState("profit");

  const { compare, setCompare, compareMode } = props;

  let sortedFit = fit
    ? fit.sort(sortState === "profit" ? sortByProfit : sortByYield)
    : faBorderNone;
  // if compareMode = true => title bar is green, div is not scrollable, and checkbox is disabled
  // if compareMode = false => title bar is yellow, div is scrollable, and checkbox is enabled

  return (
    <React.Fragment>
      <ReactTooltip
        id="FitTableTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />

      <HeatmapLegend
        style={{ display: "grid", gridTemplateColumns: "1fr" }}
      >
        <div
          className={`HeatmapLegendTitle HybridSummaryTitle ${
            compareMode ? "CompareTitle" : ""
          }`}
          style={{
            display: "grid",
            gridTemplateColumns:
              "2em 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2em"
          }}
        >
          <span />
          <span>Seed Company</span>
          <span>Seed Name</span>
          <span>Soil Description</span>
          <span>Percent of Field</span>
          <span>Avg. Optimal Planting Density*</span>
          <span
            className="Link"
            data-tip="Rank by Yield"
            data-for="FitTableTooltip"
            onClick={() => setSortState("yield")}
          >
            Avg. Yield/Acre
          </span>
          <span>Seed Cost / Bag**</span>
          <span
            className="Link"
            data-tip="Rank by Profit"
            data-for="FitTableTooltip"
            onClick={() => setSortState("profit")}
          >
            Profit/Acre
          </span>
          <span># Data Points</span>
          <span>{compareMode ? "Rank" : "Com-pare"}</span>
        </div>
      </HeatmapLegend>

      <div
        className={`HeatmapLegend ${
          !compareMode ? "HybridSummaryWrapper" : ""
        }`}
      >
        {sortedFit &&
          sortedFit.map((item, index) => (
            <React.Fragment key={index + "_" + item.hybrid}>
              <div
                className="HybridSummary"
                style={{
                  display: "grid",
                  gridTemplateColumns:
                    "2em 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2em"
                }}
              >
                <span
                  onClick={() =>
                    setRowExpandedState({
                      ...rowExpandedState,
                      [index]: !rowExpandedState[index]
                    })
                  }
                >
                  {item.detail && (
                    <FontAwesomeIcon
                      icon={
                        rowExpandedState[index]
                          ? "chevron-up"
                          : "chevron-down"
                      }
                      size="1x"
                      data-tip={
                        rowExpandedState[index]
                          ? "Expand Detail"
                          : "Collapse Detail"
                      }
                      data-for="FitTableTooltip"
                    />
                  )}
                </span>
                <span
                  onClick={() =>
                    setRowExpandedState({
                      ...rowExpandedState,
                      [index]: !rowExpandedState[index]
                    })
                  }
                >
                  {item.seed_company_name}
                </span>
                <span
                  onClick={() =>
                    setRowExpandedState({
                      ...rowExpandedState,
                      [index]: !rowExpandedState[index]
                    })
                  }
                >
                  {item.hybrid}
                </span>
                <span>{item.soil_desc}</span>
                <span />
                <span>
                  {item.optimal_planting_density &&
                    item.optimal_planting_density
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </span>
                <span>
                  {item.yield_per_acre &&
                    item.yield_per_acre
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </span>

                <span>
                  $
                  {item.price_per_bag
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </span>

                <span>
                  $
                  {item.profit_per_acre
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </span>
                <span>{item.num_data_points}</span>
                <span>
                  {compareMode ? (
                    item.rank
                  ) : (
                    <input
                      name={item.hybrid}
                      type="checkbox"
                      data-tip="Select for comparison"
                      data-for="FitTableTooltip"
                      checked={
                        compare && compare[item.hybrid] !== undefined
                      }
                      onChange={(event) => {
                        if (event.target.checked) {
                          setCompare({
                            ...compare,
                            [item.hybrid]: {
                              ...item,
                              rank: index + 1
                            }
                          });
                        } else {
                          var compareDelete = { ...compare };
                          delete compareDelete[item.hybrid];
                          setCompare(compareDelete);
                        }
                      }}
                    />
                  )}
                </span>
              </div>
              {rowExpandedState[index] &&
                item.detail &&
                item.detail
                  .sort(sortBySoilTypePercent)
                  .map((detail_item, index) => (
                    <div
                      key={item.hybrid + index}
                      className="YieldEnv"
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "2em 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2em"
                      }}
                    >
                      <span />
                      <span />
                      <span>{/*item.hybrid*/}</span>
                      <span>{detail_item.soil_desc}</span>
                      <span>
                        {detail_item.percent_of_field &&
                          detail_item.percent_of_field
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        %
                      </span>
                      <span>
                        {detail_item.optimal_planting_density &&
                          detail_item.optimal_planting_density
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </span>
                      <span>
                        {detail_item.yield_per_acre &&
                          detail_item.yield_per_acre
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </span>
                      {/* <span>{item.est_reliability_of_fit}</span> */}
                      <span />
                      {/* 
                <span>
                  $
                  {item.seed_cost_per_bushel_produced
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </span> */}
                      <span>
                        $
                        {detail_item.profit_per_acre
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </span>
                      <span />
                      <span />
                    </div>
                  ))}
            </React.Fragment>
          ))}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

const Fit = (props: Props) => {
  const { activeFieldId } = props.app;
  const { layer } = props;

  let reportData = layer.data;

  const [compareSingleRate, setCompareSingleRate] = useState({});
  const [compareVariableRate, setCompareVariableRate] = useState({});

  const [selectedBrands, setSelectedBrands] = useState([]);

  var brandOptions = [];
  if (reportData && reportData.single_rate_fit) {
    reportData.single_rate_fit.forEach((fit) => {
      if (
        fit.seed_company_name !== null &&
        !brandOptions.some(
          (brand) => brand.name === fit.seed_company_name
        )
      ) {
        brandOptions.push({ name: fit.seed_company_name });
      }
    });
  }

  //TODO: Force component to rerender to fix issue
  return (
    <React.Fragment>
      <ReactTooltip
        id="FitTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />
      {activeFieldId && reportData && (
        <div>
          <div className="ReportInfo">
            <span>Crop Type: </span>{" "}
            <span> {reportData.crop_type} </span>
          </div>

          <div className="ReportInfo">
            <span>Show Brands: </span>{" "}
            <span>
              <Multiselect
                selectionLimit="3"
                style={{
                  multiselectContainer: {
                    // To change css for multiselect (Width,height,etc..)
                  },
                  searchBox: {
                    // To change search box element look
                    border: "none",
                    color: "#f5d870",
                    minHeight: "2.4em"
                  },
                  inputField: {
                    // To change input field position or margin
                    margin: "5px",
                    border: "1px solid #f5d870",
                    borderRadius: "8px",
                    height: "2.4em"
                  },
                  chips: {
                    // To change css chips(Selected options)
                    backgroundColor: "forest-green",
                    color: "#f5d870",
                    height: "1em"
                  },
                  optionContainer: {
                    // To change css for option container
                    border: "1px solid #f5d870",
                    backgroundColor: "#3e342e"
                  },
                  option: {
                    // To change css for dropdown options
                  },
                  groupHeading: {
                    // To chanage group heading style
                  }
                }}
                options={brandOptions} // Options to display in the dropdown
                selectedValues={selectedBrands} // Preselected value to persist in dropdown
                onSelect={(evt) => setSelectedBrands(evt)} // Function will trigger on select event
                onRemove={(evt) => setSelectedBrands(evt)} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />{" "}
            </span>
          </div>

          <a href="/app/data/prices">Manage My Seed Prices</a>

          <h3>Single Rate Fit</h3>

          {Object.values(compareSingleRate).length > 0 && (
            <FitTable
              compareMode={true}
              fit={Object.values(compareSingleRate)}
            />
          )}

          <FitTable
            fit={reportData.single_rate_fit.filter(
              (row) =>
                (Array.isArray(selectedBrands) &&
                  selectedBrands.length === 0) ||
                selectedBrands.some(
                  (brand) => brand.name === row.seed_company_name
                )
            )}
            compareMode={false}
            compare={compareSingleRate}
            setCompare={(compare) => setCompareSingleRate(compare)}
          />

          <h3>Variable Rate Fit</h3>

          {Object.values(compareVariableRate).length > 0 && (
            <FitTable
              compareMode={true}
              fit={Object.values(compareVariableRate)}
            />
          )}

          <FitTable
            fit={reportData.variable_rate_fit.filter(
              (row) =>
                (Array.isArray(selectedBrands) &&
                  selectedBrands.length === 0) ||
                selectedBrands.some(
                  (brand) => brand.name === row.seed_company_name
                )
            )}
            compareMode={false}
            compare={compareVariableRate}
            setCompare={(compare) => setCompareVariableRate(compare)}
          />

          <span style={{ fontStyle: "italic" }}>
            *Avg. Optimal Planting Density given in thousands of seeds
            / Acre
          </span>
          <br />
          <span style={{ fontStyle: "italic" }}>
            **Seed Cost / Bag calculated based 80,000 seeds/bag for
            Corn and 140,000 seeds/bag for Soybeans
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

Fit.defaultProps = {};
export default Fit;
