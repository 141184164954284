// @flow

import React, { useState, useEffect } from "react";

import {
  Input,
  AlertBar,
  SliderModal,
  Header
} from "../../../components/";

type Props = {};

const ManageField = (props: Props) => {
  const [fieldId, setFieldId] = useState("");

  const [fieldName, setFieldName] = useState("");
  const [lockBoundary, setLockBoundary] = useState("");

  const [isFieldFormOpen, setIsFieldFormOpen] = useState(false);
  const [isBoundaryFormOpen, setIsBoundaryFormOpen] = useState(false);

  const [validationError, setValidationError] = useState(undefined);

  let update_field = props.fields.components.update_field;

  let update_boundary = props.fields.components.update_boundary;

  const {
    updateField,
    updateBoundary,
    getNearbyFields,
    boundaryToMerge
  } = props;
  const {
    activeFieldId,
    activeFieldName,
    activeFieldBoundaryLocked
  } = props.app;

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (activeFieldId) {
      //TODO: Get Seed Prices
      getNearbyFields(activeFieldId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFieldId, getNearbyFields]);

  return (
    <div>
      <form className="ProfileForm">
        <Header
          title="Fields"
          loading={update_field.loading || update_boundary.loading}
        />
        {validationError ||
        update_field.error ||
        update_boundary.error ? (
          <AlertBar>
            {validationError ? (
              <div>
                {validationError.split("\n").map((item) => {
                  return <div>{item}</div>;
                })}
              </div>
            ) : null}
            {update_field.error}
            {update_boundary.error}
          </AlertBar>
        ) : null}

        <div className="ReportInfo">
          {activeFieldName ? (
            <React.Fragment>
              <span>Selected Field: </span>{" "}
              <span> {activeFieldName} </span>{" "}
            </React.Fragment>
          ) : (
            <div>Select a field to get started.</div>
          )}
        </div>

        <div className="ReportInfo">
          {boundaryToMerge && (
            <React.Fragment>
              <span>Selected Field To Merge: </span>{" "}
              <span> {boundaryToMerge.field_name} </span>{" "}
            </React.Fragment>
          )}
        </div>

        {activeFieldId && (
          <div
            style={{
              display: "grid",
              gridGap: "1em",
              gridTemplateColumns: "1fr 1fr"
            }}
          >
            <input
              type="button"
              value="Edit Field"
              onClick={() => {
                setFieldId(activeFieldId);
                setFieldName(activeFieldName);
                setLockBoundary(activeFieldBoundaryLocked);
                setIsFieldFormOpen(true);
              }}
            />
            {boundaryToMerge && (
              <input
                type="button"
                value="Merge Boundaries"
                onClick={() =>
                  updateBoundary(
                    activeFieldName,
                    boundaryToMerge.field_name,
                    activeFieldId,
                    boundaryToMerge.boundary_id
                  )
                }
              />
            )}
          </div>
        )}
      </form>

      <SliderModal
        title={"Edit Field"}
        isOpen={isFieldFormOpen}
        onOutsideClick={() => setIsFieldFormOpen(false)}
        footer={
          <div
            style={{
              width: "100%",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            <input
              type="button"
              value="Save"
              onClick={() => {
                setValidationError("");
                var validationMessage = "";

                if (!fieldName) {
                  validationMessage =
                    validationMessage +
                    "\u2022 Field name is required.\n";
                }

                if (validationMessage !== "") {
                  setValidationError(validationMessage);
                } else {
                  updateField(fieldId, fieldName, lockBoundary);
                  setIsFieldFormOpen(false);
                  setFieldName("");
                }
              }}
            />

            <input
              type="button"
              value="Cancel"
              onClick={() => setIsFieldFormOpen(false)}
            />
          </div>
        }
      >
        <form>
          {validationError && (
            <AlertBar>
              {validationError ? (
                <div>
                  {validationError.split("\n").map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
              {/* {error} */}
            </AlertBar>
          )}
          <label>
            <span>Field Name:</span>
            <Input
              name="fieldName"
              type="text"
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
            />
          </label>

          <label>
            <span>Lock Boundary</span>
            <input
              name="lockBoundary"
              type="checkbox"
              checked={lockBoundary}
              onChange={(event) =>
                setLockBoundary(event.target.checked)
              }
            />
          </label>
        </form>
      </SliderModal>
    </div>
  );
};

ManageField.defaultProps = {};
export default ManageField;
