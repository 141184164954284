// @flow

import React from "react";
import Section from "./Section";
import CircleImage from "./CircleImage";

import John from "../img/John.jpg";
import Zeph from "../img/Zeph.jpg";
import Keith from "../img/Keith.jpg";
import William from "../img/William.jpg";
import Sam from "../img/Sam.jpg";
import Chris from "../img/Chris.jpg";
import Alec from "../img/AlecThompson.JPG";
import Pradeep from "../img/Pradeep.JPG";
import Rae from "../img/Rae.jpg";
import Berkh from "../img/Berkh.jpg";
import Doug from "../img/Doug.jpg";
import Tyler from "../img/Tyler.jpg";
import Grant from "../img/grant.jpeg";
import Brian from "../img/brian.jpeg";

// import DefaultImg from "../img/default.png";

const About = (props: Props) => {
  return (
    <Section
      id="about"
      onVisibilityChange={props.onVisibilityChange}
      style={{ minHeight: "105em" }}
    >
      <div className="Section" id="about">
        <h1 className="SectionHeading">About us</h1>

        <div className="PanelWrapper">
          <div id="Team" className="PanelContent">
            <h1>Our Team</h1>

            <div className="Team">
              <CircleImage
                name="Grant Strom"
                position="Farmer and Board Chairman"
                img={Grant}
              />
              <CircleImage
                name="Tyler Johnston"
                position="Farmer and Vice Board Chairman"
                img={Tyler}
              />
              <CircleImage
                name="Zeph Thompson"
                img={Zeph}
                position="Farmer and Board Secretary/Treasurer"
              />
              <CircleImage
                name="John Johnston"
                img={John}
                position="Farmer and Board Member"
              />
              <CircleImage
                name="Brian O'Connor"
                img={Brian}
                position="Farmer and Board Member"
              />
              <CircleImage
                name="Keith Morling"
                img={Keith}
                position="Farmer and Board Member"
              />
              <CircleImage
                name="Doug Elliott"
                position="Farmer, Investor, and Former Board Chairman"
                img={Doug}
              />
              <CircleImage
                name="William Goldsborough"
                img={William}
                position="US Business Leader, Investor, and Former Board Member"
              />
              <CircleImage
                name="Dr. Chris Fasano"
                img={Chris}
                position="Chief Science Officer and Board Member"
              />
              <CircleImage
                name="Sam Stehling"
                img={Sam}
                position="Director of Agronomy"
              />
              <CircleImage
                name="Berkh Tsogtbaatar"
                position="Lead Geospatial Software Engineer"
                img={Berkh}
              />
              <CircleImage
                name="Pradeep Maddineni"
                img={Pradeep}
                position="Director of Engineering"
              />
              <CircleImage
                name="Amanda Rae Rush"
                img={Rae}
                position="Director of Software Products"
              />
            </div>
          </div>

          <div id="AboutUs">
            <h1>Our Mission</h1>
            <p className="BigText">
              As a farmer built and directed company, we strive to
              provide excellent tools for farmers everywhere. Our goal
              is to serve the farming community with a variety of
              products and services developed with privacy and data
              security in mind.
            </p>

            <div id="Team">
              <h1>FFA Support and My Gold Farm</h1>

              <h2>Warren County Cell Support Team</h2>

              <div className="Team">
                <CircleImage
                  name="Alec Thompson"
                  img={Alec}
                  position="FFA Leader and My Gold Farm Owner"
                />
                <CircleImage
                  name="Darin Kennelly"
                  position="My Gold Farm Leader and Owner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default About;
