import * as ACTION from "../constants";

export const sessionReducer = (
  state = {
    isAuthenticated: false,
    token: undefined,
    showBrowserWarning: true,
    showInvestmentBanner: true
  },
  action
) => {
  switch (action.type) {
    case ACTION.LOG_IN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.data.token
      };
    case ACTION.LOG_IN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        token: undefined
      };
    case ACTION.DISMISS_BROWSER_WARNING:
      return {
        ...state,
        showBrowserWarning: false
      };
    case ACTION.DISMISS_INVESTMENT_BANNER:
      return {
        ...state,
        showInvestmentBanner: false
      };
    default:
      return state;
  }
};
