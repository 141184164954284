// @flow

import React from "react";
import "./styles.scss";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Logo } from "../../assets/mfi-smooth.svg";

import { useNavigate } from "react-router";
import { isBrowser } from "react-device-detect";

type Props = {
  activePage: string,
  logout: Function
};

const Toolbar = (props: Props) => {
  const { activePage } = props;
  const navigate = useNavigate();

  return (
    <div className={`Toolbar ${isBrowser ? "browser" : "mobile"}`}>
      <ReactTooltip
        place="right"
        id="ToolbarTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />

      <ul>
        {isBrowser && (
          <li className="Tool" onClick={() => navigate("/")}>
            <Logo className="Logo" />
          </li>
        )}
        <li
          className={`Tool ${
            activePage === "dashboard" ? "active" : ""
          }`}
          onClick={() => navigate("/app/dashboard")}
          data-tip="Dashboard"
          data-for="ToolbarTooltip"
        >
          <FontAwesomeIcon className="Icon" icon="home" size="3x" />
        </li>

        <li
          className={`Tool ${
            activePage === "reports" ? "active" : ""
          }`}
          onClick={() => navigate("/app/reports")}
          data-tip="Reports"
          data-for="ToolbarTooltip"
        >
          <FontAwesomeIcon
            className="Icon"
            icon="chart-line"
            size="3x"
          />
        </li>
        <li
          className={`Tool ${activePage === "farms" ? "active" : ""}`}
          onClick={() => navigate("/app/farms")}
          data-tip="Farm Management"
          data-for="ToolbarTooltip"
        >
          <FontAwesomeIcon className="Icon" icon="cog" size="3x" />
        </li>

        {/* <li
          className={`Tool ${activePage === "inbox" ? "active" : ""}`}
          onClick={() => navigate("/app/inbox")}
          data-tip="Inbox" data-for="ToolbarTooltip"
        >
          <FontAwesomeIcon className="Icon" icon="envelope" size="3x" />
        </li> */}
        <li
          className={`Tool ${
            activePage === "data management" ? "active" : ""
          }`}
          onClick={() => navigate("/app/data")}
          data-tip="Data Management"
          data-for="ToolbarTooltip"
        >
          <FontAwesomeIcon className="Icon" icon="table" size="3x" />
        </li>
        <li
          className={`Tool ${
            activePage === "account" ? "active" : ""
          }`}
          onClick={() => navigate("/app/account")}
          data-tip="Account Settings"
          data-for="ToolbarTooltip"
        >
          <FontAwesomeIcon className="Icon" icon="user" size="3x" />
        </li>
        {/* <li
          className={`Tool `}
          onClick={() => logout()}
          data-tip="Log Out"
          data-for="ToolbarTooltip"
        >
          <FontAwesomeIcon
            className="Icon"
            icon="sign-out-alt"
            size="3x"
          />
        </li> */}
      </ul>
    </div>
  );
};

Toolbar.defaultProps = {
  activePage: "dashboard",
  logout: () => console.log("Please set a logout() handler")
};
export default Toolbar;
