export const hasAppAttribute = (attribute, user) => {
  return (
    user &&
    user.role_entitlements &&
    user.role_entitlements.app_entitlements.attributes.includes(
      attribute
    )
  );
};

export const hasDataAttribute = (attribute, user) => {
  return (
    user &&
    user.role_entitlements &&
    user.role_entitlements.data_entitlements.attributes.includes(
      attribute
    )
  );
};

export const hasRole = (role, user) => {
  return user && user.role && user.role === role;
};
