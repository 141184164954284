export const getForegroundColor = (backgroundColor) => {
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (backgroundColor.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    backgroundColor = backgroundColor.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = backgroundColor[1];
    g = backgroundColor[2];
    b = backgroundColor[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    backgroundColor = +(
      "0x" +
      backgroundColor
        .slice(1)
        .replace(backgroundColor.length < 5 && /./g, "$&$&")
    );

    r = backgroundColor >> 16;
    // eslint-disable-next-line
    g = (backgroundColor >> 8) & 255;
    b = backgroundColor & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    //color is light, use $background-primary color
    return "#272320";
  } else {
    //color is dark, use $foreground-primary color
    return "#ECE4B6";
  }
};
