// @flow

import React from "react";

import { Rx } from "./components/";

type Props = {};

const Prescriptions = (props: Props) => {
  return (
    <div className="ReportsPageContent">
      <Rx {...props} />
    </div>
  );
};

Prescriptions.defaultProps = {};
export default Prescriptions;
