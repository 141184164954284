import { connect } from "react-redux";
import Map from "./index";

import {
  flyTo,
  setZoom,
  selectField,
  getAvailableLayers,
  getBoundaryGeojson
} from "../../redux/actions";

const mapStateToProps = (state) => {
  return {
    fields: state.fields,
    center: state.app.center,
    zoom: state.app.zoom,
    activeFieldId: state.app.activeFieldId,
    activeFieldName: state.app.activeFieldName,
    activeFieldCoords: state.app.activeFieldCoords,
    user: state.user,
    loading:
      state.fields.components.get_layer_geospatial.loading ||
      state.fields.components.get_fields.loading ||
      state.fields.components.get_boundary_geojson.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    flyTo: (coords) => {
      dispatch(flyTo(coords));
    },
    selectField: (
      fieldName,
      fieldId,
      fieldCoords,
      fieldBoundaryLocked
    ) => {
      //TODO: Optimize
      dispatch(
        selectField(
          fieldName,
          fieldId,
          fieldCoords,
          fieldBoundaryLocked
        )
      );
      dispatch(getBoundaryGeojson(fieldId));
      dispatch(getAvailableLayers(fieldId));
      dispatch(flyTo(fieldCoords));
    },
    setZoom: (zoom) => {
      dispatch(setZoom(zoom));
    }
  };
};

const MapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);

export default MapContainer;
