// @flow
import React, { useEffect } from "react";

import { Routes } from "react-router-dom";

import "normalize.css";
import "../styles/app.scss";
import "../styles/printable.scss";

import * as APP_ATTRIBUTE from "../entitlements/appAttributes";
import { hasAppAttribute } from "../entitlements";
import {
  isChrome,
  isFirefox,
  isSafari,
  isMobile
} from "react-device-detect";
import { AlertBar, Loading } from "../components/";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faCog,
  faPrescription,
  faSlidersH,
  faUser,
  faSignOutAlt,
  faChartLine,
  faExclamationTriangle,
  faBell,
  faEye,
  faEyeSlash,
  faQuestionCircle,
  faPlus,
  faCheck,
  faEnvelope,
  faEnvelopeOpenText,
  faEnvelopeOpen,
  faChevronDown,
  faChevronUp,
  faPencilAlt,
  faTrashAlt,
  faCalculator,
  faRedoAlt,
  faSeedling,
  faTractor,
  faPlay,
  faPaperPlane,
  faTable
} from "@fortawesome/free-solid-svg-icons";
import { Toasts, Modal } from "../components/";
import {
  Home,
  Dashboard,
  Farms,
  Account,
  DataManagement,
  Reports,
  Login,
  TokenLogin,
  Inbox,
  NotFound,
  ConfirmAccount,
  RequestPasswordReset,
  ResetPassword,
  InfoCenter,
  Library
} from "../pages/";

// import mockupDashboard from "../redux/mockups/mockupDashboard.json";
// import mockupFarmMgmt from "../redux/mockups/mockupFarmMgmt.json";
// import mockupYield from "../redux/mockups/mockupYield.json";

library.add(
  faHome,
  faCog,
  faPrescription,
  faSlidersH,
  faUser,
  faSignOutAlt,
  faChartLine,
  faExclamationTriangle,
  faBell,
  faEye,
  faEyeSlash,
  faQuestionCircle,
  faPlus,
  faEnvelope,
  faEnvelopeOpenText,
  faEnvelopeOpen,
  faCheck,
  faChevronDown,
  faChevronUp,
  faPencilAlt,
  faTrashAlt,
  faCalculator,
  faRedoAlt,
  faSeedling,
  faTractor,
  faPlay,
  faPaperPlane,
  faTable
);

function isSupportedBrowser() {
  return isChrome || isFirefox || isSafari;
}

type Props = {};
const App = (props: Props) => {
  const {
    isAuthenticated,
    showBrowserWarning
    // showInvestmentBanner
  } = props.session;
  const {
    getUser,
    dismissBrowserWarning
    // dismissInvestmentBanner,
    // requestToBuyUnits
  } = props;

  useEffect(() => {
    if (isAuthenticated && !props.user.role) {
      getUser(); //Get the user info in order to render the page based on user role
    }
  });

  let browserWarning =
    showBrowserWarning && (!isSupportedBrowser() || isMobile);
  let browserAlert = browserWarning && (
    <AlertBar
      style={{
        position: "absolute",
        top: "0",
        width: "100%"
      }}
      dismissAction={dismissBrowserWarning}
    >
      This browser is not supported. Please use Chrome, Firefox, or
      Safari.
      {isMobile &&
        " My Farm Intelligence works best on a desktop computer. "}{" "}
    </AlertBar>
  );

  // let investmentBanner = showInvestmentBanner &&
  //   <AlertBar type="info" style={{
  //     position: "absolute",
  //     top: "0",
  //     width: "100%"
  //   }}
  //     dismissAction={dismissInvestmentBanner}>

  //     <div>
  //       <p className="BigText"> Only farmers can own a piece of MFI. You can too.</p>
  //       <input type="button" className="LinkButton" value="Learn More" onClick={() => { requestToBuyUnits(); dismissInvestmentBanner(); }} />

  //     </div>
  //   </AlertBar>

  if (isAuthenticated && !props.user.role) {
    return (
      <div className="AppLoading">
        <Loading />
      </div>
    );
  } else if (isAuthenticated) {
    //Authenticated user: load application content
    return (
      <div className="App">
        {/* <AlertBar style={{
          position: "absolute",
          top: "0",
          width: "100%"
        }}>
          Starting Monday, August 3, MFI will be upgrading our site infrastructure.
          We will not be accepting any new file uploads until the migration is complete.
          Thank you for your patience as we upgrade the MFI experience!


          </AlertBar> */}

        {browserAlert}
        {/* TODO: Uncomment this section to enabled the Investment Banner
          Clicking on the banner sends an email to Sam to get more info about ownership units.
          {investmentBanner}
          */}

        <Toasts />
        <Modal appElement="body" />
        <Routes>
          {hasAppAttribute(APP_ATTRIBUTE.HOME_PAGE, props.user) && (
            <React.Fragment>
              <Home path="/" {...props} />
              <Home path="/app/login/token/:token" {...props} />
            </React.Fragment>
          )}
          {hasAppAttribute(APP_ATTRIBUTE.FARMER_APP, props.user) && (
            <React.Fragment>
              <Dashboard path="/app/dashboard" {...props} />
              <Dashboard path="*" {...props} />
              <Farms path="/app/farms" {...props} />
              <Reports path="/app/reports" {...props} />
              <DataManagement path="/app/data/*" {...props} />
              <Account path="/app/account/*" {...props} />
              <Inbox path="/app/inbox" {...props} />
              <InfoCenter path="/blog" {...props} />
              <Library path="/app/library" {...props} />
            </React.Fragment>
          )}
          <NotFound path="*" />
        </Routes>
      </div>
    );
  } else {
    //Not Authenticated - Only render login form
    return (
      <div className="App">
        {browserAlert}
        <Routes>
          {/*
            TODO: Put this back when making public
            <Home path="/" />
            */}
          <Login path="*" {...props} />
          <TokenLogin path="/app/login/token/:token" {...props} />
          <ResetPassword path="/app/login/reset/:token" {...props} />
          <RequestPasswordReset
            path="/app/login/request"
            {...props}
          />
          <ConfirmAccount
            path="/app/login/confirm/:token"
            {...props}
          />
        </Routes>
      </div>
    );
  }
};

App.defaultProps = {};

export default App;
