import { connect } from "react-redux";
import Toasts from "./index";

import { hideToast } from "../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    toasts: state.toast.toasts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideToast: (toast) => {
      dispatch(hideToast(toast));
    }
  };
};

const ToastsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Toasts);

export default ToastsContainer;
