import { connect } from "react-redux";
import App from "./index";

import {
  addFarm,
  updateFarm,
  assignFieldToFarm,
  login,
  logout,
  dismissBrowserWarning,
  dismissInvestmentBanner,
  tokenLogin,
  deleteUser,
  requestPasswordReset,
  resetPassword,
  confirmAccount,
  updateAccountSettings,
  updateLoginInfo,
  getUser,
  registerUser,
  inviteUser,
  resendInvite,
  getReferrals,
  uploadFiles,
  getLayer,
  getNearbyFields,
  //getLayerRaster,
  getLayerGeospatial,
  copyPracticeProducts,
  removeLayer,
  clearUserError,
  flyTo,
  setLayerVariable,
  selectField,
  deleteField,
  updateField,
  updateBoundary,
  selectFarm,
  deleteFarm,
  getAvailableLayers,
  getBoundaryGeojson,
  downloadPrescriptionShapefile,
  markMessageRead,
  archiveMessage,
  displayModal,
  displayVideoModal,
  runSimulation,
  getFileStatusByYear,
  getFileYears,
  reprocessFiles,
  deleteFiles,
  reprocessSeedFit,
  reprocessFertility,
  getFileProcessingStatus,
  validateSeeds,
  getPlantedSeeds,
  updateSeedName,
  completeInvite,
  contactTeam,
  updateManagedSeeds,
  getSeedCompanies,
  getSeedsByCompany,
  adminGetAllUsers,
  adminUpdateUser,
  adminDeleteUser,
  deleteManagedSeed,
  showToast,
  getProductPrices,
  getUnassignedProductPrices,
  deleteProductPrice,
  updateProductPrice,
  getPracticeProducts,
  updatePracticeProduct,
  deletePracticeProduct,
  updateFieldPractices,
  deleteFieldPractice,
  getDataYears,
  requestToBuyUnits
} from "../redux/actions";

const mapStateToProps = (state) => {
  return {
    app: {
      activeFieldName: state.app.activeFieldName,
      activeFieldId: state.app.activeFieldId,
      activeFieldCoords: state.app.activeFieldCoords,
      activeFieldBoundaryLocked: state.app.activeFieldBoundaryLocked,
      activeFarmId: state.app.activeFarmId,
      activeFarmName: state.app.activeFarmName,
      activeFarmColor: state.app.activeFarmColor,
      activeYieldEnv: state.app.activeYieldEnv,
      showMap: state.app.showMap,
      center: state.app.center,
      isRegistered: state.user.isRegistered
    },
    farms: state.farms,
    inbox: state.inbox,
    user: state.user,
    session: state.session,
    fields: state.fields,
    components: state.components,
    profiles: state.profiles,
    files: state.files,
    seeds: state.seeds,
    admin: state.admin,
    prices: state.prices,
    practices: state.practices
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (message) => {
      dispatch(showToast(message));
    },
    login: (email, password) => {
      dispatch(login(email, password));
    },
    logout: () => {
      dispatch(
        displayModal(
          "Are you sure you want to log out?",
          "Yes, log out",

          logout,
          "No, go back"
        )
      );
    },

    tokenLogin: (token) => {
      dispatch(tokenLogin(token));
    },
    requestPasswordReset: (email) => {
      dispatch(requestPasswordReset(email));
    },
    resetPassword: (email, token) => {
      dispatch(resetPassword(email, token));
    },
    confirmAccount: (token) => {
      dispatch(confirmAccount(token));
    },
    getUser: () => {
      dispatch(getUser());
    },
    registerUser: (name, email, password, phone, code, role) => {
      dispatch(
        registerUser(name, email, password, phone, code, role)
      );
    },
    inviteUser: (name, email, phone, message) => {
      dispatch(inviteUser(name, email, phone, message));
    },
    resendInvite: (email) => {
      dispatch(
        displayModal(
          `Are you sure you want to resend an invite to ${email}?`,
          "Yes, resend invite",
          () => resendInvite(email),
          "No, go back"
        )
      );
    },
    completeInvite: () => {
      dispatch(completeInvite());
    },
    deleteUser: () => {
      dispatch(
        displayModal(
          `Are you sure you want to delete your account? All data will be permanently deleted.`,
          "Yes, delete my account",
          () => deleteUser(),
          "No, go back"
        )
      );
    },
    getReferrals: () => {
      dispatch(getReferrals());
    },
    refreshFileProcessingStatus: () => {
      dispatch(getFileProcessingStatus());
    },
    addFarm: (name, color) => {
      dispatch(addFarm(name, color));
    },
    updateFarm: (id, name, color) => {
      dispatch(updateFarm(id, name, color));
    },
    assignFieldToFarm: (fieldId, farmId) => {
      dispatch(assignFieldToFarm(fieldId, farmId));
    },

    updateAccountSettings: (data) => {
      dispatch(updateAccountSettings(data));
    },

    updateLoginInfo: (email, current_password, new_password) => {
      dispatch(
        displayModal(
          "Are you sure you want to update your credentials?  You will need to log in again.",
          "Yes, update my credentials",

          () =>
            updateLoginInfo(email, current_password, new_password),
          "No, go back"
        )
      );
    },
    uploadFiles: (files) => {
      dispatch(uploadFiles(files));
    },
    getLayer: (name, field, year, coords) => {
      dispatch(flyTo(coords));
      dispatch(getLayer(name, field, year));
    },
    getLayerGeospatial: (name, field, year, coords) => {
      dispatch(getLayerGeospatial(name, field, year));
    },
    removeLayer: (name, field, year) => {
      dispatch(removeLayer(name, field, year));
    },
    runSimulation: (simulation, field, year, crop_type, options) => {
      dispatch(
        runSimulation(simulation, field, year, crop_type, options)
      );
    },
    downloadPrescriptionShapefile: (field_id, field_name, rates) => {
      dispatch(
        downloadPrescriptionShapefile(field_id, field_name, rates)
      );
    },
    clearUserError: () => {
      dispatch(clearUserError());
    },
    flyTo: (coords, zoom) => {
      dispatch(flyTo(coords));
    },
    selectField: (
      fieldName,
      fieldId,
      fieldCoords,
      fieldBoundaryLocked
    ) => {
      dispatch(
        selectField(
          fieldName,
          fieldId,
          fieldCoords,
          fieldBoundaryLocked
        )
      );
      dispatch(getBoundaryGeojson(fieldId));
      dispatch(getAvailableLayers(fieldId));
      dispatch(flyTo(fieldCoords));
    },

    setLayerVariable: (
      fieldId,
      layerGroup,
      layer,
      sublayer,
      variableName,
      variableValue
    ) => {
      dispatch(
        setLayerVariable(
          fieldId,
          layerGroup,
          layer,
          sublayer,
          variableName,
          variableValue
        )
      );
    },
    deleteField: (fieldName, fieldId) => {
      dispatch(
        displayModal(
          `Are you sure you want to delete ${fieldName}?  This will permanently delete all data related to this field.`,
          "Yes, delete it",
          () => deleteField(fieldId),
          "No, go back"
        )
      );
    },
    updateField: (fieldId, fieldName, lockBoundary) => {
      dispatch(updateField(fieldId, fieldName, lockBoundary));
    },
    getNearbyFields: (fieldId) => {
      dispatch(getNearbyFields(fieldId));
    },
    updateBoundary: (
      activeFieldName,
      fieldNameToMerge,
      activeFieldId,
      boundaryIdToMerge
    ) => {
      dispatch(
        displayModal(
          `Are you sure you want to merge ${activeFieldName} with ${fieldNameToMerge}?  This cannot be undone.`,
          "Yes, merge them",
          () => updateBoundary(activeFieldId, boundaryIdToMerge),
          "No, go back"
        )
      );
    },
    selectFarm: (farmId, farmName, farmColor) => {
      dispatch(selectFarm(farmId, farmName, farmColor));
    },
    deleteFarm: (farmId, farmName) => {
      dispatch(
        displayModal(
          `Are you sure you want to delete ${farmName}?  This will permanently delete this farm label.  Fields in the farm will not be deleted.`,
          "Yes, delete it",
          () => deleteFarm(farmId),
          "No, go back"
        )
      );
    },
    markMessageRead: (id) => {
      dispatch(markMessageRead(id));
    },
    archiveMessage: (id) => {
      dispatch(archiveMessage(id));
    },
    getFileStatusByYear: (year) => {
      dispatch(getFileStatusByYear(year));
    },
    getFileYears: () => {
      dispatch(getFileYears());
    },
    getDataYears: () => {
      dispatch(getDataYears());
    },
    watchVideo: (video) => {
      dispatch(displayVideoModal(video));
    },
    reprocessFiles: (file_ids, file_names, year) => {
      dispatch(
        displayModal(
          `Are you sure you want to reprocess files ${file_names}?`,
          "Yes, reprocess files",
          () => reprocessFiles(file_ids, file_names, year),
          "No, go back"
        )
      );
    },
    deleteFiles: (file_ids, file_names, year) => {
      dispatch(
        displayModal(
          `Are you sure you want to delete files ${file_names}?`,
          "Yes, delete files",
          () => deleteFiles(file_ids, file_names, year),
          "No, go back"
        )
      );
    },
    reprocessFertility: (field_ids, field_names) => {
      dispatch(
        displayModal(
          `Are you sure you want to reprocess fertility for ${field_names}?`,
          "Yes, reprocess fertility",
          () => reprocessFertility(field_ids, field_names),
          "No, go back"
        )
      );
    },
    reprocessSeedFit: (field_ids, field_names, year) => {
      dispatch(
        displayModal(
          `Are you sure you want to reprocess seed fit for ${field_names}?`,
          "Yes, reprocess seed fit",
          () => reprocessSeedFit(field_ids, field_names, year),
          "No, go back"
        )
      );
    },
    validateSeeds: () => {
      dispatch(
        displayModal(
          `Are you sure you want to mark all seeds as validated for your account?`,
          "Yes, validate",
          () => validateSeeds(),
          "No, go back"
        )
      );
    },
    getPlantedSeeds: (year) => {
      dispatch(getPlantedSeeds(year));
    },

    updateSeedName: (
      new_seed_name,
      old_seed_name,
      field_id,
      year,
      crop_type
    ) => {
      dispatch(
        updateSeedName(
          new_seed_name,
          old_seed_name,
          field_id,
          year,
          crop_type
        )
      );
    },
    contactTeam: (message, dropdownOption) => {
      dispatch(contactTeam(message, dropdownOption));
    },
    requestToBuyUnits: (unitBlocksRequested) => {
      dispatch(requestToBuyUnits(unitBlocksRequested));
    },
    updateManagedSeeds: (seed) => {
      dispatch(updateManagedSeeds(seed));
    },
    getSeedCompanies: () => {
      dispatch(getSeedCompanies());
    },
    getSeedsByCompany: (companyName) => {
      dispatch(getSeedsByCompany(companyName));
    },
    getProductPrices: (year, mode) => {
      dispatch(getProductPrices(year, mode));
    },
    getUnassignedProductPrices: (year, mode) => {
      dispatch(getUnassignedProductPrices(year, mode));
    },
    updateProductPrice: (product, mode) => {
      dispatch(updateProductPrice(product, mode));
    },
    deleteProductPrice: (product, mode) => {
      dispatch(
        displayModal(
          `Are you sure you want to delete this product price: ${
            product.product_name
          }${
            product.supplier_name ? ` (${product.supplier_name})` : ""
          }? `,
          "Yes, delete this product price",
          () => deleteProductPrice(product, mode),
          "No, go back"
        )
      );
    },

    getPracticeProducts: (year) => {
      dispatch(getPracticeProducts(year));
    },
    updatePracticeProduct: (practice) => {
      dispatch(updatePracticeProduct(practice));
    },
    copyPracticeProducts: (practiceIds, copyYear) => {
      dispatch(copyPracticeProducts(practiceIds, copyYear));
    },
    deletePracticeProduct: (practice) => {
      dispatch(
        displayModal(
          `Are you sure you want to delete this practice: ${practice.practice_name} `,
          "Yes, delete this practice",
          () => deletePracticeProduct(practice),
          "No, go back"
        )
      );
    },
    updateFieldPractices: (field_practices) => {
      dispatch(updateFieldPractices(field_practices));
    },
    deleteFieldPractice: (field_practice) => {
      dispatch(
        displayModal(
          `Are you sure you want to remove this practice from this field: ${field_practice.practice_product.practice_name} `,
          "Yes, remove practice",
          () => deleteFieldPractice(field_practice),
          "No, go back"
        )
      );
    },
    adminGetAllUsers: () => {
      dispatch(adminGetAllUsers());
    },
    adminUpdateUser: (user) => {
      dispatch(
        displayModal(
          `Are you sure you want to update this account: ${user.email}?`,
          "Yes, update this account",
          () => adminUpdateUser(user),
          "No, go back"
        )
      );
    },
    dismissBrowserWarning: () => {
      dispatch(dismissBrowserWarning());
    },
    dismissInvestmentBanner: () => {
      dispatch(dismissInvestmentBanner());
    },
    adminDeleteUser: (user) => {
      dispatch(
        displayModal(
          `Are you sure you want to delete this account: ${user.email}?All data will be permanently deleted.`,
          "Yes, delete this account",
          () => adminDeleteUser(user),
          "No, go back"
        )
      );
    },
    deleteManagedSeed: (seed) => {
      dispatch(
        displayModal(
          `Are you sure you want to delete this seed: ${seed.seed_company_name} ${seed.seed_name}?`,
          "Yes, delete this seed",
          () => deleteManagedSeed(seed),
          "No, go back"
        )
      );
    }
  };
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;
