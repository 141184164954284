import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Loading, Notice } from "../../";

import "./styles.scss";

const Header = (props) => {
  const { title, loading, error, success, className } = props;
  return (
    <React.Fragment>
      <header className={`Header ${className}`}>
        <h2>{title}</h2>
        {loading ? <Loading /> : null}
        {error ? (
          <FontAwesomeIcon icon="exclamation-triangle" size="1x" />
        ) : null}
        {success ? <FontAwesomeIcon icon="check" size="1x" /> : null}
      </header>
      {error ? (
        <Notice>
          <FontAwesomeIcon icon="exclamation-triangle" size="1x" />
          <span>{error}</span>
        </Notice>
      ) : null}
    </React.Fragment>
  );
};
export default Header;
