import * as ACTION from "../constants";

export const practicesReducer = (
  state = {
    practice_products: [],
    years: [],
    components: {
      get_practice_products: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_practice_product: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_field_practices: {
        loading: false,
        error: undefined,
        success: undefined
      }
    }
  },
  action
) => {
  switch (action.type) {
    case ACTION.GET_PRACTICE_PRODUCTS:
      return {
        ...state,
        components: {
          ...state.components,
          get_practice_products: {
            ...state.components.get_practice_products,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.GET_PRACTICE_PRODUCTS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_practice_products: {
            ...state.components.get_practice_products,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.GET_PRACTICE_PRODUCTS_SUCCESS:
      return {
        ...state,
        practice_products: action.data.practice_products,
        components: {
          ...state.components,
          get_practice_products: {
            ...state.components.get_practice_products,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.UPDATE_PRACTICE_PRODUCT:
    case ACTION.COPY_PRACTICE_PRODUCT:
    case ACTION.DELETE_PRACTICE_PRODUCT:
      return {
        ...state,
        components: {
          ...state.components,
          update_practice_product: {
            ...state.components.update_practice_product,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.UPDATE_PRACTICE_PRODUCT_FAIL:
    case ACTION.COPY_PRACTICE_PRODUCT_FAIL:
    case ACTION.DELETE_PRACTICE_PRODUCT_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_practice_product: {
            ...state.components.update_practice_product,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.UPDATE_PRACTICE_PRODUCT_SUCCESS:
    case ACTION.COPY_PRACTICE_PRODUCT_SUCCESS:
    case ACTION.DELETE_PRACTICE_PRODUCT_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_practice_product: {
            ...state.components.update_practice_product,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.UPDATE_FIELD_PRACTICES:
    case ACTION.DELETE_FIELD_PRACTICE:
      return {
        ...state,
        components: {
          ...state.components,
          update_field_practices: {
            ...state.components.update_field_practices,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.UPDATE_FIELD_PRACTICES_FAIL:
    case ACTION.DELETE_FIELD_PRACTICE_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_field_practices: {
            ...state.components.update_field_practices,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.UPDATE_FIELD_PRACTICES_SUCCESS:
    case ACTION.DELETE_FIELD_PRACTICE_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_field_practices: {
            ...state.components.update_field_practices,
            loading: false,
            success: true
          }
        }
      };
    default:
      return state;
  }
};
