// @flow

import React from "react";

type Props = {};

const Removals = (props: Props) => {
  const { layer } = props;
  return (
    <React.Fragment>
      <div className="ReportInfo">
        <span>Total Acres:</span>
        <span>{layer.data.total_acres}</span>
      </div>
      <div className="ReportInfo">
        <span>Total Yield (Bu):</span>
        <span>{layer.data.total_yield}</span>
      </div>
    </React.Fragment>
  );
};

Removals.defaultProps = {};
export default Removals;
