// @flow

import React from "react";

import { Summary } from "./components/";

type Props = {};

const Yield = (props: Props) => {
  return (
    <div className="ReportsPageContent">
      <Summary {...props} />
    </div>
  );
};

Yield.defaultProps = {};
export default Yield;
