// @flow

import React, { useState } from "react";
import ReactTooltip from "react-tooltip";

import "./styles.scss";

import { Loading, FieldCardSelect } from "../../components";

import { Multiselect } from "multiselect-react-dropdown";

const onDragStart = (ev, id) => {
  ev.dataTransfer.setData("id", id);
};

const onDragOver = (ev) => {
  ev.preventDefault();
};
const FieldList = (props) => {
  const { summary } = props.fields;
  const { loading } = props.fields.components.get_fields;
  const { selectField, onDrop, placeholder } = props;
  const { activeFieldId, activeFieldName } = props.app;

  var fieldsList = summary.sort(function (a, b) {
    var textA = a.properties.field_name.toUpperCase();
    var textB = b.properties.field_name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  //eslint-disable-next-line
  const [selectedFarms, setSelectedFarms] = useState([]);
  const [selectedFields, setSelectedFields] = useState(
    activeFieldId
      ? [{ field_name: activeFieldName, field_id: activeFieldName }]
      : []
  );

  var farmsOptions = [];
  if (fieldsList) {
    fieldsList.forEach((field) => {
      field.field_name = field.properties.field_name;
      field.field_id = field.properties.field_id;
      if (
        typeof field.properties.farm_name !== "undefined" &&
        field.properties.farm_name !== null &&
        !farmsOptions.some(
          (farm) => farm.name === field.properties.farm_name
        )
      ) {
        farmsOptions.push({ name: field.properties.farm_name });
      }
    });
  }

  var filteredFieldList = fieldsList.filter((field) => {
    return (
      (Array.isArray(selectedFarms) && selectedFarms.length === 0) ||
      selectedFarms.some(
        (farm) => farm.name === field.properties.farm_name
      )
    );
  });

  return (
    <div
      className="FieldSelectList"
      onDragOver={(e) => onDragOver(e)}
      onDrop={(ev) => onDrop && onDrop(ev.dataTransfer.getData("id"))}
    >
      <ReactTooltip
        id="FieldListTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />
      {loading && <Loading />}
      {/* <Multiselect
        selectionLimit="3"
        style={{
          multiselectContainer: {
            // To change css for multiselect (Width,height,etc..)
          },
          searchBox: {
            // To change search box element look
            border: "none",
            color: "#f5d870",
            minHeight: "2.4em"
          },
          inputField: {
            // To change input field position or margin
            margin: "5px",
            border: "1px solid #f5d870",
            borderRadius: "8px",
            height: "2.4em"
          },
          chips: {
            // To change css chips(Selected options)
            backgroundColor: "forest-green",
            color: "#f5d870",
            height: "1em"
          },
          optionContainer: {
            // To change css for option container
            border: "1px solid #f5d870",
            backgroundColor: "#3e342e"
          },
          option: {
            // To change css for dropdown options
          },
          groupHeading: {
            // To chanage group heading style
          }
        }}
        options={farmsOptions} // Options to display in the dropdown
        selectedValues={selectedFarms} // Preselected value to persist in dropdown
        onSelect={(evt) => setSelectedFarms(evt)} // Function will trigger on select event
        onRemove={(evt) => setSelectedFarms(evt)} // Function will trigger on remove event
        displayValue="name" // Property name to display in the dropdown options
        placeholder="Search farms"
      /> */}
      <Multiselect
        selectionLimit="1"
        style={{
          multiselectContainer: {
            // To change css for multiselect (Width,height,etc..)
          },
          searchBox: {
            // To change search box element look
            border: "none",
            color: "#f5d870 !important",
            minHeight: "2.4em"
          },
          inputField: {
            // To change input field position or margin
            margin: "5px",
            border: "1px solid #f5d870",
            borderRadius: "8px",
            color: "#f5d870",

            height: "2.4em"
          },
          chips: {
            // To change css chips(Selected options)
            backgroundColor: "forest-green",
            color: "#f5d870",
            height: "1em"
          },
          optionContainer: {
            // To change css for option container
            border: "1px solid #f5d870",
            backgroundColor: "#3e342e"
          },
          option: {
            // To change css for dropdown options
          },
          groupHeading: {
            // To chanage group heading style
          }
        }}
        placeholder={placeholder}
        options={filteredFieldList} // Options to display in the dropdown
        selectedValues={selectedFields} // Preselected value to persist in dropdown
        onSelect={(evt) =>
          selectField(
            evt[0].properties.field_name,
            evt[0].properties.field_id,
            [
              evt[0].geometry.coordinates[1],
              evt[0].geometry.coordinates[0]
            ],
            evt[0].properties.boundary_locked
          )
        } // Function will trigger on select event
        onRemove={(evt) => setSelectedFields(evt)} // Function will trigger on remove event
        displayValue="field_name" // Property name to display in the dropdown options
      />{" "}
      <ul>
        {filteredFieldList.length > 0 &&
          filteredFieldList.map((field) => {
            return (
              <li
                draggable
                onDragStart={(e) =>
                  onDragStart(e, field.properties.field_id)
                }
                key={"select" + field.properties.field_id}
                className={
                  activeFieldId === field.properties.field_id
                    ? "active"
                    : null
                }
                onClick={() => {
                  selectField(
                    field.properties.field_name,
                    field.properties.field_id,
                    [
                      field.geometry.coordinates[1],
                      field.geometry.coordinates[0]
                    ],
                    field.properties.boundary_locked
                  );
                  setSelectedFields([
                    {
                      field_id: field.properties.field_id,
                      field_name: field.properties.field_name
                    }
                  ]);
                }}
              >
                <FieldCardSelect field={field} {...props} />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

FieldList.defaultProps = {
  title: "My Fields",
  fields: []
};
export default FieldList;
