// @flow

import React from "react";

import { VideoPlayer } from "../../../components";

import { isMobile } from "react-device-detect";

const Post = (props) => {
  const { date, title, body, video, slug } = props;
  return (
    <div className="Post" id={slug}>
      <h1 className="PostHeading">{title}</h1>
      <h2>{date}</h2>

      <div
        className="PanelWrapper"
        style={{
          gridTemplateColumns: !isMobile && video ? "1fr 1fr" : "1fr"
        }}
      >
        <div className="PanelContent">
          <p className="BigText">{body}</p>
        </div>

        {video && (
          <div className="PanelContent">
            <p>
              <VideoPlayer
                height={320}
                src={video.src}
                poster={video.poster}
                autoplay={false}
                controls={true}
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Post;
