import * as ACTION from "../constants";

import { buildJsonRequest, getErrorMessage } from "../utils";
import { showToast } from "./";

export const adminGetAllUsers = () => {
  return buildJsonRequest(
    {
      url: `/user/users`,
      method: "GET"
    },
    ACTION.ADMIN_GET_ALL_USERS
  );
};

export const adminUpdateUser = (user) => {
  return buildJsonRequest(
    {
      url: `/user/user_admin`,
      method: "PUT",
      data: user
    },
    ACTION.ADMIN_UPDATE_USER,
    [
      () => showToast(`Updated user: ${user.email}`),
      adminGetAllUsers
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const adminDeleteUser = (user) => {
  return buildJsonRequest(
    {
      url: "/user/user_admin",
      method: "DELETE",
      data: user
    },
    ACTION.ADMIN_DELETE_USER,
    [
      () => showToast(`Account Deleted: ${user.email}`),
      adminGetAllUsers
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};
