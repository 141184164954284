// @flow

import React from "react";

import { Input, AlertBar, Header } from "../../../components";
// eslint-disable-next-line
import { DeleteUser } from "./";

import { validateEmail, validatePassword } from "../../../utils";

type Props = {};

class LoginInfo extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      email: props.user.email,
      currentPassword: "",
      newPassword: undefined,
      validationError: undefined
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    const { email, current_password, new_password } = this.state;
    var validationMessage = "";

    if (!email || !current_password) {
      validationMessage =
        validationMessage +
        "\u2022 Email and Current Password are required.\n";
    }

    var validateEmailResult = validateEmail(email);
    if (!validateEmailResult.status) {
      validationMessage =
        validationMessage + validateEmailResult.message;
    }

    if (new_password) {
      var validatePasswordResult = validatePassword(new_password);
      if (!validatePasswordResult.status) {
        validationMessage =
          validationMessage + validatePasswordResult.message;
      }
    }

    this.setState({
      validationError: validationMessage
    });
    return validationMessage === "";
  };

  render() {
    const {
      error,
      loading,
      success
    } = this.props.user.components.update_login_info;
    const { updateLoginInfo, clearUserError } = this.props;

    const {
      email,
      current_password,
      new_password,
      validationError
    } = this.state;

    return (
      <div className="RightPanelContent LoginInfo">
        <Header
          title="Change my Email or Password"
          loading={loading}
          success={success}
          error={error}
        />
        {validationError || error ? (
          <AlertBar>
            {validationError ? (
              <div>
                {validationError.split("\n").map((item) => {
                  return <div>{item}</div>;
                })}
              </div>
            ) : null}
            {error}
          </AlertBar>
        ) : null}

        <form className="ProfileForm">
          <label>
            <span>Email:</span>
            <Input
              name="email"
              type="text"
              value={email}
              onChange={this.onChange}
              mask={/^\S*@?\S*$/}
            />
          </label>
          <label>
            <span>Current Password:</span>
            <Input
              name="current_password"
              type="password"
              value={current_password}
              onChange={this.onChange}
            />
          </label>
          <label>
            <span>New Password:</span>
            <Input
              name="new_password"
              type="password"
              value={new_password}
              onChange={this.onChange}
            />
          </label>

          <input
            type="button"
            value="Update Login Info"
            onClick={() => {
              clearUserError();
              this.validate()
                ? updateLoginInfo(
                    email,
                    current_password,
                    new_password
                  )
                : console.log("validation error");
            }}
          />
        </form>

        {/* <DeleteUser {...this.props} /> */}
      </div>
    );
  }
}

LoginInfo.defaultProps = {};
export default LoginInfo;
