// @flow

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading, Notice } from "../../../components";
import { Link } from "react-router-dom";

type Props = {
  messages: Array
};

const Notices = (props: Props) => {
  const { messages } = props.inbox;
  const { loading } = props.inbox.components.inbox;

  let unreadMessages =
    messages &&
    messages.filter(function (message) {
      return !message.has_read;
    });

  if (!unreadMessages || unreadMessages.length === 0) return null;

  return (
    <div className="Notices">
      <header>
        <h2>Notices</h2>
        {loading ? <Loading /> : null}
      </header>

      <ul>
        {unreadMessages.slice(0, 3).map((message, index) => {
          var iconName;

          switch (message.msg_type) {
            case "alert":
              iconName = "bell";
              break;
            case "error":
              iconName = "exclamation-triangle";
              break;
            case "bell":
            default:
              iconName = "question-circle";
              break;
          }

          return (
            <li key={message.id}>
              <Notice>
                <FontAwesomeIcon icon={iconName} size="1x" />
                <span>{message.msg_subject}</span>
              </Notice>
            </li>
          );
        })}
      </ul>
      {unreadMessages.length > 3 && (
        <Link to="/app/inbox">See more in inbox</Link>
      )}
    </div>
  );
};

Notices.defaultProps = {
  messages: []
};
export default Notices;
