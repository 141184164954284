// @flow

import React from "react";
import Section from "./Section";
import { VideoModal, Notice } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const videosFeatures = [
  {
    title: "Fertility Tool",
    src: "https://ddwzudgjmurcz.cloudfront.net/berkh-fertility"
  },
  {
    title: "Seed Tool - How it Works",
    src: "https://ddwzudgjmurcz.cloudfront.net/chris-genetics"
  },
  {
    title: "Using the Seed Tool",
    src: "https://ddwzudgjmurcz.cloudfront.net/amanda-seedfit"
  },
  {
    title: "Get Help",
    src: "https://ddwzudgjmurcz.cloudfront.net/sam-alec-mygoldfarm"
  },
  {
    title: "Organize My Farms and Fields",
    src: "https://ddwzudgjmurcz.cloudfront.net/berkh-farmmgmt"
  },
  {
    title: "Invite Tool",
    src: "https://ddwzudgjmurcz.cloudfront.net/berkh-invite"
  },
  {
    title: "Dealer Seed Management Tool",
    src: "https://ddwzudgjmurcz.cloudfront.net/berkh-dealerseedmgmt"
  },
  {
    title: "Farming Practices Tool",
    src:
      "https://ddwzudgjmurcz.cloudfront.net/amanda-farmingpractices"
  }
];

const HowTo = (props: Props) => {
  return (
    <Section
      id="tutorials"
      onVisibilityChange={props.onVisibilityChange}
      style={{ minHeight: "105em" }}
    >
      <div className="Section XL" id="tutorials">
        <h1 className="SectionHeading">Tutorials</h1>
        <p className="BigText">
          Discover the value of different farming practices using our
          unique tools and expert guidance.
        </p>
        <Notice>
          <FontAwesomeIcon icon="exclamation-triangle" size="1x" />
          <span>
            This content is protected by our confidentiality
            agreement. Do not share this content with individuals who
            have not signed the confidentiality agreement. If you wish
            to share this content with someone who has not signed the
            agreement, use the invite tool to send them an invite and
            make sure that they sign the agreement before continuing.
          </span>
        </Notice>
        <div className="PanelWrapper">
          <div className="PanelContent">
            <h1>How To Videos</h1>

            {/* <h2>Features</h2> */}
            <div className="Team">
              {videosFeatures.map((video) => {
                return (
                  <VideoModal
                    {...props}
                    title={video.title}
                    img={`${video.src}/poster.png`}
                    src={`${video.src}/video.mp4`}
                  />
                );
              })}
            </div>

            {/* <Contact {...props} /> */}
          </div>
          <div className="PanelContent">
            <h1>Upcoming Value Roadmap</h1>

            <ol>
              <li>
                <i>(Summer 2020) </i>Pick the most profitable seed
                based on the world's first true comparative
                productivity ranking from local results fitted to
                field capabilities.
              </li>
              <li>
                <i>(Fall 2020) </i>Understand the impact of water
                drainage in each of your fields and value for
                improvement.
              </li>
              <li>
                <i>(Spring 2021) </i>Use the history of your field to
                plan the most profitable crop rotation practices for
                it.
              </li>
              <li>
                <i>(Spring 2021) </i>Understand how your tillage
                practices affect each of your fields profitability and
                see if a new plan is worth it.
              </li>
              <li>
                <i>(Spring 2021) </i>Understand how your and other
                local production practices impact the soil conditions
                and profitability.
              </li>
              <li>
                <i>(Spring 2021) </i>Understand the value of cover
                crops to each fields profitability and also how to
                determine if there use is worth the trouble.
              </li>
              <li>
                <i>(Spring 2021) </i>Understand the value of planting
                conditions and timing to your fields profitability.
              </li>
              <li>
                <i>(Spring 2021) </i>Determine the local value in
                profitability and know optimum application rate for
                Herbicides, Fungicides and other crop protectant use.
              </li>
              <li>
                <i>(Spring 2021) </i>Optimize the fertility plan
                (product, rate, method and timing) for savings and
                productivity.
              </li>
              <li>
                <i>(Summer 2021) </i>Understand the value of soil
                health and develop the most profitable plan for
                maintaining it.
              </li>
              <li>
                <i>(Summer 2021) </i>Use our coming growth and
                development trackers to project and field activities
                and crop needs for each field.
              </li>
              <li>
                <i>(Summer 2021) </i>Track the impact of soil
                temperature on crop emergence and vigor and project
                outcomes.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HowTo;
