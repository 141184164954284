const YieldColors = {
  1: "#d12602",
  2: "#FF5733",
  3: "#ff7933",
  4: "#ff8f33",
  5: "#ffbb1f",
  6: "ffdf0f",
  7: "#ffff00",
  8: "#ecf600",
  9: "#a7d300",
  10: "#d6eb00",
  11: "#5cae00",
  12: "#008000",
  13: "#015201"
};

const YieldEnvironmentColors_3 = {
  1: YieldColors[2],
  2: YieldColors[7],
  3: YieldColors[12]
};

const YieldEnvironmentColors_5 = {
  1: YieldColors[2],
  2: YieldColors[4],
  3: YieldColors[7],
  4: YieldColors[9],
  5: YieldColors[12]
};

const YieldEnvironmentColors_7 = {
  1: YieldColors[2],
  2: YieldColors[4],
  3: YieldColors[5],
  4: YieldColors[7],
  5: YieldColors[9],
  6: YieldColors[10],
  7: YieldColors[12]
};

const YieldEnvironmentColors_8 = {
  1: YieldColors[2],
  2: YieldColors[4],
  3: YieldColors[5],
  4: YieldColors[6],
  5: YieldColors[7],
  6: YieldColors[9],
  7: YieldColors[10],
  8: YieldColors[12]
};

export const getYieldEnvironmentColors = (count) => {
  switch (true) {
    case count <= 3:
      return YieldEnvironmentColors_3;
    case count <= 5:
      return YieldEnvironmentColors_5;
    case count <= 7:
      return YieldEnvironmentColors_7;
    case count <= 8:
      return YieldEnvironmentColors_8;
    default:
      return YieldColors;
  }
};

export const getColorMapping = (type, numberOfColors) => {
  switch (type) {
    case "yield":
      return getYieldEnvironmentColors(numberOfColors);
    default:
      return getYieldEnvironmentColors(numberOfColors);
  }
};
