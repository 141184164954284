// @flow

import React, { useState, useEffect } from "react";

import {
  Input,
  AlertBar,
  SliderModal,
  Header,
  FarmList,
  FieldsInFarm
} from "../../../components/";

import { CirclePicker } from "react-color";

type Props = {};

const ManageFarm = (props: Props) => {
  const [farmName, setFarmName] = useState(
    props.app.activeFarmName ? props.app.activeFarmName : ""
  );
  const [farmColor, setFarmColor] = useState(
    props.app.activeFarmColor ? props.app.activeFarmColor : ""
  );
  const [farmId, setFarmId] = useState(
    props.app.activeFarmId ? props.app.activeFarmId : ""
  );
  const [isOpen, setIsOpen] = useState(false);
  const [validationError, setValidationError] = useState(undefined);

  let manage_farms = props.farms.components.manage_farms;

  let get_farms = props.farms.components.get_farms;

  const { addFarm, updateFarm, deleteFarm } = props;
  const { activeFarmId, activeFarmName, activeFarmColor } = props.app;

  useEffect(() => {
    if (activeFarmId && (!farmId || activeFarmId !== farmId)) {
      setFarmName(activeFarmName ? activeFarmName : "");
      setFarmColor(activeFarmColor ? activeFarmColor : "");
      setFarmId(activeFarmId ? activeFarmId : "");
    } else if (!activeFarmId && (farmId || manage_farms.success)) {
      setFarmName("");
      setFarmColor("");
      setFarmId("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeFarmId,
    activeFarmName,
    activeFarmColor,
    manage_farms.success
  ]);

  return (
    <div>
      {validationError || manage_farms.error ? (
        <AlertBar>
          {validationError ? (
            <div>
              {validationError.split("\n").map((item) => {
                return <div>{item}</div>;
              })}
            </div>
          ) : null}
          {manage_farms.error}
        </AlertBar>
      ) : null}

      <form className="ProfileForm">
        <Header
          title="Farms"
          loading={manage_farms.loading || get_farms.loading}
        />

        <div
          style={{
            display: "grid",
            gridGap: "1em",
            gridTemplateColumns: "1fr 1fr"
          }}
        >
          <input
            type="button"
            value={"Add New Farm"}
            onClick={() => {
              setFarmId("");
              setFarmName("");
              setFarmColor("");
              setIsOpen(true);
            }}
          />
        </div>
      </form>

      <FarmList
        {...props}
        editFarm={(farmId, farmName, farmColor) => {
          setFarmId(farmId);
          setFarmName(farmName);
          setFarmColor(farmColor);
          setIsOpen(true);
        }}
        deleteFarm={(farmId, farmName) =>
          deleteFarm(farmId, farmName)
        }
      />

      <FieldsInFarm {...props} />

      <SliderModal
        title={activeFarmId ? "Edit Farm" : "Add New Farm"}
        isOpen={isOpen}
        onOutsideClick={() => setIsOpen(false)}
        footer={
          <div
            style={{
              width: "100%",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            <input
              type="button"
              value="Save"
              onClick={() => {
                setValidationError("");
                var validationMessage = "";

                if (!farmName || !farmColor) {
                  validationMessage =
                    validationMessage +
                    "\u2022 Farm name and color are required.\n";
                }

                if (validationMessage !== "") {
                  setValidationError(validationMessage);
                } else {
                  activeFarmId
                    ? updateFarm(farmId, farmName, farmColor)
                    : addFarm(farmName, farmColor);

                  setIsOpen(false);
                  setFarmColor(undefined);
                  setFarmId(undefined);
                  setFarmName(undefined);
                }
              }}
            />

            <input
              type="button"
              value="Cancel"
              onClick={() => setIsOpen(false)}
            />
          </div>
        }
      >
        <form>
          {validationError && (
            <AlertBar>
              {validationError ? (
                <div>
                  {validationError.split("\n").map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
              {/* {error} */}
            </AlertBar>
          )}
          <label>
            <span>Farm Name:</span>
            <Input
              name="farmName"
              type="text"
              value={farmName}
              onChange={(e) => setFarmName(e.target.value)}
            />
          </label>

          <label>
            <span>Farm Color Code:</span>
            <CirclePicker
              color={farmColor}
              width="368px"
              circleSize={27}
              circleSpacing={13}
              onChangeComplete={(color) => setFarmColor(color.hex)}
            />
          </label>
        </form>
      </SliderModal>
    </div>
  );
};

ManageFarm.defaultProps = {};
export default ManageFarm;
