// @flow

import React from "react";
import { Status } from "../../../components";
import { Loading } from "../../../components";

type Props = {
  health: Array
};

const HealthStatus = (props: Props) => {
  const { healthStatus } = props.user;
  const { loading } = props.user.components.health_status;

  return (
    <div className="HealthStatus">
      <header>
        <h2>Health Status</h2>
        {loading ? <Loading /> : null}
      </header>

      <ul>
        {healthStatus.map((status) => {
          return (
            <li
              key={status.fieldCount}
              // style={{ backgroundColor: status.color, color: status.textColor }}
            >
              <Status backgroundColor={status.color} />{" "}
              {status.fieldCount} field
              {status.fieldCount > 1 ? "s" : null} - {status.status}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

HealthStatus.defaultProps = {
  health: []
};
export default HealthStatus;
