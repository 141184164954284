import { connect } from "react-redux";
import TypeaheadInput from "./index";

import {
  getTypeaheadSuggestions,
  clearTypeaheadSuggestions
} from "../../redux/actions";

const mapStateToProps = (state) => {
  return {
    typeahead: state.typeahead
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTypeaheadSuggestions: (endpoint, typedValue) => {
      dispatch(getTypeaheadSuggestions(endpoint, typedValue));
    },
    clearTypeaheadSuggestions: (endpoint) => {
      dispatch(clearTypeaheadSuggestions(endpoint));
    }
  };
};

const TypeaheadInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TypeaheadInput);

export default TypeaheadInputContainer;
