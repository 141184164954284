import * as ACTION from "../constants";

export const pricesReducer = (
  state = {
    product_prices: [],
    unassigned_product_prices: [],
    years: [],
    components: {
      get_product_prices: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_product_prices: {
        loading: false,
        error: undefined,
        success: undefined
      }
    }
  },
  action
) => {
  switch (action.type) {
    case ACTION.GET_DATA_YEARS_SUCCESS:
      return {
        ...state,
        years: action.data.years
      };
    case ACTION.GET_PRODUCT_PRICES:
      return {
        ...state,
        components: {
          ...state.components,
          get_product_prices: {
            ...state.components.get_product_prices,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.GET_PRODUCT_PRICES_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_product_prices: {
            ...state.components.get_product_prices,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.GET_PRODUCT_PRICES_SUCCESS:
      return {
        ...state,
        product_prices: action.data.product_prices,
        components: {
          ...state.components,
          get_product_prices: {
            ...state.components.get_product_prices,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.GET_UNASSIGNED_PRODUCT_PRICES:
      return {
        ...state,
        components: {
          ...state.components,
          get_product_prices: {
            ...state.components.get_product_prices,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.GET_UNASSIGNED_PRODUCT_PRICES_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_product_prices: {
            ...state.components.get_product_prices,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.GET_UNASSIGNED_PRODUCT_PRICES_SUCCESS:
      return {
        ...state,
        unassigned_product_prices: action.data.product_prices,
        components: {
          ...state.components,
          get_product_prices: {
            ...state.components.get_product_prices,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.UPDATE_PRODUCT_PRICE:
    case ACTION.DELETE_PRODUCT_PRICE:
      return {
        ...state,
        components: {
          ...state.components,
          update_product_prices: {
            ...state.components.update_product_prices,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.UPDATE_PRODUCT_PRICE_FAIL:
    case ACTION.DELETE_PRODUCT_PRICE_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_product_prices: {
            ...state.components.update_product_prices,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.UPDATE_PRODUCT_PRICE_SUCCESS:
    case ACTION.DELETE_PRODUCT_PRICE_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_product_prices: {
            ...state.components.update_product_prices,
            loading: false,
            success: true
          }
        }
      };
    default:
      return state;
  }
};
