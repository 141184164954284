import { connect } from "react-redux";
import Modal from "./index";

import { dismissModal } from "../../redux/actions";

const mapStateToProps = (state) => {
  return {
    visible: state.app.modal.visible,
    message: state.app.modal.message,
    video: state.app.modal.video,
    confirmButtonLabel: state.app.modal.confirmButtonLabel,
    cancelButtonLabel: state.app.modal.cancelButtonLabel,
    onConfirm: state.app.modal.onConfirm,
    onCancel: state.app.modal.onCancel
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCancelAction: (action) => {
      dispatch(dismissModal());
      if (action) {
        dispatch(action());
      }
    },
    onConfirmAction: (action) => {
      dispatch(dismissModal());
      if (action) {
        dispatch(action());
      }
    }
  };
};
const ModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);

export default ModalContainer;
