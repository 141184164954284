import * as ACTION from "../constants";

export const seedsReducer = (
  state = {
    seeds: [],
    seed_companies: [],
    components: {
      get_seeds: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_seeds: {
        loading: false,
        error: undefined,
        success: undefined
      }
    }
  },
  action
) => {
  switch (action.type) {
    case ACTION.GET_SEED_COMPANIES:
    case ACTION.GET_SEEDS_BY_COMPANY:
      return {
        ...state,
        components: {
          ...state.components,
          get_seeds: {
            ...state.components.get_seeds,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.GET_SEED_COMPANIES_FAIL:
    case ACTION.GET_SEEDS_BY_COMPANY_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_seeds: {
            ...state.components.get_seeds,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.GET_SEED_COMPANIES_SUCCESS:
      return {
        ...state,
        seed_companies: action.data.seed_companies,
        components: {
          ...state.components,
          get_seeds: {
            ...state.components.get_seeds,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.GET_SEEDS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        seeds: action.data.seed_list,
        components: {
          ...state.components,
          get_seeds: {
            ...state.components.get_seeds,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.UPDATE_MANAGED_SEED:
      return {
        ...state,
        components: {
          ...state.components,
          update_seeds: {
            ...state.components.update_seeds,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.UPDATE_MANAGED_SEED_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_seeds: {
            ...state.components.update_seeds,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.UPDATE_MANAGED_SEED_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_seeds: {
            ...state.components.update_seeds,
            loading: false,
            success: true
          }
        }
      };
    default:
      return state;
  }
};
