// @flow

import React from "react";
import Section from "./Section";
//import { Carousel } from "react-responsive-carousel";
import { ReactComponent as Logo } from "../../../assets/mfi-smooth.svg";
//import { navigate } from "react-router";

// import image1 from "../../../assets/img/corn-3665250.jpg";
// import image2 from "../../../assets/img/field-1521332.jpg";
//import image3 from "../../../assets/img/sunset-241806.jpg";

const Home = (props: Props) => {
  return (
    <Section
      id="summary"
      onVisibilityChange={props.onVisibilityChange}
    >
      <div className="HeaderHome">
        {/* <img className="WelcomeBanner" src={image3} alt="Cornfield" /> */}

        <div className="HeaderText">
          <Logo className="Logo" />
          <span>Tools for the modern farm.</span>
          <span>Farmer built and directed.</span>
          <span>
            <a className="LinkButton" href="#getstarted">
              Learn more
            </a>
          </span>
        </div>
      </div>
    </Section>
  );
};

export default Home;
