// @flow
import React, { Component } from "react";

import "./styles.scss";

import ReactModal from "react-modal";

import VideoPlayer from "../VideoPlayer";

class Modal extends Component {
  componentDidMount() {
    ReactModal.setAppElement("body");
  }

  render() {
    const {
      visible,
      message,
      video,
      onConfirm,
      onCancel,
      confirmButtonLabel,
      cancelButtonLabel,
      onConfirmAction,
      onCancelAction
    } = this.props;

    return (
      <ReactModal
        isOpen={visible}
        onRequestClose={
          message || video
            ? () => onCancelAction(onCancel)
            : () => console.log("accept the terms to continue")
        }
        contentLabel={message}
        className="Modal"
        overlayClassName="ModalOverlay"
      >
        {video && (
          <VideoPlayer src={video} autoplay={true} controls={true} />
        )}

        {!video && message && <h2> {message} </h2>}

        {!video && !message && (
          <h2>
            {" "}
            I agree to the{" "}
            <a target="_blank" href="/ndaagreement.html">
              Confidentiality Agreement
            </a>
            ,{" "}
            <a target="_blank" href="/termsandconditions.html">
              Terms and Conditions
            </a>
            , and{" "}
            <a target="_blank" href="/privacypolicy.html">
              Privacy Policy.
            </a>
          </h2>
        )}

        {!video && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around"
            }}
          >
            {cancelButtonLabel && (
              <input
                type="button"
                onClick={() => onCancelAction(onCancel)}
                value={cancelButtonLabel}
              />
            )}
            <input
              type="button"
              onClick={() => onConfirmAction(onConfirm)}
              value={confirmButtonLabel}
            />
          </div>
        )}
      </ReactModal>
    );
  }
}

Modal.defaultProps = {};

export default Modal;
