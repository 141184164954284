// @flow

import React, { useState } from "react";
import { PageWithMap } from "../../components";

import { ManageFarm, ManageField } from "./components/";

import "./styles.scss";

import { isMobile } from "react-device-detect";

type Props = {};

const Farms = (props: Props) => {
  const [boundaryToMerge, setBoundaryToMerge] = useState(undefined);

  return (
    <PageWithMap
      title="farms"
      boundaryToMerge={boundaryToMerge}
      selectFieldMerge={(boundary) => {
        setBoundaryToMerge(boundary);
      }}
      {...props}
    >
      <div
        className={`FarmsPageContent ${
          isMobile ? "mobile" : "browser"
        }`}
      >
        <ManageFarm {...props} />

        <ManageField {...props} boundaryToMerge={boundaryToMerge} />
      </div>
    </PageWithMap>
  );
};

Farms.defaultProps = {};
export default Farms;
