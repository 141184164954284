// @flow

import React from "react";

import { getColorMapping, sortYieldEnvs } from "../../../../../utils";
import { Status, HeatmapLegend } from "../../../../../components";

type Props = {};

const Removals = (props: Props) => {
  const { layer } = props;

  const colorMapping = getColorMapping(
    layer,
    layer.data.yield_env_removal_rates.length
  );
  return (
    <React.Fragment>
      <HeatmapLegend
        style={{ display: "grid", gridTemplateColumns: "1fr" }}
      >
        <div
          className="HeatmapLegendTitle"
          style={{
            display: "grid",
            gridTemplateColumns: "2em 1fr 1fr 1fr 1fr"
          }}
        >
          <span />
          <span>K2O </span>
          <span>N </span>
          <span>P2O5 </span>
          <span>S </span>
        </div>
        {(
          layer.name === "removals" &&
          layer.data.yield_env_removal_rates
        )
          .sort(sortYieldEnvs)
          .map((item) => (
            <div
              key={item.yield_env}
              style={{
                display: "grid",
                gridTemplateColumns: "2em 1fr 1fr 1fr 1fr"
              }}
            >
              <Status
                backgroundColor={colorMapping[item.yield_env]}
              />
              <span>{item.K2O} </span>
              <span>{item.N} </span>
              <span>{item.P2O5} </span>
              <span>{item.S} </span>
            </div>
          ))}
      </HeatmapLegend>
      <span style={{ fontStyle: "italic" }}>
        Removals given in lbs per acre. Negative numbers refer to the
        amount removed in harvest.
      </span>
    </React.Fragment>
  );
};

Removals.defaultProps = {};
export default Removals;
