// @flow

import React, { useState, useEffect } from "react";

import ReactTooltip from "react-tooltip";

import { Header, FieldCard } from "../../../components";

type Props = {};

const FileStatus = (props: Props) => {
  const { fields, years } = props.files;
  const { getFileYears, getFileStatusByYear } = props;
  const { loading } = props.files.components.get_file_status;
  const processRunning =
    props.files.components.delete_file.loading ||
    props.files.components.reprocess_file.loading ||
    props.files.components.reprocess_seed_fit.loading ||
    props.files.components.validate_seeds.loading;
  const [selectedYear, setSelectedYear] = useState(undefined);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);

  useEffect(() => {
    if (selectedYear) {
      getFileStatusByYear(selectedYear);
    } else if (years && years[0]) {
      //Load first year by default
      setSelectedYear(years[0]);
    } else {
      getFileYears();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years, selectedYear, getFileYears, getFileStatusByYear]);

  return (
    <div className="RightPanelContent">
      <div className="FileStatusWrapper">
        <ReactTooltip
          id="FileStatusTooltip"
          border={true}
          borderColor="#f5d870"
          className="Tooltip"
        />

        <Header
          title="Manage My Files"
          loading={loading || processRunning}
        />

        {/* <input
                type="button"
                value="Reprocess all shapefiles"
                onClick={() => {
                }}
            /> */}

        {/* <input
                    type="button"
                    value="Validate all seeds"
                    disabled={processRunning}
                    onClick={() => validateSeeds()}
                /> */}

        {/* <input
                type="button"
                value="Reprocess all seed fit simulations"
                onClick={() => {
                }}
            /> */}

        <div className="DataManagement">
          <form>
            <label>
              <span>Year:</span>
              <select
                onChange={(evt) => {
                  getFileStatusByYear(evt.target.value);
                  setSelectedYear(evt.target.value);
                }}
              >
                {years &&
                  years
                    .sort(function (a, b) {
                      return b - a;
                    })
                    .map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
              </select>
            </label>
          </form>
          {fields &&
            fields
              .sort(function (a, b) {
                var textA = a.field_name.toUpperCase();
                var textB = b.field_name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((field, index) => {
                return (
                  <div
                    className="Field"
                    key={field.field_id + " " + index}
                  >
                    <FieldCard
                      selectedYear={selectedYear}
                      field={field}
                      {...props}
                    />
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

FileStatus.defaultProps = {};
export default FileStatus;
