// @flow

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import {
  Header,
  Input,
  TypeaheadInput,
  AlertBar,
  SliderModal
} from "../../../components";
import typeaheadOptions from "../../../redux/utils/typeaheadOptions.js";

type Props = {};

const sortAlphabetical = (field) => {
  return (a, b) => {
    var textA = a && a[field] ? a[field].toUpperCase() : "";
    var textB = b && b[field] ? b[field].toUpperCase() : "";
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  };
};

const PriceDataManagement = (props: Props) => {
  const { mode } = props;
  const { years, product_prices } = props.prices;

  const {
    getDataYears,
    getProductPrices,
    updateProductPrice,
    deleteProductPrice
  } = props;
  const { loading } =
    props.admin.components.get_all_users ||
    props.admin.components.update_user;

  const [isOpen, setIsOpen] = useState(false);
  const [productPriceId, setProductPriceId] = useState("");
  const [productType, setProductType] = useState("");
  const [productName, setProductName] = useState("");
  const [inputProductName, setInputProductName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [pricePerUnit, setPricePerUnit] = useState("");
  const [purchasingUnit, setPurchasingUnit] = useState("");
  const [selectedYear, setSelectedYear] = useState(undefined);
  const [validationError, setValidationError] = useState("");
  const [sortFunction, setSortFunction] = useState(() =>
    sortAlphabetical("name")
  );

  var unit_options;
  var product_type_for_mode;
  switch (mode) {
    case "seed":
      unit_options = ["bag", "1000 seeds"];
      product_type_for_mode = "seed";
      break;

    case "commodity":
      unit_options = ["bushel"];
      product_type_for_mode = "commodity";
      break;
    default:
      unit_options = ["gal", "qt", "pt", "oz", "lbs", "ton"];
      product_type_for_mode = "product";
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (selectedYear) {
      //TODO: Get Seed Prices
      getProductPrices(selectedYear, mode);
    } else if (years && years[0]) {
      //Load first year by default
      setSelectedYear(years[0]);
    } else {
      getDataYears();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years, selectedYear, getDataYears, getProductPrices, mode]);
  return (
    <div className="RightPanelContent">
      <ReactTooltip
        id="AccountManagementTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />

      <Header title="Manage Price Information" loading={loading} />

      {product_prices && (
        <div className="DataManagement">
          <form>
            <label>
              <span>Year:</span>
              <select
                onChange={(evt) => {
                  getProductPrices(evt.target.value, mode);
                  setSelectedYear(evt.target.value);
                }}
              >
                {years &&
                  years
                    .sort(function (a, b) {
                      return b - a;
                    })
                    .map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
              </select>
            </label>
          </form>
          <input
            type="button"
            value={`Add ${mode} price`}
            onClick={() => {
              setProductPriceId(null);
              setProductType(product_type_for_mode);
              setProductName("");
              setInputProductName("");
              setSupplierName("");
              setPricePerUnit("");
              setPurchasingUnit("");
              setIsOpen(true);
            }}
          />
          <table>
            <thead>
              <tr>
                <th style={{ width: "1em" }}></th>
                <th style={{ width: "1em" }}></th>
                <th
                  className="Link"
                  data-tip="Sort by Name"
                  data-for="AccountManagementTooltip"
                  onClick={() =>
                    setSortFunction(() =>
                      sortAlphabetical("product_name")
                    )
                  }
                >
                  {" "}
                  Product Name{" "}
                </th>
                <th
                  className="Link"
                  data-tip="Sort by Supplier"
                  data-for="AccountManagementTooltip"
                  onClick={() =>
                    setSortFunction(() =>
                      sortAlphabetical("supplier_name")
                    )
                  }
                >
                  {" "}
                  Supplier{" "}
                </th>
                <th> Price/Unit </th>
                <th> Purchasing Unit </th>
              </tr>
            </thead>
            <tbody>
              {product_prices
                .sort(sortFunction)
                .map((product, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <FontAwesomeIcon
                          icon="pencil-alt"
                          className="ActionIcon"
                          data-tip="Edit line item"
                          data-for="AccountManagementTooltip"
                          onClick={() => {
                            setIsOpen(true);
                            setProductType(product.product_type);
                            setProductPriceId(
                              product.product_price_id
                            );
                            setProductName(
                              typeaheadOptions[
                                `${product.product_type}_products`
                              ] &&
                                typeaheadOptions[
                                  `${product.product_type}_products`
                                ].includes(product.product_name)
                                ? product.product_name
                                : "other"
                            );
                            setInputProductName(product.product_name);
                            setSupplierName(product.supplier_name);
                            setPricePerUnit(product.price_per_unit);
                            setPurchasingUnit(
                              product.purchasing_unit
                            );
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon="trash-alt"
                          className="ActionIcon"
                          size="1x"
                          onClick={() =>
                            deleteProductPrice(product, mode)
                          }
                          data-tip="Delete Product Price"
                          data-for="DataManagementTooltip"
                        />
                      </td>

                      <td>{product.product_name}</td>
                      <td>{product.supplier_name}</td>
                      <td>
                        $
                        {product.price_per_unit
                          ? product.price_per_unit.toFixed(2)
                          : ""}
                      </td>
                      <td>{product.purchasing_unit}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      <SliderModal
        title="Update Prices"
        isOpen={isOpen}
        onOutsideClick={() => setIsOpen(false)}
        footer={
          <div
            style={{
              width: "100%",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            <input
              type="button"
              value="Update"
              onClick={() => {
                setValidationError("");
                var validationMessage = "";

                //TODO: Validation
                if (
                  !productType ||
                  !productName ||
                  !pricePerUnit ||
                  !purchasingUnit
                ) {
                  validationMessage =
                    "Product name, price per unit, and purchasing unit are required.  Please fill out all the input fields to continue.";
                }

                if (validationMessage !== "") {
                  setValidationError(validationMessage);
                } else {
                  var product = {
                    product_price_id: productPriceId,
                    product_name:
                      productName !== "other"
                        ? productName
                        : inputProductName,

                    supplier_name: supplierName,
                    price_per_unit: pricePerUnit,
                    purchasing_unit: purchasingUnit,
                    product_type: productType,
                    year: selectedYear
                  };

                  //TODO: Update seed price function
                  updateProductPrice(product, mode);
                  setIsOpen(false);
                  setProductPriceId("");
                  setProductName("");
                  setInputProductName("");
                  setSupplierName("");
                  setPricePerUnit("");
                  setPurchasingUnit("");
                }
              }}
            />

            <input
              type="button"
              value="Cancel"
              onClick={() => setIsOpen(false)}
            />
          </div>
        }
      >
        <form>
          {validationError && (
            <AlertBar>
              {validationError ? (
                <div>
                  {validationError.split("\n").map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
              {/* {error} */}
            </AlertBar>
          )}

          {mode === "seed" ? (
            <label>
              <span>Seed Name:</span>

              <TypeaheadInput
                name="newSeedName"
                type="text"
                endpoint={"managed_seeds"}
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </label>
          ) : mode === "commodity" ? (
            <React.Fragment>
              <label>
                <span>Commodity Name:</span>

                <select
                  value={productName}
                  onChange={(evt) => {
                    setProductName(
                      evt.target.value === "Select"
                        ? undefined
                        : evt.target.value
                    );
                  }}
                >
                  <option key={undefined} value={undefined}>
                    Select
                  </option>

                  {typeaheadOptions[`commodity_products`] &&
                    typeaheadOptions[`commodity_products`].map(
                      (product) => {
                        return (
                          <option key={product} value={product}>
                            {product}
                          </option>
                        );
                      }
                    )}

                  <option key="other" value="other">
                    other
                  </option>
                </select>
              </label>

              {productName === "other" && (
                <label>
                  <span>Enter Commodity Name:</span>

                  <Input
                    name="inputProductName"
                    type="text"
                    value={inputProductName}
                    onChange={(e) =>
                      setInputProductName(e.target.value)
                    }
                  />
                </label>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <label>
                <span>Product Type:</span>
                <select
                  value={productType}
                  onChange={(evt) => {
                    setProductType(evt.target.value);
                  }}
                >
                  <option key={null} value={null}>
                    Select
                  </option>
                  {[
                    "fertilizer",
                    "herbicide",
                    "insecticide",
                    "fungicide",
                    "nematocide",
                    "amendment",
                    "additive"
                  ]
                    .sort()
                    .map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
                </select>
              </label>

              {/* <label>
                <span>Product Name:</span>

                <TypeaheadInput
                  name="newSeedName"
                  type="text"
                  endpoint={
                    productType === "fertilizer"
                      ? "fertilizer_products"
                      : productType === "fungicide"
                      ? "fungicide_products"
                      : ""
                  }
                  value={productName}
                  onChange={e => setProductName(e.target.value)}
                />
              </label> */}

              <label>
                <span>Product Name:</span>

                <select
                  value={productName}
                  onChange={(evt) => {
                    setProductName(
                      evt.target.value === "Select"
                        ? undefined
                        : evt.target.value
                    );
                  }}
                >
                  <option key={undefined} value={undefined}>
                    Select
                  </option>

                  {typeaheadOptions[`${productType}_products`] &&
                    typeaheadOptions[`${productType}_products`]
                      .sort()
                      .map((product) => {
                        return (
                          <option key={product} value={product}>
                            {product}
                          </option>
                        );
                      })}

                  <option key="other" value="other">
                    other
                  </option>
                </select>
              </label>

              {productName === "other" && (
                <label>
                  <span>Enter Product Name:</span>

                  <Input
                    name="inputProductName"
                    type="text"
                    value={inputProductName}
                    onChange={(e) =>
                      setInputProductName(e.target.value)
                    }
                  />
                </label>
              )}
            </React.Fragment>
          )}
          {mode !== "commodity" && (
            <label>
              <span>Supplier:</span>

              <Input
                name="supplierName"
                type="text"
                value={supplierName}
                onChange={(e) => setSupplierName(e.target.value)}
              />
            </label>
          )}

          <label>
            <span>Price per unit:</span>

            <Input
              name="price_per_unit"
              type="text"
              mask="[000000000].00"
              value={pricePerUnit}
              onChange={(e) => setPricePerUnit(e.target.value)}
            />
          </label>

          <label>
            <span>Purchasing Unit:</span>
            <select
              value={purchasingUnit}
              onChange={(evt) => {
                setPurchasingUnit(evt.target.value);
              }}
            >
              <option key={null} value={null}>
                Select
              </option>

              {unit_options.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </label>
        </form>
      </SliderModal>
    </div>
  );
};

PriceDataManagement.defaultProps = {};
export default PriceDataManagement;
