// @flow

import React from "react";
import { /*Router,*/ useLocation, useNavigate } from "react-router";

import "./styles.scss";

import { Toolbox, PageWithMenu } from "../../components";
import {
  SeedDataManagement,
  FileStatus,
  SeedDealerDataManagement,
  AdminManageAccounts,
  PriceDataManagement,
  FieldPracticeDataManagement
} from "./components";

import { hasAppAttribute } from "../../entitlements";
import * as APP_ATTRIBUTE from "../../entitlements/appAttributes";

type Props = {};

const DataManagement = (props: Props) => {
  const { user } = props;
  const location = useLocation();
  const navigate = useNavigate();

  var Form;
  var inputProps;
  switch (location.pathname) {
    case "/app/data/seeds":
      Form = SeedDataManagement;
      inputProps = { ...props };
      break;
    case "/app/data/files":
      Form = FileStatus;
      inputProps = { ...props };
      break;
    case "/app/data/seed_prices":
      Form = PriceDataManagement;
      inputProps = { ...props, mode: "seed" };
      break;
    case "/app/data/commodity_prices":
      Form = PriceDataManagement;
      inputProps = { ...props, mode: "commodity" };
      break;
    case "/app/data/product_prices":
      Form = PriceDataManagement;
      inputProps = { ...props, mode: "input" };
      break;
    case "/app/data/practices":
      Form = FieldPracticeDataManagement;
      inputProps = { ...props };
      break;
    case "/app/data/seed_dealer":
      Form = SeedDealerDataManagement;
      inputProps = { ...props };
      break;
    case "/app/data/manage_users":
      Form = AdminManageAccounts;
      inputProps = { ...props };
      break;
    default:
      Form = FileStatus;
      inputProps = { ...props };
      break;
  }

  return (
    <PageWithMenu title="data management">
      <Toolbox title="data management">
        <div>I want to...</div>

        <div
          className={`AccountTool ${
            location.pathname.includes("seeds") ? "active" : ""
          }`}
          onClick={() => navigate("/app/data/seeds")}
        >
          Manage My Planted Seed Data
        </div>

        <div
          className={`AccountTool ${
            location.pathname.includes("files") ? "active" : ""
          }`}
          onClick={() => navigate("/app/data/files")}
        >
          Manage My Files
        </div>

        <div
          className={`AccountTool ${
            location.pathname.includes("seed_prices") ? "active" : ""
          }`}
          onClick={() => navigate("/app/data/seed_prices")}
        >
          Manage My Seed Prices
        </div>
        <div
          className={`AccountTool ${
            location.pathname.includes("commodity_prices")
              ? "active"
              : ""
          }`}
          onClick={() => navigate("/app/data/commodity_prices")}
        >
          Manage My Commodity Prices
        </div>

        <div
          className={`AccountTool ${
            location.pathname.includes("product_prices")
              ? "active"
              : ""
          }`}
          onClick={() => navigate("/app/data/product_prices")}
        >
          Manage My Input Product Prices
        </div>

        <div
          className={`AccountTool ${
            location.pathname.includes("practices") ? "active" : ""
          }`}
          onClick={() => navigate("/app/data/practices")}
        >
          Manage My Farming Practices
        </div>

        {hasAppAttribute(APP_ATTRIBUTE.MANAGE_SEED_DATA, user) && (
          <div
            className={`AccountTool ${
              location.pathname.includes("seed_dealer")
                ? "active"
                : ""
            }`}
            onClick={() => navigate("/app/data/seed_dealer")}
          >
            Manage Seed Dealer Data
          </div>
        )}

        {hasAppAttribute(APP_ATTRIBUTE.MANAGE_ACCOUNTS, user) && (
          <div
            className={`AccountTool ${
              location.pathname.includes("manage_users")
                ? "active"
                : ""
            }`}
            onClick={() => navigate("/app/data/manage_users")}
          >
            Manage User Accounts (Admin)
          </div>
        )}
      </Toolbox>
      <div className="AccountWrapper">
        {/*TODO: Fix router */}
        {/* <Router>
          <MyReferrals path="/invite" {...props} />
          <LoginInfo path="/login" {...props} />
          <AccountSettings path="/settings" {...props} />
          <DataManagement path="/seeds" {...props} />
          <FileStatus path="/files" {...props} />
        </Router> */}
        <Form {...inputProps} />
      </div>
    </PageWithMenu>
  );
};

DataManagement.defaultProps = {};
export default DataManagement;
