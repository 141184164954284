// @flow

import React, { useState } from "react";
import "./styles.scss";

import {
  MapContainer as LeafletMap,
  TileLayer,
  ImageOverlay,
  useMapEvents,
  useMap,
  LayersControl
} from "react-leaflet";

import { Loading } from "../";
import { hereTileUrl } from "./here";

import { GeoJSONLayer, FieldsSummaryLayer } from "./components";

import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";

import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const MapEventListener = (props) => {
  const { setZoom, onOverlayAdd, onOverlayRemove } = props;

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapEvents.getZoom());
    },
    overlayadd: (e) => {
      // console.log("add:" + e.name);
      onOverlayAdd(e.name);
    },
    overlayremove: (e) => {
      // console.log("remove:" + e.name);
      onOverlayRemove(e.name);
    }
  });
  return null;
};

function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

const Map = (props) => {
  const {
    loading,
    fields,
    activeFieldId,
    center,
    zoom,
    setZoom,
    selectField,
    selectFieldMerge,
    boundaryToMerge
  } = props;

  const { summary, detail } = fields;

  const [layers, setLayers] = useState({});

  return (
    <div className="Map" name="Map">
      <div id="mapid" />

      <LeafletMap
        center={center}
        zoom={zoom}
        useFlyTo={true}
        boundsOptions={{ padding: [50, 50] }}
      >
        {loading && <Loading />}

        <MapEventListener
          setZoom={setZoom}
          onOverlayAdd={(name) =>
            setLayers({ ...layers, [name]: true })
          }
          onOverlayRemove={(name) =>
            setLayers({ ...layers, [name]: false })
          }
        />
        <ChangeView center={center} zoom={zoom} />
        <LayersControl
          collapsed={true}
          sortLayers={true}
          hideSingleBase={true}
          autoZIndex={true}
        >
          <LayersControl.BaseLayer checked name="Satellite">
            <TileLayer attribution="HERE" url={hereTileUrl} />
          </LayersControl.BaseLayer>
          {summary && true ? (
            <LayersControl.Overlay checked name="Fields List">
              <FieldsSummaryLayer
                data={summary}
                {...props}
                key="fields_summary"
              />
            </LayersControl.Overlay>
          ) : null}
          {/*<LayersControl.Overlay checked name={"soil test"}>
            <ImageOverlay
              url="/big_top_soil_test.jpg"
              bounds={[
                [41.026396728099, -90.065673491062],
                [41.029112278855, -90.062073803805]
              ]}
              opacity={1}
              zIndex={10}
            />
            </LayersControl.Overlay>*/}

          {activeFieldId &&
            detail[activeFieldId] &&
            detail[activeFieldId].geospatial_layers &&
            Object.keys(detail[activeFieldId].geospatial_layers).map(
              (layer, index) => {
                if (
                  detail[activeFieldId].geospatial_layers[layer]
                    .sublayers
                ) {
                  return (
                    <React.Fragment>
                      {Object.keys(
                        detail[activeFieldId].geospatial_layers[layer]
                          .sublayers
                      ).map((sublayer) => {
                        var layerObject =
                          detail[activeFieldId].geospatial_layers[
                            layer
                          ].sublayers[sublayer];

                        if (layerObject.content_type === "png") {
                          return (
                            <LayersControl.Overlay
                              checked={layerObject.showOnMap}
                              name={sublayer}
                            >
                              <ImageOverlay
                                url={layerObject.data}
                                /*  bounds={[
                                  [41.026396728099, -90.065673491062],
                                  [41.029112278855, -90.062073803805]
                                ]} */
                                bounds={layerObject.bounds}
                                opacity={layerObject.opacity / 100}
                              />
                            </LayersControl.Overlay>
                          );
                        } else {
                          //Geojson
                          if (layerObject.data) {
                            return (
                              <LayersControl.Overlay
                                checked={layerObject.showOnMap}
                                name={`${layerObject.display_name}  ${
                                  layerObject.year
                                    ? `-${layerObject.year}`
                                    : ""
                                }`}
                              >
                                <GeoJSONLayer
                                  id={layer}
                                  layer={layerObject}
                                  data={layerObject.data}
                                  key={layer}
                                  opacity={layerObject.opacity}
                                  onClick={(center, properties) => {
                                    if (layer === "nearby_fields") {
                                      selectFieldMerge(properties);
                                    } else if (properties.field_id) {
                                      selectField(
                                        properties.field_name,
                                        properties.field_id,
                                        center,
                                        properties.boundary_locked
                                      );
                                    }
                                  }}
                                  {...props}
                                />{" "}
                              </LayersControl.Overlay>
                            );
                          }
                        }
                      })}
                    </React.Fragment>
                  );
                }

                return null;
              }
            )}
        </LayersControl>
      </LeafletMap>
    </div>
  );
};

export default Map;
