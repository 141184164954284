import * as ACTION from "../constants";

export const adminReducer = (
  state = {
    users: [],
    components: {
      get_all_users: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_user: {
        loading: false,
        error: undefined,
        success: undefined
      },
      delete_user: {
        loading: false,
        error: undefined,
        success: undefined
      }
    }
  },
  action
) => {
  switch (action.type) {
    case ACTION.ADMIN_GET_ALL_USERS:
      return {
        ...state,
        components: {
          ...state.components,
          get_all_users: {
            ...state.components.get_all_users,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.ADMIN_GET_ALL_USERS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_all_users: {
            ...state.components.get_all_users,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.ADMIN_GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.data.users,
        components: {
          ...state.components,
          get_all_users: {
            ...state.components.get_all_users,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.ADMIN_UPDATE_USER:
      return {
        ...state,
        components: {
          ...state.components,
          update_user: {
            ...state.components.update_user,
            loading: true,
            error: undefined,
            success: undefined
          }
        }
      };
    case ACTION.ADMIN_UPDATE_USER_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_user: {
            ...state.components.update_user,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.ADMIN_UPDATE_USER_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_user: {
            ...state.components.update_user,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.ADMIN_DELETE_USER:
      return {
        ...state,
        components: {
          ...state.components,
          delete_user: {
            ...state.components.delete_user,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.ADMIN_DELETE_USER_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          delete_user: {
            ...state.components.delete_user,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.ADMIN_DELETE_USER_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          delete_user: {
            ...state.components.delete_user,
            error: action.error,
            loading: false
          }
        }
      };
    default:
      return state;
  }
};
