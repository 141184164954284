// @flow

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import {
  Header,
  Input,
  AlertBar,
  SliderModal
} from "../../../components";

import { validateEmail, validatePhone } from "../../../utils";

type Props = {};

const MyReferrals = (props: Props) => {
  const { referrals /*total_acres*/ } = props.user;
  const { inviteUser, getReferrals, resendInvite } = props;
  const { loading } = props.user.components.get_referrals;

  const [isOpen, setIsOpen] = useState(false);
  const [inviteeName, setInviteeName] = useState("");
  const [inviteePhone, setInviteePhone] = useState("");
  const [inviteeEmail, setInviteeEmail] = useState("");
  const [inviteeMessage, setInviteeMessage] = useState("");
  const [validationError, setValidationError] = useState("");

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    getReferrals();
  }, [getReferrals]);

  return (
    <div className="RightPanelContent">
      <ReactTooltip
        id="ReferralTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />

      <Header title="My Referrals" loading={loading} />
      {/* <span className="TotalAcres">
        <span>My total acres:</span>
        <span>{total_acres}</span>
      </span> */}

      <input
        type="button"
        value="Invite a Friend"
        onClick={() => setIsOpen(true)}
      />
      {referrals && (
        <div className="DataManagement">
          <table>
            <thead>
              <tr>
                <th style={{ width: "1em" }}></th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                {/* <td>Acres</td> */}
                <th>Invitation Status</th>
                <th>Invitation Date</th>
                <th>Join Date</th>
              </tr>
            </thead>
            <tbody>
              {referrals
                .sort(function (a, b) {
                  var textA = a.name.toUpperCase();
                  var textB = b.name.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                })
                .map((user) => {
                  var inviteStatus;

                  switch (user.role) {
                    case "demo_user":
                      if (user.has_accepted_terms) {
                        inviteStatus = "Logged In";
                      } else {
                        inviteStatus = "Invite Sent";
                      }
                      break;
                    case "nda_user":
                      inviteStatus = "NDA Signed";
                      break;
                    case "farmer":
                    case "tech_farmer":
                    case "seed_dealer_farmer":
                    case "scientist":
                    case "key_collaborator":
                    case "ag_professional":
                    case "farmer_supporter":
                    case "admin":
                      inviteStatus = "Member";
                      break;
                    default:
                      inviteStatus = "Unknown";
                  }

                  var timeDiff =
                    new Date() - Date.parse(user.invited_on);
                  timeDiff /= 1000; //get seconds
                  timeDiff /= 3600; //minutes / hours
                  timeDiff /= 24; //days
                  var tokenValid = timeDiff < 7;

                  return (
                    <tr key={user.email}>
                      <td>
                        {inviteStatus !== "Member" && (
                          <FontAwesomeIcon
                            icon="paper-plane"
                            className="ActionIcon"
                            data-tip="Send Invite Reminder"
                            data-for="ReferralTooltip"
                            onClick={() => resendInvite(user.email)}
                          />
                        )}
                      </td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      {/* <td>{user.acres}</td> */}
                      <td
                        style={{
                          fontStyle:
                            inviteStatus !== "Member" && !tokenValid
                              ? "italic"
                              : "normal"
                        }}
                      >
                        {inviteStatus}
                      </td>
                      <td
                        style={{
                          fontStyle:
                            inviteStatus !== "Member" && !tokenValid
                              ? "italic"
                              : "normal"
                        }}
                      >
                        {user.invited_on}
                      </td>
                      <td>{user.activated_on}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      <SliderModal
        title="Invite a Friend"
        isOpen={isOpen}
        onOutsideClick={() => setIsOpen(false)}
        footer={
          <div
            style={{
              width: "100%",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            <input
              type="button"
              value="Send Invite"
              onClick={() => {
                setValidationError("");
                var validationMessage = "";

                var validateEmailResult = validateEmail(inviteeEmail);
                if (!validateEmailResult.status) {
                  validationMessage =
                    validationMessage + validateEmailResult.message;
                }
                var validatePhoneResult = validatePhone(inviteePhone);
                if (!validatePhoneResult.status) {
                  validationMessage =
                    validationMessage + validatePhoneResult.message;
                }

                if (validationMessage !== "") {
                  setValidationError(validationMessage);
                } else {
                  inviteUser(
                    inviteeName,
                    inviteeEmail,
                    inviteePhone,
                    inviteeMessage
                  );
                  setIsOpen(false);
                  setInviteeEmail("");
                  setInviteeMessage("");
                  setInviteeName("");
                  setInviteePhone("");
                }
              }}
            />

            <input
              type="button"
              value="Cancel"
              onClick={() => setIsOpen(false)}
            />
          </div>
        }
      >
        <form>
          {validationError && (
            <AlertBar>
              {validationError ? (
                <div>
                  {validationError.split("\n").map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
              {/* {error} */}
            </AlertBar>
          )}
          <label>
            <span>Name:</span>

            <Input
              name="name"
              type="text"
              value={inviteeName}
              onChange={(e) => setInviteeName(e.target.value)}
            />
          </label>
          <label>
            <span>Email:</span>

            <Input
              name="email"
              type="email"
              value={inviteeEmail}
              onChange={(e) => setInviteeEmail(e.target.value)}
            />
          </label>

          <label>
            <span>Phone:</span>

            <Input
              name="phone"
              type="text"
              mask="+1 (000) 000-0000"
              value={inviteePhone}
              onChange={(e) => setInviteePhone(e.target.value)}
            />
          </label>
          <label style={{ height: "10em" }}>
            <span>Message:</span>

            <textarea
              row="5"
              name="message"
              value={inviteeMessage}
              onChange={(e) => setInviteeMessage(e.target.value)}
            />
          </label>
        </form>
      </SliderModal>
    </div>
  );
};

MyReferrals.defaultProps = {};
export default MyReferrals;
