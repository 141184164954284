// @flow

import React from "react";

import Dropzone from "react-dropzone";
import { Loading } from "../../../components";

type Props = { handleUpload: Function };

const FileUpload = (props: Props) => {
  const { fileUploading, showToast } = props;
  return (
    <div className="FileUpload">
      <header>
        <h2>Upload Files</h2>
      </header>
      <Dropzone
        disabled={fileUploading}
        multiple={false}
        maxSize={104857600}
        accept="application/zip,application/x-zip-compressed"
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length > 0)
            props.handleUpload(acceptedFiles);
        }}
        onDropRejected={(fileRejections) => {
          fileRejections.map((fileRejection) =>
            showToast(
              `file ${fileRejection.file.name} could not be uploaded: ${fileRejection.errors[0].message}`
            )
          );
        }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div {...getRootProps()} className={"FileDropzone"}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop files here...</p>
              ) : (
                <React.Fragment>
                  {!fileUploading && (
                    <p>
                      Try dropping some zip files here, or click to
                      select files to upload.
                    </p>
                  )}
                </React.Fragment>
              )}
              {fileUploading && (
                <p style={{ textAlign: "center" }}>
                  <span>Files are uploading...</span>
                  <Loading />{" "}
                </p>
              )}
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

FileUpload.defaultProps = {
  handleUpload: () => console.log("Please pass in an upload handler.")
};
export default FileUpload;
