import * as ACTION from "../constants";

import { buildJsonRequest, getErrorMessage } from "../utils";
import {
  getFields,
  getInbox,
  getFarms,
  showToast,
  dispatchAtDelay
} from "./";

export const logout = () => {
  return {
    type: ACTION.LOG_OUT
  };
};

export const login = (EMAIL, PASSWORD) => {
  return buildJsonRequest(
    {
      url: "/user/login",
      method: "POST",
      data: {
        email: EMAIL,
        password: PASSWORD
      }
    },
    ACTION.LOG_IN,
    [getFields, getFarms, getInbox, getHealthStatus, getUser]
    /* Mockup!!  Comment this out to use real APIs
    {
      status: "success",
      token:
        "ZXlKMGVYQWlPaUpLVjFRaUxDSmhiR2NpT2lKSVV6STFOaUo5LmV5SjBheUk2TWpFc0ltVjRjQ0k2TVRVMk9EY3hNRFEyTTMwLnQ3Y0E2Y1Q3U1BSQkNwTzRHaW1YZ2FOTkdzZ3l2OV9NN3VNMG16bmJmSVk="
    }
    */
  );
};

export const tokenLogin = (token) => {
  return buildJsonRequest(
    {
      url: "/user/login_status",
      method: "GET"
    },
    ACTION.LOG_IN,
    [],
    [],
    token
  );
};

export const checkLoginStatus = () => {
  return buildJsonRequest(
    {
      url: "/user/login_status",
      method: "GET"
    },
    ACTION.LOG_IN_STATUS
  );
};

export const registerUser = (
  NAME,
  EMAIL,
  PASSWORD,
  CODE,
  PHONE,
  ROLE
) => {
  return buildJsonRequest(
    {
      url: "/user/user",
      method: "POST",
      data: {
        name: NAME,
        email: EMAIL,
        password: PASSWORD,
        inviteCode: CODE,
        phone: PHONE,
        role: ROLE
      }
    },
    ACTION.REGISTER
    /* Mockup!!  Comment this out to use real APIs 
    {
      status: "success"
    }
    */
  );
};

export const inviteUser = (NAME, EMAIL, PHONE, MESSAGE) => {
  return buildJsonRequest(
    {
      url: "/user/invite_user",
      method: "POST",
      data: {
        name: NAME,
        email: EMAIL,
        phone: PHONE,
        message: MESSAGE
      }
    },
    ACTION.INVITE,
    [getReferrals, () => showToast("Invite sent.")],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const resendInvite = (EMAIL) => {
  return buildJsonRequest(
    {
      url: "/user/invite_user/resend",
      method: "POST",
      data: {
        email: EMAIL
      }
    },
    ACTION.INVITE,
    [getReferrals, () => showToast("Invite sent.")],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const getReferrals = () => {
  return buildJsonRequest(
    {
      url: "/user/invite_user",
      method: "GET"
    },
    ACTION.GET_REFERRALS
  );
};

export const getUser = () => {
  return buildJsonRequest(
    {
      url: "/user/user",
      method: "GET"
    },
    ACTION.GET_USER
  );
};

export const deleteUser = () => {
  return buildJsonRequest(
    {
      url: "/user/user",
      method: "DELETE"
    },
    ACTION.DELETE_USER,
    [() => showToast("Account Deleted."), logout],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const completeInvite = () => {
  return buildJsonRequest(
    {
      url: "/user/complete_invite",
      method: "POST"
    },
    ACTION.UPDATE_ACCOUNT_SETTINGS,
    [
      getUser,
      (response) => showToast("Account updated."),
      () =>
        dispatchAtDelay(ACTION.UPDATE_ACCOUNT_SETTINGS_CLEAR, 5000)
    ],
    [
      (error) => showToast(getErrorMessage(error)),
      () =>
        dispatchAtDelay(ACTION.UPDATE_ACCOUNT_SETTINGS_CLEAR, 5000)
    ]
  );
};

export const updateAccountSettings = (data) => {
  return buildJsonRequest(
    {
      url: "/user/user",
      method: "PUT",
      data: data
    },
    ACTION.UPDATE_ACCOUNT_SETTINGS,
    [
      getUser,
      (response) => showToast("Account updated."),
      () =>
        dispatchAtDelay(ACTION.UPDATE_ACCOUNT_SETTINGS_CLEAR, 5000)
    ],
    [
      (error) => showToast(getErrorMessage(error)),
      () =>
        dispatchAtDelay(ACTION.UPDATE_ACCOUNT_SETTINGS_CLEAR, 5000)
    ]
  );
};

export const updateLoginInfo = (
  EMAIL,
  CURRENT_PASSWORD,
  NEW_PASSWORD
) => {
  return buildJsonRequest(
    {
      url: "/user/user",
      method: "PUT",
      data: {
        email: EMAIL,
        old_password: CURRENT_PASSWORD,
        new_password: NEW_PASSWORD
      }
    },
    ACTION.UPDATE_LOGIN_INFO,
    [(response) => showToast("Login info updated."), logout],
    [
      (error) => showToast(getErrorMessage(error)),
      () => dispatchAtDelay(ACTION.UPDATE_LOGIN_INFO_CLEAR, 5000)
    ]
  );
};

export const requestPasswordReset = (EMAIL) => {
  return buildJsonRequest(
    {
      url: "/user/forgot_pwd",
      method: "POST",
      data: {
        email: EMAIL
      }
    },
    ACTION.REQUEST_PASSWORD_RESET
  );
};

export const resetPassword = (PASSWORD, TOKEN) => {
  return buildJsonRequest(
    {
      url: "/user/reset_pwd",
      method: "POST",
      data: {
        password: PASSWORD,
        token: TOKEN
      }
    },
    ACTION.RESET_PASSWORD
  );
};

export const confirmAccount = (TOKEN) => {
  return buildJsonRequest(
    {
      url: `/user/verify_email`,
      method: "POST",
      data: {
        token: TOKEN
      }
    },
    ACTION.CONFIRM_ACCOUNT
  );
};

export const clearUserError = () => {
  return {
    type: ACTION.CLEAR_USER_ERROR
  };
};

//TODO: get API
export const getHealthStatus = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION.GET_HEALTH_STATUS
    });

    //Simulate API Response
    setTimeout(
      () =>
        dispatch({
          type: ACTION.GET_HEALTH_STATUS_SUCCESS,
          data: {
            status: [
              {
                status: "healthy",
                fieldCount: 12,
                color: "yellowgreen"
              },
              { status: "warning", fieldCount: 4, color: "yellow" },
              {
                status: "problem detected",
                fieldCount: 1,
                color: "#FF5733"
              }
            ]
          }
        }),
      1000
    );
  };
};
