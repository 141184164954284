// @flow

import React, { useState } from "react";

import { Header } from "../";

const Contact = (props: Props) => {
  const [message, setMessage] = useState("");
  const [dropdownOption, setDropdownOption] = useState("");

  const { loading, success } = props.inbox.components.contact;

  const { contactTeam } = props;

  return (
    <div className="Contact">
      <h1>Get Help</h1>

      <Header
        loading={loading}
        success={success}
        title="Questions? Concerns? Please leave us a message"
      ></Header>

      <i>A team member will contact you to answer your questions.</i>
      <form id="ContactForm">
        <label>
          <span className="BigText">Subject:</span>
          <select
            value={dropdownOption}
            onChange={(evt) => {
              setDropdownOption(evt.target.value);
            }}
          >
            <option key="" value="">
              select
            </option>

            {[
              "Get help navigating the site",
              "Get help with data loading and processing",
              "Get help with reports or simulations",
              "Report a problem",
              "Other question"
            ].map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        </label>

        <label>
          <span className="BigText">Message:</span>
          <textarea
            rows={5}
            value={message}
            onChange={(evt) => setMessage(evt.target.value)}
          />
        </label>

        <input
          type="button"
          value="Send"
          disabled={message === "" || dropdownOption === ""}
          onClick={() => {
            if (message !== "") {
              contactTeam(message, dropdownOption);
              setMessage("");
            }
          }}
        />
      </form>
    </div>
  );
};

export default Contact;
