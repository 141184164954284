// @flow

import React from "react";

//import "rc-slider/assets/index.css";
//import Slider from "rc-slider";
import Slider from "react-rangeslider";

import "react-rangeslider/lib/index.css";

type Props = {
  min: number,
  max: number,
  defaultValue: number,
  label: string
};

class SliderControl extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value
    };
  }
  render() {
    const { min, max, label, onChange } = this.props;
    return (
      <span className="Slider">
        <span>{label}</span>
        <span>{this.state.value}%</span>

        <Slider
          min={min}
          max={max}
          value={this.state.value}
          onChange={(value) => {
            this.setState({ value: value });
            onChange(value);
          }}
          labels={{ 0: "0", 100: "100%", 200: "200%" }}
        />
      </span>
    );

    //
  }
}

SliderControl.defaultProps = {
  min: 0,
  max: 200,
  defaultValue: 100,
  label: ""
};
export default SliderControl;
