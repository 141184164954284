import axios from "axios";
import saveAs from "file-saver";
import parse_georaster from "georaster";

import * as ACTION from "../constants";

const apiURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API
    : process.env.REACT_APP_DEV_API;

const processingApiURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_PROCESSING_API
    : process.env.REACT_APP_DEV_PROCESSING_API;

const buildJsonRequestWithEndpoint = (
  ENDPOINT,
  REQUEST,
  DISPATCH_ACTION,
  ON_SUCCESS_CALLBACK,
  ON_ERROR_CALLBACK,
  TOKEN
) => {
  let savedState = JSON.parse(sessionStorage.getItem("state"));

  let token = TOKEN
    ? TOKEN
    : savedState
    ? savedState.session.token
    : null;

  if (
    !token &&
    !(DISPATCH_ACTION === ACTION.LOG_IN) &&
    !(DISPATCH_ACTION === ACTION.REGISTER) &&
    !(DISPATCH_ACTION === ACTION.CONFIRM_ACCOUNT) &&
    !(DISPATCH_ACTION === ACTION.RESET_PASSWORD) &&
    !(DISPATCH_ACTION === ACTION.REQUEST_PASSWORD_RESET)
  ) {
    return (dispatch) => {
      dispatch({
        type: ACTION.LOG_OUT
      });
    };
  }

  var request = {
    url: ENDPOINT + REQUEST.url,
    method: REQUEST.method,
    data: JSON.stringify(REQUEST.data),
    headers: {
      Authorization: token,
      "Content-Type": "application/json; charset=utf-8"
    }
  };

  return (dispatch) => {
    dispatch({
      type: DISPATCH_ACTION,
      request: REQUEST.data
    });
    return axios
      .request(request)
      .then((response) => {
        dispatch({
          type: `${DISPATCH_ACTION}_SUCCESS`,
          data: response.data,
          metadata: REQUEST.metadata
        });

        if (ON_SUCCESS_CALLBACK && ON_SUCCESS_CALLBACK.length > 0) {
          ON_SUCCESS_CALLBACK.forEach((callback) => {
            dispatch(callback(response.data));
          });
        } else {
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch({
            type: ACTION.LOG_OUT
          });
        } else {
          dispatch({
            type: `${DISPATCH_ACTION}_FAIL`,
            error: error.response
              ? error.response.data.msg
              : error.message
          });

          if (ON_ERROR_CALLBACK && ON_ERROR_CALLBACK.length > 0) {
            ON_ERROR_CALLBACK.forEach((callback) => {
              dispatch(callback(error));
            });
          }
        }
      });
  };
};

const buildJsonRequest = (
  REQUEST,
  DISPATCH_ACTION,
  ON_SUCCESS_CALLBACK,
  ON_ERROR_CALLBACK,
  TOKEN
) => {
  return buildJsonRequestWithEndpoint(
    apiURL,
    REQUEST,
    DISPATCH_ACTION,
    ON_SUCCESS_CALLBACK,
    ON_ERROR_CALLBACK,
    TOKEN
  );
};

const buildProcessingJsonRequest = (
  REQUEST,
  DISPATCH_ACTION,
  ON_SUCCESS_CALLBACK,
  ON_ERROR_CALLBACK,
  TOKEN
) => {
  return buildJsonRequestWithEndpoint(
    processingApiURL,
    REQUEST,
    DISPATCH_ACTION,
    ON_SUCCESS_CALLBACK,
    ON_ERROR_CALLBACK,
    TOKEN
  );
};

const buildFileUploadRequest = (
  REQUEST,
  DISPATCH_ACTION,
  ON_SUCCESS_CALLBACK,
  ON_ERROR_CALLBACK,
  MOCKUP
) => {
  let savedState = JSON.parse(sessionStorage.getItem("state"));

  let token = savedState ? savedState.session.token : null;

  var request = {
    url: apiURL + REQUEST.url,
    method: REQUEST.method,
    headers: {
      Authorization: token,
      "Content-Type": "application/json; charset=utf-8"
    }
  };

  return (dispatch) => {
    dispatch({
      type: DISPATCH_ACTION,
      request: REQUEST.data
    });
    return axios
      .request(request)
      .then((response) => {
        //Get S3 file upload url from backend
        const { url } = response.data;

        var requestFile = {
          url: url,
          method: "PUT",
          data: REQUEST.data,
          headers: {
            "Content-Type": "application/zip"
          }
        };

        //Send files to s3 file upload url
        axios
          .request(requestFile)
          .then((response) => {
            dispatch({
              type: `${DISPATCH_ACTION}_SUCCESS`,
              data: response.data
            });
            if (
              ON_SUCCESS_CALLBACK &&
              ON_SUCCESS_CALLBACK.length > 0
            ) {
              ON_SUCCESS_CALLBACK.forEach((callback) => {
                dispatch(callback(response.data));
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              dispatch({
                type: ACTION.LOG_OUT
              });
            } else {
              dispatch({
                type: `${DISPATCH_ACTION}_FAIL`,
                error: error.response
                  ? error.response.data.msg
                  : error.message
              });
              if (ON_ERROR_CALLBACK && ON_ERROR_CALLBACK.length > 0) {
                ON_ERROR_CALLBACK.forEach((callback) => {
                  dispatch(callback(error.response));
                });
              }
            }
          });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch({
            type: ACTION.LOG_OUT
          });
        } else {
          dispatch({
            type: `${DISPATCH_ACTION}_FAIL`,
            error: error.response
              ? error.response.data.msg
              : error.message
          });
          if (ON_ERROR_CALLBACK && ON_ERROR_CALLBACK.length > 0) {
            ON_ERROR_CALLBACK.forEach((callback) => {
              dispatch(callback(error));
            });
          }
        }
      });
  };
};

const buildFileDownloadRequest = (
  REQUEST,
  DISPATCH_ACTION,
  ON_SUCCESS_CALLBACK,
  ON_ERROR_CALLBACK,
  MOCKUP
) => {
  let savedState = JSON.parse(sessionStorage.getItem("state"));

  let token = savedState ? savedState.session.token : null;

  var request = {
    responseType: "blob",
    url: apiURL + REQUEST.url,
    method: REQUEST.method,
    data: REQUEST.data,
    timeout: 1000 * 30, // Wait for 5 seconds

    headers: {
      Authorization: token,
      "Content-Type": "application/json; charset=utf-8"
    }
  };

  return (dispatch) => {
    dispatch({
      type: DISPATCH_ACTION,
      request: REQUEST.data
    });
    return axios
      .request(request)
      .then((response) => {
        saveAs(response.data, REQUEST.outputFileName);
        dispatch({
          type: `${DISPATCH_ACTION}_SUCCESS`,
          data: response.data
        });
        if (ON_SUCCESS_CALLBACK && ON_SUCCESS_CALLBACK.length > 0) {
          ON_SUCCESS_CALLBACK.forEach((callback) => {
            dispatch(callback(response.data));
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch({
            type: ACTION.LOG_OUT
          });
        } else {
          dispatch({
            type: `${DISPATCH_ACTION}_FAIL`,
            error: error.response
              ? error.response.data.msg
              : error.message
          });
          if (ON_ERROR_CALLBACK && ON_ERROR_CALLBACK.length > 0) {
            ON_ERROR_CALLBACK.forEach((callback) => {
              dispatch(callback(error.response));
            });
          }
        }
      });
  };
};

const getErrorMessage = (error) => {
  console.log(error);
  if (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.msg
    ) {
      return error.response.data.msg;
    } else if (error.message && error.message !== "") {
      return error.message;
    }
  }

  return "An unknown error occurred.  Please try again.";
};

const buildRasterRequestWithEndpoint = (
  ENDPOINT,
  REQUEST,
  DISPATCH_ACTION,
  ON_SUCCESS_CALLBACK,
  ON_ERROR_CALLBACK,
  TOKEN
) => {
  let savedState = JSON.parse(sessionStorage.getItem("state"));

  let token = TOKEN
    ? TOKEN
    : savedState
    ? savedState.session.token
    : null;

  if (
    !token &&
    !(DISPATCH_ACTION === ACTION.LOG_IN) &&
    !(DISPATCH_ACTION === ACTION.REGISTER) &&
    !(DISPATCH_ACTION === ACTION.CONFIRM_ACCOUNT) &&
    !(DISPATCH_ACTION === ACTION.RESET_PASSWORD) &&
    !(DISPATCH_ACTION === ACTION.REQUEST_PASSWORD_RESET)
  ) {
    return (dispatch) => {
      dispatch({
        type: ACTION.LOG_OUT
      });
    };
  }

  var request = {
    // url: ENDPOINT + REQUEST.url,
    url: "/2018_soy.tif",
    method: REQUEST.method,
    data: JSON.stringify(REQUEST.data),
    headers: {
      Authorization: token,
      "Content-Type": "application/json; charset=utf-8"
    }
  };

  return (dispatch) => {
    dispatch({
      type: DISPATCH_ACTION,
      request: REQUEST.data
    });
    return axios
      .request(request)
      .then((response) => {
        parse_georaster(response.data, undefined, true)
          .then((georaster) => {
            dispatch({
              type: `${DISPATCH_ACTION}_SUCCESS`,
              data: georaster,
              metadata: REQUEST.metadata
            });

            if (
              ON_SUCCESS_CALLBACK &&
              ON_SUCCESS_CALLBACK.length > 0
            ) {
              ON_SUCCESS_CALLBACK.forEach((callback) => {
                dispatch(callback(response.data));
              });
            } else {
            }
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch({
            type: ACTION.LOG_OUT
          });
        } else {
          dispatch({
            type: `${DISPATCH_ACTION}_FAIL`,
            error: error.response
              ? error.response.data.msg
              : error.message
          });

          if (ON_ERROR_CALLBACK && ON_ERROR_CALLBACK.length > 0) {
            ON_ERROR_CALLBACK.forEach((callback) => {
              dispatch(callback(error));
            });
          }
        }
      });
  };
};

const buildRasterRequest = (
  REQUEST,
  DISPATCH_ACTION,
  ON_SUCCESS_CALLBACK,
  ON_ERROR_CALLBACK,
  TOKEN
) => {
  return buildRasterRequestWithEndpoint(
    apiURL,
    REQUEST,
    DISPATCH_ACTION,
    ON_SUCCESS_CALLBACK,
    ON_ERROR_CALLBACK,
    TOKEN
  );
};

export {
  buildJsonRequest,
  buildProcessingJsonRequest,
  buildFileUploadRequest,
  buildFileDownloadRequest,
  getErrorMessage,
  buildRasterRequest
};
