import * as ACTION from "../constants";

export const userReducer = (
  state = {
    email: undefined,
    name: undefined,
    phone: undefined,
    role: undefined,
    role_entitlements: undefined,
    healthStatus: [],
    fileProcessingStatus: {
      pending: 0,
      processed: 0,
      failed: 0
    },
    farms: [],
    components: {
      user: { loading: false, error: undefined, success: undefined },
      delete_user: {
        loading: false,
        error: undefined,
        success: undefined
      },
      invite_user: {
        loading: false,
        error: undefined,
        success: undefined
      },
      get_referrals: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_account_settings: {
        loading: false,
        error: undefined,
        success: undefined
      },
      update_login_info: {
        loading: false,
        error: undefined,
        success: undefined
      },
      health_status: {
        loading: false,
        error: undefined,
        success: undefined
      },
      request_password_reset: {
        loading: false,
        error: undefined,
        success: undefined
      },
      reset_password: {
        loading: false,
        error: undefined,
        success: undefined
      },
      confirm_account: {
        loading: false,
        error: undefined,
        success: undefined
      }
    }
  },
  action
) => {
  switch (action.type) {
    case ACTION.LOG_IN:
      return {
        ...state,
        components: {
          ...state.components,
          user: {
            ...state.components.user,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.LOG_IN_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          user: {
            ...state.components.user,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.LOG_IN_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          user: {
            ...state.components.user,
            error: action.error,
            loading: false
          }
        }
      };
    case ACTION.REGISTER:
      return {
        ...state,
        components: {
          ...state.components,
          user: {
            ...state.components.user,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.REGISTER_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          user: {
            ...state.components.user,
            error: undefined,
            loading: false,
            success: true
          }
        },
        isRegistered: true
      };
    case ACTION.REGISTER_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          user: {
            ...state.components.user,
            error: action.error,
            loading: false
          }
        }
      };

    case ACTION.INVITE:
      return {
        ...state,
        components: {
          ...state.components,
          invite_user: {
            ...state.components.invite_user,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.INVITE_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          invite_user: {
            ...state.components.invite_user,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.INVITE_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          invite_user: {
            ...state.components.invite_user,
            error: action.error,
            loading: false
          }
        }
      };

    case ACTION.GET_REFERRALS:
      return {
        ...state,
        components: {
          ...state.components,
          get_referrals: {
            ...state.components.get_referrals,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.GET_REFERRALS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          get_referrals: {
            ...state.components.get_referrals,
            error: undefined,
            loading: false,
            success: true
          }
        },
        total_acres: action.data.total_acres,
        referrals: action.data.invitees
      };
    case ACTION.GET_REFERRALS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          get_referrals: {
            ...state.components.get_referrals,
            error: action.error,
            loading: false
          }
        }
      };

    case ACTION.UPDATE_ACCOUNT_SETTINGS:
      return {
        ...state,
        components: {
          ...state.components,
          update_account_settings: {
            ...state.components.update_account_settings,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.UPDATE_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_account_settings: {
            ...state.components.update_account_settings,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.UPDATE_ACCOUNT_SETTINGS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_account_settings: {
            ...state.components.update_account_settings,
            error: action.error,
            loading: false
          }
        }
      };
    case ACTION.UPDATE_ACCOUNT_SETTINGS_CLEAR:
      return {
        ...state,
        components: {
          ...state.components,
          update_account_settings: {
            ...state.components.update_account_settings,
            error: undefined,
            loading: false,
            success: undefined
          }
        }
      };
    case ACTION.UPDATE_LOGIN_INFO:
      return {
        ...state,
        components: {
          ...state.components,
          update_login_info: {
            ...state.components.update_login_info,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.UPDATE_LOGIN_INFO_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          update_login_info: {
            ...state.components.update_login_info,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.UPDATE_LOGIN_INFO_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          update_login_info: {
            ...state.components.update_login_info,
            error: action.error,
            loading: false
          }
        }
      };
    case ACTION.UPDATE_LOGIN_INFO_CLEAR:
      return {
        ...state,
        components: {
          ...state.components,
          update_login_info: {
            ...state.components.update_login_info,
            error: undefined,
            loading: false,
            success: undefined
          }
        }
      };
    case ACTION.CLEAR_USER_ERROR:
      return {
        ...state,
        components: {
          ...state.components,
          user: {
            ...state.components.user,
            error: undefined
          }
        },
        isRegistered: undefined
      };
    case ACTION.GET_HEALTH_STATUS:
      return {
        ...state,
        components: {
          ...state.components,
          health_status: {
            ...state.components.health_status,
            loading: true,
            error: undefined,
            success: false
          }
        }
      };
    case ACTION.CONFIRM_ACCOUNT:
      return {
        ...state,
        components: {
          ...state.components,
          confirm_account: {
            ...state.components.confirm_account,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.CONFIRM_ACCOUNT_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          confirm_account: {
            ...state.components.confirm_account,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.CONFIRM_ACCOUNT_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          confirm_account: {
            ...state.components.confirm_account,
            error: action.error,
            loading: false
          }
        }
      };
    case ACTION.REQUEST_PASSWORD_RESET:
      return {
        ...state,
        components: {
          ...state.components,
          request_password_reset: {
            ...state.components.request_password_reset,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          request_password_reset: {
            ...state.components.request_password_reset,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.REQUEST_PASSWORD_RESET_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          request_password_reset: {
            ...state.components.request_password_reset,
            error: action.error,
            loading: false
          }
        }
      };

    case ACTION.RESET_PASSWORD:
      return {
        ...state,
        components: {
          ...state.components,
          reset_password: {
            ...state.components.reset_password,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          reset_password: {
            ...state.components.reset_password,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.RESET_PASSWORD_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          reset_password: {
            ...state.components.reset_password,
            error: action.error,
            loading: false
          }
        }
      };
    case ACTION.GET_HEALTH_STATUS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          health_status: {
            ...state.components.health_status,
            loading: false,
            success: true
          }
        },
        healthStatus: action.data.status
      };
    case ACTION.GET_HEALTH_STATUS_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          health_status: {
            ...state.components.health_status,
            loading: false,
            error: action.error
          }
        }
      };
    case ACTION.GET_USER_SUCCESS:
      return {
        ...state,
        email: action.data.email,
        name: action.data.name,
        phone: action.data.phone,
        role: action.data.role,
        role_entitlements: action.data.role_entitlements,
        has_accepted_terms: action.data.has_accepted_terms
      };
    case ACTION.GET_FILE_PROCESSING_STATUS_SUCCESS:
      return {
        ...state,
        fileProcessingStatus: {
          pending: action.data.file_status_count.PENDING,
          processed: action.data.file_status_count.PROCESSED,
          failed: action.data.file_status_count.FAILED
            ? action.data.file_status_count.FAILED
            : 0
        }
      };
    case ACTION.DELETE_USER:
      return {
        ...state,
        components: {
          ...state.components,
          delete_user: {
            ...state.components.delete_user,
            error: undefined,
            loading: true,
            success: undefined
          }
        }
      };
    case ACTION.DELETE_USER_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          delete_user: {
            ...state.components.delete_user,
            error: undefined,
            loading: false,
            success: true
          }
        }
      };
    case ACTION.DELETE_USER_FAIL:
      return {
        ...state,
        components: {
          ...state.components,
          delete_user: {
            ...state.components.delete_user,
            error: action.error,
            loading: false
          }
        }
      };
    default:
      return state;
  }
};
