// @flow

import React, { useEffect } from "react";
import "./styles.scss";

import { useNavigate, useParams } from "react-router";

import { AlertBar, Loading } from "../../components/";
import { AuthenticationPage } from ".";

type Props = {};

const TokenLogin = (props: Props) => {
  const navigate = useNavigate();

  const { tokenLogin } = props;

  const { token } = useParams();

  const { success, loading } = props.user.components.user;

  useEffect(() => {
    tokenLogin(token);
  }, [token, tokenLogin]);

  return (
    <AuthenticationPage>
      <div className="LoginHeader">
        <span className="active">Login</span>
      </div>

      <div className="FormContainer">
        {!loading && !success && (
          <React.Fragment>
            {" "}
            <AlertBar>
              Your invite token has expired. Please contact a team
              member for assistance.
            </AlertBar>
            <form className="LoginForm">
              <input
                type="button"
                value="Go to Login"
                onClick={() => {
                  navigate("/app/login");
                }}
              />{" "}
            </form>
          </React.Fragment>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {loading ? (
            <React.Fragment>
              <Loading />
              <div> Logging in to your account...</div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </AuthenticationPage>
  );
};

TokenLogin.defaultProps = {};
export default TokenLogin;
