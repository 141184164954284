// @flow

import React from "react";
import Section from "./Section";
import NDAForm from "./NDAForm";
import { VideoPlayer } from "../../../components";

import { hasAppAttribute } from "../../../entitlements";
import * as APP_ATTRIBUTE from "../../../entitlements/appAttributes";
const GetStarted = (props: Props) => {
  return (
    <Section
      id="getstarted"
      onVisibilityChange={props.onVisibilityChange}
      style={{ minHeight: "30em" }}
    >
      <div className="Section" id="getstarted">
        <h1 className="SectionHeading">Get Started</h1>
        <p className="BigText">
          Are you a farmer or agronomy professional looking to take
          your business to the next level? Join today!
        </p>

        <div className="PanelWrapper">
          <div className="PanelContent">
            <h1>For Farmers, by Farmers</h1>
            <p className="BigText">
              Your <i>always free</i> basic membership supports your
              neighbors in the local farm community and keeps you
              updated on exclusive news for our members. Click above
              to join today!
            </p>

            <h1>
              Become a Founding Farmer Investor to upgrade your
              account to utilize these features:
            </h1>

            <h2>Optimal Seed Fit Tool</h2>
            <h2>Field Profile / Farm Management Tools</h2>
            <h2>Yield and Nutrient Removal Reports</h2>
            <h2>Data Cleaning</h2>

            {/* <h1>
              Pay one low price for your lifetime Founding Farmer membership ($1/acre, no more than $4000 total)
            </h1> */}
            {/* <p> <i>Your Founding Farmer membership and data helps build the vision of MFI.  To be a qualified founding member, you must sign up all your acres for this low price.
              Founding Farmer members get access to all MFI tools at no additional cost for one year or until MFI launches as a paid software (whichever is longer). </i></p> */}
          </div>
          {hasAppAttribute(APP_ATTRIBUTE.NDA_FORM, props.user) && (
            <NDAForm {...props} />
          )}
          {!hasAppAttribute(APP_ATTRIBUTE.NDA_FORM, props.user) && (
            <div className="PanelContent">
              <h1>Welcome to MFI - Actionable Intelligence</h1>

              <p>
                <VideoPlayer
                  height={320}
                  src={
                    "https://ddwzudgjmurcz.cloudfront.net/spotlight/video.mp4"
                  }
                  poster={
                    "https://ddwzudgjmurcz.cloudfront.net/spotlight/poster.png"
                  }
                  autoplay={hasAppAttribute(
                    APP_ATTRIBUTE.JOIN_FORM,
                    props.user
                  )}
                  controls={true}
                />
              </p>
              {/* <div style={{ display: "flex", flexDirection: "row", width: "570px" }}>

                <h1 style={{ paddingRight: "50px" }}>As a Founding Farmer, your $1/acre membership unlocks $50+/acre increase in profit potential this fall</h1>

                <p>
                  <VideoPlayer height={320} src={"https://ddwzudgjmurcz.cloudfront.net/grant-onedollar/video.mp4"} poster={"https://ddwzudgjmurcz.cloudfront.net/grant-onedollar/poster.png"} autoplay={false} controls={true} />
                </p>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default GetStarted;
