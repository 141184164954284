import * as ACTION from "../constants";

export const typeaheadReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION.GET_TYPEAHEAD_SUGGESTIONS:
      return state;
    case ACTION.GET_TYPEAHEAD_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        [action.endpoint]: action.data.list
      };
    case ACTION.CLEAR_TYPEAHEAD_SUGGESTIONS:
      return {
        ...state,
        [action.endpoint]: []
      };
    default:
      return state;
  }
};
