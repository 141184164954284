// @flow

import React, { useState } from "react";
import "./styles.scss";

import { AuthenticationPage } from ".";

import { Input, AlertBar, Loading } from "../../components/";

//type Props = {};
import { useNavigate } from "react-router";

const RequestPasswordReset = (props) => {
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState(undefined);

  const validate = () => {
    var validationMessage = "";

    if (!email) {
      validationMessage =
        validationMessage + "\u2022 Email is required.\n";
    }

    setValidationError(validationMessage);

    return validationMessage === "";
  };

  const navigate = useNavigate();

  const {
    error,
    loading,
    success
  } = props.user.components.request_password_reset;

  const { requestPasswordReset } = props;

  return (
    <AuthenticationPage>
      <div className="LoginHeader">
        <span className="active">Reset Password</span>
      </div>

      <div className="FormContainer">
        {validationError || error ? (
          <AlertBar>
            {validationError ? (
              <div>
                {validationError.split("\n").map((item) => {
                  return <div>{item}</div>;
                })}
              </div>
            ) : null}
            {error}
          </AlertBar>
        ) : null}
        {success && (
          <AlertBar type="success">
            Email sent successfully! Please check your email.
          </AlertBar>
        )}
        {loading ? <Loading /> : null}
        <form className="LoginForm">
          {!success && (
            <React.Fragment>
              <label>
                <span>Email:</span>
                <Input
                  name="email"
                  type="text"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                  mask={/^\S*@?\S*$/}
                />
              </label>

              <input
                type="button"
                value="Email me a link"
                onClick={() => {
                  validate()
                    ? requestPasswordReset(email)
                    : console.log("validation error");
                }}
              />
            </React.Fragment>
          )}

          {success && (
            <React.Fragment>
              <input
                type="button"
                value="Go to Login"
                onClick={() => {
                  navigate("/app/login");
                }}
              />
            </React.Fragment>
          )}
        </form>
      </div>
    </AuthenticationPage>
  );
};

RequestPasswordReset.defaultProps = {};
export default RequestPasswordReset;
