// @flow
import React from "react";
import ReactDOMServer from "react-dom/server";

import { GeoJSON } from "react-leaflet";
import L from "leaflet";

const FieldsSummaryLayer = (props) => {
  const { selectField, activeFieldId, zoom, data } = props;

  return (
    <GeoJSON
      key={"fields_summary_list" + data ? data.length : ""}
      bubblingMouseEvents={false}
      pointToLayer={(feature, latlng) => {
        return L.circleMarker(latlng, {
          radius: 10
        });
      }}
      style={(feature) => {
        let color = feature.properties.color
          ? feature.properties.color
          : "#cccccc";
        return {
          color:
            feature.properties.field_id === activeFieldId
              ? "#ece4b6"
              : "#272320",
          fillColor: color,
          opacity:
            feature.properties.field_id === activeFieldId &&
            zoom >= 14
              ? 0
              : 1,
          fillOpacity:
            feature.properties.field_id === activeFieldId &&
            zoom >= 14
              ? 0
              : 1
        };
      }}
      onEachFeature={(feature, layer) => {
        layer.bindPopup(
          ReactDOMServer.renderToString(
            <div className="FieldPopup">
              <span> Field:</span>
              <span>{feature.properties.field_name}</span>
              {/* <span> Product: </span>
                <span>{feature.properties.product}</span> */}
              <span> Farm:</span>
              <span>
                {feature.properties.farm_name
                  ? feature.properties.farm_name
                  : "unassigned"}
              </span>
            </div>
          ),

          {
            maxWidth: "500",
            className: "custom"
          }
        );
        layer.on("click", (evt) => {
          var layer = evt.target;

          selectField(
            layer.feature.properties.field_name,
            layer.feature.properties.field_id,
            [
              layer.feature.geometry.coordinates[1],
              layer.feature.geometry.coordinates[0]
            ],
            layer.feature.properties.boundary_locked
          );
        });
        layer.on("mouseover", function () {
          layer.openPopup();
        });
        layer.on("mouseout", function () {
          layer.closePopup();
        });
      }}
      data={data}
    />
  );
};

export default FieldsSummaryLayer;
