import * as ACTION from "../constants";

import {
  buildFileUploadRequest,
  buildJsonRequest,
  getErrorMessage
} from "../utils";
import { showToast } from "./toast";

export const uploadFiles = (FILES) => {
  //Limited to 1 file at a time for now (S3 URL limitation)
  // var bodyFormData = new FormData();

  // FILES.forEach((file, index) => {
  //   bodyFormData.append(file.name, file);
  // });

  return buildFileUploadRequest(
    {
      url: `/files/geturl?file_name=${FILES[0].name}`,
      method: "GET",
      data: FILES[0]
    },
    ACTION.UPLOAD_FILE,
    [
      (response) => showToast("Upload successful."),
      () => getFileProcessingStatus()
    ],
    [(error) => showToast(getErrorMessage(error))]
  );
};

export const getFileProcessingStatus = () => {
  return buildJsonRequest(
    {
      url: "/files/filequeue",
      method: "GET"
    },
    ACTION.GET_FILE_PROCESSING_STATUS,
    [getFields]
  );
};

export const getFields = () => {
  return buildJsonRequest(
    {
      url: "/data/fields",
      method: "GET"
    },
    ACTION.GET_FIELDS,
    undefined,
    [(response) => showToast(getErrorMessage(response))]
  );
};

export const deleteField = (FIELD_ID) => {
  return buildJsonRequest(
    {
      url: "/data/fields",
      method: "DELETE",
      data: {
        field_ids: [FIELD_ID]
      }
    },
    ACTION.DELETE_FIELD,
    [getFields],
    [(response) => showToast(getErrorMessage(response))]
  );
};

export const updateField = (FIELD_ID, FIELD_NAME, LOCK_BOUNDARY) => {
  return buildJsonRequest(
    {
      url: "/data/fields",
      method: "PUT",
      data: {
        field_id: FIELD_ID,
        field_name: FIELD_NAME,
        boundary_locked: LOCK_BOUNDARY
      }
    },
    ACTION.UPDATE_FIELD,
    [getFields, (response) => showToast(`Updated Field`)],
    [(response) => showToast(getErrorMessage(response))]
  );
};

export const updateBoundary = (FIELD_ID, BOUNDARY_ID) => {
  return buildJsonRequest(
    {
      url: "/data/fields/boundary",
      method: "POST",
      data: {
        field_id: FIELD_ID,
        boundary_id: BOUNDARY_ID
      }
    },
    ACTION.UPDATE_BOUNDARY,
    [getFields],
    [(response) => showToast(getErrorMessage(response))]
  );
};

export const getNearbyFields = (FIELD_ID) => {
  return buildJsonRequest(
    {
      url: `/data/fields/boundary/nearby?field_id=${FIELD_ID}`,
      method: "GET"
    },
    ACTION.GET_NEARBY_FIELDS,
    [],
    [(response) => showToast(getErrorMessage(response))]
  );
};
