// @flow

import React from "react";

import "./styles.scss";

import { PageWithMap } from "../../components";

import {
  SelectLayer,
  SeedFit,
  YieldReport,
  Fertility,
  CollapsibleLayer
} from "./components/";

type Props = {};

const Reports = (props: Props) => {
  const { activeFieldId } = props.app;

  const { detail } = props.fields;

  return (
    <PageWithMap title="reports" {...props}>
      {/*Selector....*/}
      <div className="ReportsWrapper">
        <div className="ReportsContent">
          <SelectLayer {...props} />

          {activeFieldId &&
            detail[activeFieldId] &&
            detail[activeFieldId].layers &&
            Object.keys(detail[activeFieldId].layers).map((layer) => {
              var layerObject = detail[activeFieldId].layers[layer];
              var geoLayerKey =
                layerObject.name === "boundary"
                  ? "boundary"
                  : `${layerObject.loc_name}-${layerObject.year}`;
              var geoLayerObject =
                detail[activeFieldId].geospatial_layers[geoLayerKey];

              var Report = undefined;
              if (layer.includes("fit")) {
                Report = <SeedFit {...props} layer={layerObject} />;
              } else if (
                layer.includes("yield") ||
                layer.includes("removals")
              ) {
                Report = (
                  <YieldReport {...props} layer={layerObject} />
                );
              } else if (
                layer.includes("prescription") ||
                layer.includes("fertility")
              ) {
                Report = <Fertility {...props} layer={layerObject} />;
              }
              return (
                <CollapsibleLayer
                  key={activeFieldId + layer + layerObject.loc_name}
                  layerKey={layer}
                  geoLayerKey={geoLayerKey}
                  layer={layerObject}
                  geospatial_layer={geoLayerObject}
                  {...props}
                >
                  {Report}
                </CollapsibleLayer>
              );
            })}
        </div>
      </div>
    </PageWithMap>
  );
};

Reports.defaultProps = {};
export default Reports;
