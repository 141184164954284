import { useEffect } from "react";
import { useInterval } from "../utils";
import { useLocation } from "react-router";

type Props = {
  isAuthenticated: boolean,
  fileUploadInProgress: boolean
};

const DataLoader = (props: Props) => {
  const {
    loadPageContent,
    isAuthenticated,
    checkLoginStatus
  } = props;

  const location = useLocation();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    loadPageContent(location, props);
    //eslint-disable-next-line
  }, [loadPageContent, location]);

  useInterval(() => {
    if (isAuthenticated) {
      loadPageContent(location.pathname, props);
    }
  }, 60000);

  useInterval(() => {
    if (isAuthenticated) {
      checkLoginStatus();
    }
  }, 300000);

  return null;
};

export default DataLoader;
