// @flow

import React from "react";
import "./styles.scss";

import { ReactComponent as Logo } from "../../assets/mfi-smooth.svg";

import image1 from "../../assets/img/corn-3665250.jpg";
import image2 from "../../assets/img/field-1521332.jpg";
import image3 from "../../assets/img/sunset-241806.jpg";
//import image4 from "../../assets/img/corn-3649693.jpg";

import BackgroundSlideshow from "react-background-slideshow";
import { useNavigate } from "react-router";

//type Props = {};

const AuthenticationPage = (props) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <BackgroundSlideshow
        className="Background"
        images={[image1, image2, image3]}
      />

      <div className="AuthenticationLayout">
        <div />
        <div className="Login">
          <header>
            {/* <h1 className="Title">mfi</h1> */}
            <Logo className="Logo" onClick={() => navigate("/")} />
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.5em",
                color: "#3e342e",
                marginBottom: ".5em"
              }}
            >
              a l p h a
            </div>
          </header>
          {props.children}
        </div>
        <div />
      </div>
    </React.Fragment>
  );
};

AuthenticationPage.defaultProps = {};
export default AuthenticationPage;
