// @flow

import React, { useState } from "react";
import Section from "./Section";

import { VideoModal, Notice } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import DefaultImg from "../img/default.png";

const videos = {
  whoowns: [
    {
      title: "Brian",
      src: "https://ddwzudgjmurcz.cloudfront.net/brian-whoowns"
    },
    {
      title: "Grant",
      src: "https://ddwzudgjmurcz.cloudfront.net/grant-whoowns"
    }
  ],
  whoruns: [
    {
      title: "Brian",
      src: "https://ddwzudgjmurcz.cloudfront.net/brian-whoruns"
    },
    {
      title: "Grant",
      src: "https://ddwzudgjmurcz.cloudfront.net/grant-whoruns"
    }
  ],
  whynotbefore: [
    {
      title: "Brian",
      src: "https://ddwzudgjmurcz.cloudfront.net/brian-whynotbefore"
    },
    {
      title: "Grant",
      src: "https://ddwzudgjmurcz.cloudfront.net/grant-whynotbefore"
    }
  ],
  whyjoin: [
    {
      title: "Brian",
      src: "https://ddwzudgjmurcz.cloudfront.net/brian-whyjoin"
    },
    {
      title: "Grant",
      src: "https://ddwzudgjmurcz.cloudfront.net/grant-whyjoin"
    }
  ]
};

const Testimonials = (props: Props) => {
  const [activeQuestion, setActiveQuestion] = useState("whoowns");

  return (
    <Section
      id="testimonials"
      onVisibilityChange={props.onVisibilityChange}
      style={{ minHeight: "105em" }}
    >
      <div className="Section" id="about">
        <h1 className="SectionHeading">Testimonials</h1>
        <Notice>
          <FontAwesomeIcon icon="exclamation-triangle" size="1x" />
          <span>
            This content is protected by our confidentiality
            agreement. Do not share this content with individuals who
            have not signed the confidentiality agreement. If you wish
            to share this content with someone who has not signed the
            agreement, use the invite tool to send them an invite and
            make sure that they sign the agreement before continuing.
          </span>
        </Notice>
        <div>
          <div id="Testimonials">
            <h1>Hear it from a Farmer!</h1>
            <div className="Testimonials">
              <div className="TestimonialSelector">
                <h2
                  className={
                    activeQuestion === "whoowns"
                      ? "active option"
                      : "option"
                  }
                  onClick={() => setActiveQuestion("whoowns")}
                >
                  {" "}
                  Who owns MFI?
                </h2>
                <h2
                  className={
                    activeQuestion === "whoruns"
                      ? "active option"
                      : "option"
                  }
                  onClick={() => setActiveQuestion("whoruns")}
                >
                  Who runs MFI?
                </h2>
                <h2
                  className={
                    activeQuestion === "whyjoin"
                      ? "active option"
                      : "option"
                  }
                  onClick={() => setActiveQuestion("whyjoin")}
                >
                  Why should you join MFI?
                </h2>
                <h2
                  className={
                    activeQuestion === "whynotbefore"
                      ? "active option"
                      : "option"
                  }
                  onClick={() => setActiveQuestion("whynotbefore")}
                >
                  {" "}
                  Why hasn't this been done before?
                </h2>
              </div>

              <div className="TestimonialVideos">
                <div className="Team">
                  {videos[activeQuestion].map((video) => {
                    return (
                      <VideoModal
                        {...props}
                        title={video.title}
                        img={`${video.src}/poster.png`}
                        src={`${video.src}/video.mp4`}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Testimonials;
