import React from "react";

const Progress = (props) => {
  return (
    <div
      className="Progress"
      style={{
        width: "100%",
        border: "1px solid #f5d87077",
        borderRadius: "5px",
        marginBottom: "1em"
      }}
    >
      <div
        style={{
          width: props.progress,
          height: "1em",
          backgroundColor: props.color,
          borderRadius: "5px",
          color: "#3e342e"
        }}
      />
    </div>
  );
};

Progress.defaultProps = {
  color: "#f5d870"
};
export default Progress;
