// @flow

import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import { Header, SliderModal } from "../../../components";
import { hasAppAttribute } from "../../../entitlements";
import * as APP_ATTRIBUTE from "../../../entitlements/appAttributes";

type Props = {};

const SeedDealerDataManagement = (props: Props) => {
  const { seeds, seed_companies } = props.seeds;

  const {
    getSeedCompanies,
    getSeedsByCompany,
    updateManagedSeeds,
    user,
    deleteManagedSeed
  } = props;

  const loading =
    props.seeds.components.get_seeds.loading ||
    props.seeds.components.update_seeds.loading;

  const [isOpen, setIsOpen] = useState(false);
  const [seedId, setSeedId] = useState("");
  const [seedName, setSeedName] = useState("");
  const [seedCompanyName, setSeedCompanyName] = useState("");
  const [traitPackage, setTraitPackage] = useState("");
  const [seedTreatment, setSeedTreatment] = useState("");
  const [averagePrice, setAveragePrice] = useState(undefined);
  const [cropType, setCropType] = useState("");
  const [daysToMaturity, setDaysToMaturity] = useState("");

  const [selectedSeedCompany, setSelectedSeedCompany] = useState(
    undefined
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (selectedSeedCompany) {
      getSeedsByCompany(selectedSeedCompany);
    } else if (seed_companies && seed_companies[0]) {
      //Load first company by default
      setSelectedSeedCompany(seed_companies[0]);
    } else {
      getSeedCompanies();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    seed_companies,
    selectedSeedCompany,
    getSeedCompanies,
    getSeedsByCompany
  ]);

  return (
    <div className="RightPanelContent">
      <ReactTooltip
        id="DataManagementTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />

      {hasAppAttribute(APP_ATTRIBUTE.MANAGE_SEED_DATA, user) && (
        <React.Fragment>
          <Header title="Manage Seed Dealer Data" loading={loading} />
          <div className="DataManagement">
            <form>
              <label>
                <span>Seed Company:</span>
                <select
                  onChange={(evt) => {
                    getSeedsByCompany(evt.target.value);
                    setSelectedSeedCompany(evt.target.value);
                  }}
                >
                  {seed_companies &&
                    seed_companies.sort().map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
                </select>
              </label>
            </form>
            <input
              type="button"
              value="Add Seed"
              onClick={() => {
                setSeedName("");
                setSeedCompanyName(selectedSeedCompany);
                setCropType("CORN"); //Default to CORN
                setDaysToMaturity(undefined);
                setSeedTreatment("");
                setTraitPackage("");
                setAveragePrice(undefined);
                setSeedId(undefined);
                setIsOpen(true);
              }}
            />
            <table>
              <thead>
                <tr>
                  <th style={{ width: "1em" }}></th>
                  <th style={{ width: "1em" }}></th>
                  <th> Seed Company Name </th>
                  <th> Seed Name </th>
                  <th> Crop Type</th>
                  <th> Days to Maturity</th>
                  <th> Seed Treatment</th>
                  <th> Trait Package</th>
                  <th> Average Price</th>
                </tr>
              </thead>
              <tbody>
                {seeds &&
                  seeds
                    .sort(function (a, b) {
                      var textA = a.seed_name.toUpperCase();
                      var textB = b.seed_name.toUpperCase();
                      return textA < textB
                        ? -1
                        : textA > textB
                        ? 1
                        : 0;
                    })
                    .map((seed, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              className="ActionIcon"
                              data-tip="Edit line item"
                              data-for="DataManagementTooltip"
                              onClick={() => {
                                setSeedName(seed.seed_name);
                                setSeedCompanyName(
                                  seed.seed_company_name
                                );
                                setCropType(seed.crop_type);
                                setDaysToMaturity(
                                  seed.days_to_maturity
                                );
                                setSeedTreatment(seed.seed_treatment);
                                setTraitPackage(seed.trait_package);
                                setAveragePrice(seed.average_price);
                                setSeedId(seed.seed_id);
                                setIsOpen(true);
                              }}
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              icon="trash-alt"
                              className="ActionIcon"
                              size="1x"
                              onClick={() => deleteManagedSeed(seed)}
                              data-tip="Delete seed"
                              data-for="DataManagementTooltip"
                            />
                          </td>

                          <td>{seed.seed_company_name} </td>
                          <td>{seed.seed_name} </td>
                          <td>{seed.crop_type} </td>
                          <td>{seed.days_to_maturity} </td>
                          <td>{seed.seed_treatment} </td>
                          <td>{seed.trait_package} </td>
                          <td>{seed.average_price} </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>{" "}
        </React.Fragment>
      )}

      <SliderModal
        title={`Edit Seed ${seedName}`}
        isOpen={isOpen}
        onOutsideClick={() => setIsOpen(false)}
        footer={
          <div
            style={{
              width: "100%",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            <input
              type="button"
              value="Update Seed Details"
              onClick={() => {
                var update_seed = {
                  seed_id: seedId,
                  seed_name: seedName,
                  seed_company_name: seedCompanyName,
                  crop_type: cropType,
                  seed_treatment: seedTreatment,
                  trait_package: traitPackage,
                  days_to_maturity: daysToMaturity,
                  average_price: averagePrice
                };

                updateManagedSeeds(update_seed);
                setIsOpen(false);
              }}
            />

            <input
              type="button"
              value="Cancel"
              onClick={() => setIsOpen(false)}
            />
          </div>
        }
      >
        <form>
          <label>{/* <span>Seed Company:</span> */}</label>
          <label>
            <span>Seed Company Name:</span>
            <input
              name="seedName"
              type="text"
              value={seedCompanyName}
              onChange={(e) => setSeedCompanyName(e.target.value)}
            />
          </label>
          <label>
            <span>Seed Name:</span>
            <input
              name="seedName"
              type="text"
              value={seedName}
              onChange={(e) => setSeedName(e.target.value)}
            />
          </label>
          <label>
            <span>Crop Type:</span>
            <select
              value={cropType}
              onChange={(evt) => {
                setCropType(evt.target.value);
              }}
            >
              {["CORN", "SOYBEANS"].map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </label>
          <label>
            <span>Days to Maturity:</span>
            <input
              name="daysToMaturity"
              type="number"
              value={daysToMaturity}
              onChange={(e) => setDaysToMaturity(e.target.value)}
            />
          </label>
          <label>
            <span>Seed Treatment:</span>
            <input
              name="seedTreatment"
              type="text"
              value={seedTreatment}
              onChange={(e) => setSeedTreatment(e.target.value)}
            />
          </label>
          <label>
            <span>Trait Package:</span>
            <input
              name="traitPackage"
              type="text"
              value={traitPackage}
              onChange={(e) => setTraitPackage(e.target.value)}
            />
          </label>
          <label>
            <span>Average Price:</span>
            <input
              name="averagePrice"
              type="number"
              value={averagePrice}
              onChange={(e) => setAveragePrice(e.target.value)}
            />
          </label>
        </form>
      </SliderModal>
    </div>
  );
};

SeedDealerDataManagement.defaultProps = {};
export default SeedDealerDataManagement;
