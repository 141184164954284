import * as ACTION from "../constants";

export const toastReducer = (
  state = {
    toasts: [
      // {
      //   timestamp: Date.now(),
      //   text: "This is a toast"
      // }
    ]
  },
  action
) => {
  switch (action.type) {
    case ACTION.SHOW_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.toast]
      };
    case ACTION.HIDE_TOAST:
      let toasts = state.toasts.filter(function (toast) {
        return toast.text !== action.toast.text;
      });
      return {
        ...state,
        toasts: toasts
      };
    default:
      return state;
  }
};
