// @flow

import React from "react";

import { getColorMapping, sortYieldEnvs } from "../../../../../utils";
import { Status, HeatmapLegend } from "../../../../../components";

type Props = {};

const ProfitDemo = (props: Props) => {
  const { layer } = props;
  const colorMapping = getColorMapping(
    layer.name,
    layer.data.yield_env_financials.length
  );

  return (
    <React.Fragment>
      <h3>Profit Analysis *Demo*</h3>
      <HeatmapLegend
        style={{ display: "grid", gridTemplateColumns: "1fr" }}
      >
        <div
          className="HeatmapLegendTitle"
          style={{
            display: "grid",
            gridTemplateColumns: "2em 1fr 1fr 1fr 1fr"
          }}
        >
          <span />
          <span>Yield Env.</span>
          <span>% Acres</span>
          <span>Gross / Acre</span>
          <span>Net / Acre</span>
        </div>

        {layer.data.yield_env_financials
          .sort(sortYieldEnvs)
          .map((item, index) => (
            <div
              key={item.yield_env}
              style={{
                display: "grid",
                gridTemplateColumns: "2em 1fr 1fr 1fr 1fr"
              }}
            >
              <Status
                backgroundColor={colorMapping[item.yield_env]}
              />

              <span
              // style={{
              //   backgroundColor: getColorMapping("yield", 3)[item.yield_env]
              // }}
              >
                {index === 0
                  ? `\uFF1E ${item.start_range}`
                  : index + 1 ===
                    layer.data.yield_env_financials.length
                  ? `\uFF1C ${item.end_range}`
                  : `${item.start_range} - ${item.end_range}`}
              </span>
              <span>{item.percent_acres}%</span>
              <span>
                $
                {item.gross_per_acre
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </span>
              <span>
                $
                {item.net_per_acre
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </span>
            </div>
          ))}
      </HeatmapLegend>
      <span style={{ fontStyle: "italic" }}>
        *Projected costs of production based on the Iowa State
        University Extension data from:{" "}
        <a href="https://store.extension.iastate.edu/Product/fm1712-pdf">
          https://store.extension.iastate.edu/Product/fm1712-pdf
        </a>
      </span>
    </React.Fragment>
  );
};

ProfitDemo.defaultProps = {};
export default ProfitDemo;
