// @flow

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import { hasAppAttribute } from "../../../entitlements";
import * as APP_ATTRIBUTE from "../../../entitlements/appAttributes";

import {
  Header,
  Input,
  AlertBar,
  SliderModal
} from "../../../components";

import {
  validateEmail,
  validatePassword,
  validatePhone
} from "../../../utils";

type Props = {};

const sortAlphabetical = (field) => {
  return (a, b) => {
    var textA = a && a[field] ? a[field].toUpperCase() : "";
    var textB = b && b[field] ? b[field].toUpperCase() : "";
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  };
};

const sortBoolean = (field) => {
  return (a, b) => {
    return a[field] === b[field] ? 0 : a[field] ? -1 : 1;
  };
};

//TODO: List should come from database. not hardcoded
const mgfRepOptions = [
  { name: "", uid: null },
  { name: "Darin", uid: "833d4101-e58a-49f4-9a9c-57c8c4a919d8" },
  { name: "Alec", uid: "37e1b051-b5dd-4544-b469-6d029fd35d4d" },
  { name: "Cathy", uid: "d3992955-dba6-4873-b87c-ccf36c6d2feb" }
];

const findMgfRep = (uid) => {
  var name = null;
  mgfRepOptions.forEach((rep) => {
    if (uid === rep.uid) {
      name = rep.name;
    }
  });
  return name;
};

const AdminManageAccounts = (props: Props) => {
  const { users } = props.admin;
  const {
    adminGetAllUsers,
    adminUpdateUser,
    user,
    adminDeleteUser,
    resendInvite
  } = props;
  const { loading } =
    props.admin.components.get_all_users ||
    props.admin.components.update_user;

  const [isOpen, setIsOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userUid, setUserUid] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userAccountType, setUserAccountType] = useState("");
  const [userPaymentDue, setUserPaymentDue] = useState("");
  const [userMgfContactStatus, setUserMgfContactStatus] = useState(
    ""
  );
  const [userMgfFileLoading, setUserMgfFileLoading] = useState("");
  const [userAccountNotes, setUserAccountNotes] = useState("");
  const [userMgfRep, setUserMgfRep] = useState("");
  const [userUnitsOwned, setUserUnitsOwned] = useState("");
  const [userMailingAddress, setUserMailingAddress] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");

  const [validationError, setValidationError] = useState("");
  const [sortFunction, setSortFunction] = useState(() =>
    sortAlphabetical("name")
  );

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    adminGetAllUsers();
  }, [adminGetAllUsers]);

  return (
    <div className="RightPanelContent">
      <ReactTooltip
        id="AccountManagementTooltip"
        border={true}
        borderColor="#f5d870"
        className="Tooltip"
      />

      {hasAppAttribute(APP_ATTRIBUTE.MANAGE_ACCOUNTS, user) && (
        <React.Fragment>
          {" "}
          <Header title="Manage User Accounts" loading={loading} />
          {/* <span className="TotalAcres">
        <span>My total acres:</span>
        <span>{total_acres}</span>
      </span> */}
          {users && (
            <div className="DataManagement">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "1em" }}></th>
                    <th style={{ width: "1em" }}></th>

                    <th style={{ width: "1em" }}></th>
                    <th
                      className="Link"
                      data-tip="Sort by Name"
                      data-for="AccountManagementTooltip"
                      onClick={() =>
                        setSortFunction(() =>
                          sortAlphabetical("name")
                        )
                      }
                    >
                      {" "}
                      Name{" "}
                    </th>
                    <th> Email </th>
                    <th> Phone </th>
                    <th> Mailing Address </th>
                    <th
                      className="Link"
                      data-tip="Sort by role"
                      data-for="AccountManagementTooltip"
                      onClick={() =>
                        setSortFunction(() =>
                          sortAlphabetical("role")
                        )
                      }
                    >
                      {" "}
                      Role{" "}
                    </th>
                    <th
                      className="Link"
                      data-tip="Sort by Invited By"
                      data-for="AccountManagementTooltip"
                      onClick={() =>
                        setSortFunction(() =>
                          sortAlphabetical("invited_by")
                        )
                      }
                    >
                      {" "}
                      Invited By{" "}
                    </th>
                    <th
                      className="Link"
                      data-tip="Sort by Account Type"
                      data-for="AccountManagementTooltip"
                      onClick={() =>
                        setSortFunction(() =>
                          sortAlphabetical("account_type")
                        )
                      }
                    >
                      {" "}
                      Account Type{" "}
                    </th>
                    <th>Units Owned</th>
                    <th>Company Name</th>
                    <th
                      className="Link"
                      data-tip="Sort by MGF Rep"
                      data-for="AccountManagementTooltip"
                      onClick={() =>
                        setSortFunction(() =>
                          sortAlphabetical("mgf_rep")
                        )
                      }
                    >
                      {" "}
                      MGF Rep{" "}
                    </th>
                    <th
                      className="Link"
                      data-tip="Sort by payment due"
                      data-for="AccountManagementTooltip"
                      onClick={() =>
                        setSortFunction(() =>
                          sortBoolean("payment_due")
                        )
                      }
                    >
                      {" "}
                      Payment Due?{" "}
                    </th>
                    <th
                      className="Link"
                      data-tip="Sort by payment due"
                      data-for="AccountManagementTooltip"
                      onClick={() =>
                        setSortFunction(() =>
                          sortBoolean("mgf_contact_status")
                        )
                      }
                    >
                      {" "}
                      Contacted?{" "}
                    </th>
                    <th
                      className="Link"
                      data-tip="Sort by loading status"
                      data-for="AccountManagementTooltip"
                      onClick={() =>
                        setSortFunction(() =>
                          sortBoolean("mgf_file_loading")
                        )
                      }
                    >
                      {" "}
                      Needs File Loading?{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.sort(sortFunction).map((user, index) => {
                    var account_type = "";
                    switch (user.account_type) {
                      case "founding_farmer_investor":
                        account_type = "Founding Farmer Investor";
                        break;
                      case "founding_farmer_member":
                        account_type = "Founding Farmer Member";
                        break;
                      default:
                        account_type = user.account_type;
                    }
                    return (
                      <tr key={index}>
                        <td>
                          <FontAwesomeIcon
                            icon="pencil-alt"
                            className="ActionIcon"
                            data-tip="Edit line item"
                            data-for="AccountManagementTooltip"
                            onClick={() => {
                              setUserEmail(user.email);
                              setUserName(user.name);
                              setUserPhone(user.phone);
                              setUserRole(user.role);
                              setUserUid(user.uid);
                              setUserPassword(undefined);
                              setIsOpen(true);
                              setUserAccountType(user.account_type);
                              setUserAccountNotes(user.account_notes);
                              setUserMgfRep(user.mgf_rep);
                              setUserMgfFileLoading(
                                user.mgf_file_loading ? "yes" : "no"
                              );
                              setUserMgfContactStatus(
                                user.mgf_contact_status ? "yes" : "no"
                              );
                              setUserPaymentDue(
                                user.payment_due ? "yes" : "no"
                              );
                              setUserUnitsOwned(user.units_owned);
                              setUserMailingAddress(
                                user.mailing_address
                              );
                              setUserCompanyName(user.company_name);
                            }}
                          />
                        </td>
                        <td>
                          {(user.role === "nda_user" ||
                            user.role === "demo_user") && (
                            <FontAwesomeIcon
                              icon="paper-plane"
                              className="ActionIcon"
                              data-tip="Send Invite Reminder"
                              data-for="AccountManagementTooltip"
                              onClick={() => resendInvite(user.email)}
                            />
                          )}
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon="trash-alt"
                            className="ActionIcon"
                            size="1x"
                            onClick={() => adminDeleteUser(user)}
                            data-tip="Delete account"
                            data-for="AccountManagementTooltip"
                          />
                        </td>

                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>{user.mailing_address}</td>
                        <td>{user.role}</td>
                        <td>{user.invited_by}</td>
                        <td>{account_type}</td>
                        <td>{user.units_owned}</td>
                        <td>{user.company_name}</td>
                        <td>{findMgfRep(user.mgf_rep)}</td>
                        <td>{user.payment_due ? "yes" : "no"}</td>
                        <td>
                          {user.mgf_contact_status ? "yes" : "no"}
                        </td>
                        <td>
                          {user.mgf_file_loading ? "yes" : "no"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </React.Fragment>
      )}

      <SliderModal
        title="Update User"
        isOpen={isOpen}
        onOutsideClick={() => setIsOpen(false)}
        footer={
          <div
            style={{
              width: "100%",
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            <input
              type="button"
              value="Update"
              onClick={() => {
                setValidationError("");
                var validationMessage = "";

                var validateEmailResult = validateEmail(userEmail);
                if (!validateEmailResult.status) {
                  validationMessage =
                    validationMessage + validateEmailResult.message;
                }
                var validatePhoneResult = validatePhone(userPhone);
                if (!validatePhoneResult.status) {
                  validationMessage =
                    validationMessage + validatePhoneResult.message;
                }
                if (userPassword) {
                  var validatePasswordResult = validatePassword(
                    userPassword
                  );
                  if (!validatePasswordResult.status) {
                    validationMessage =
                      validationMessage +
                      validatePasswordResult.message;
                  }
                }

                if (validationMessage !== "") {
                  setValidationError(validationMessage);
                } else {
                  var user = {
                    name: userName,
                    email: userEmail,
                    phone: userPhone,
                    role: userRole,
                    uid: userUid,
                    new_password: userPassword,
                    account_type: userAccountType,
                    account_notes: userAccountNotes,
                    payment_due:
                      userPaymentDue === "yes" ? true : false,
                    mgf_contact_status:
                      userMgfContactStatus === "yes" ? true : false,
                    mgf_file_loading:
                      userMgfFileLoading === "yes" ? true : false,
                    mgf_rep: userMgfRep,
                    units_owned: userUnitsOwned,
                    mailing_address: userMailingAddress,
                    company_name: userCompanyName
                  };

                  adminUpdateUser(user);
                  setIsOpen(false);
                  setUserEmail("");
                  setUserName("");
                  setUserPhone("");
                  setUserRole("");
                  setUserUid("");
                  setUserPassword("");
                  setUserPaymentDue("");
                  setUserMgfContactStatus("");
                  setUserMgfFileLoading("");
                  setUserAccountType("");
                  setUserAccountNotes("");
                  setUserMgfRep("");
                  setUserUnitsOwned("");
                  setUserMailingAddress("");
                  setUserCompanyName("");
                }
              }}
            />

            <input
              type="button"
              value="Cancel"
              onClick={() => setIsOpen(false)}
            />
          </div>
        }
      >
        <form>
          {validationError && (
            <AlertBar>
              {validationError ? (
                <div>
                  {validationError.split("\n").map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
              {/* {error} */}
            </AlertBar>
          )}
          <label>
            <span>Name:</span>

            <Input
              name="name"
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </label>
          <label>
            <span>Email:</span>

            <Input
              name="email"
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </label>

          <label>
            <span>Phone:</span>

            <Input
              name="phone"
              type="text"
              mask="+1 (000) 000-0000"
              value={userPhone}
              onChange={(e) => setUserPhone(e.target.value)}
            />
          </label>

          <label>
            <span>Password:</span>
            <Input
              name="password"
              type="password"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
            />
          </label>

          <label>
            <span>Role:</span>
            <select
              value={userRole}
              onChange={(evt) => {
                setUserRole(evt.target.value);
              }}
              disabled={
                userRole === "demo_user" || userRole === "nda_user"
              } //Can't change these unless they've upgrade to at least a farmer account (must sign NDA)
            >
              {[
                "demo_user",
                "nda_user",
                "farmer",
                "tech_farmer",
                "seed_dealer_farmer",
                "scientist",
                "key_collaborator",
                "ag_professional",
                "farmer_supporter",
                "admin"
              ].map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </label>

          <label>
            <span>Mailing Address:</span>
            <textarea
              rows="3"
              name="mailing_address"
              value={userMailingAddress}
              onChange={(e) => setUserMailingAddress(e.target.value)}
            />
          </label>

          <label>
            <span>Account Type:</span>
            <select
              value={userAccountType}
              onChange={(evt) => {
                setUserAccountType(evt.target.value);
              }}
            >
              {[
                "free",
                "founding_farmer_member",
                "founding_farmer_investor"
              ].map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </label>

          <label>
            <span>Company Name:</span>

            <Input
              name="company_name"
              type="text"
              value={userCompanyName}
              onChange={(e) => setUserCompanyName(e.target.value)}
            />
          </label>

          <label>
            <span>Units Owned:</span>

            <Input
              name="units_owned"
              type="number"
              value={userUnitsOwned}
              onChange={(e) => setUserUnitsOwned(e.target.value)}
            />
          </label>

          <label>
            <span>Payment Due:</span>
            <select
              value={userPaymentDue}
              onChange={(evt) => {
                setUserPaymentDue(evt.target.value);
              }}
            >
              {["yes", "no"].map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </label>

          <label>
            <span>My Gold Farm Representative:</span>
            <select
              value={userMgfRep}
              onChange={(evt) => {
                setUserMgfRep(evt.target.value);
              }}
            >
              {mgfRepOptions.map((option) => {
                return (
                  <option key={option.uid} value={option.uid}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </label>

          <label>
            <span>My Gold Farm Contacted?:</span>
            <select
              value={userMgfContactStatus}
              onChange={(evt) => {
                setUserMgfContactStatus(evt.target.value);
              }}
            >
              {["yes", "no"].map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </label>

          <label>
            <span>Needs File Loading?:</span>
            <select
              value={userMgfFileLoading}
              onChange={(evt) => {
                setUserMgfFileLoading(evt.target.value);
              }}
            >
              {["yes", "no"].map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </label>

          <label style={{ height: "8em" }}>
            <span>Account Notes:</span>

            <textarea
              rows="5"
              name="account_notes"
              value={userAccountNotes}
              onChange={(e) => setUserAccountNotes(e.target.value)}
            />
          </label>
        </form>
      </SliderModal>
    </div>
  );
};

AdminManageAccounts.defaultProps = {};
export default AdminManageAccounts;
