const typeaheadOptions = {
  tillage: [
    "Chopping cornstalks, /acre",
    "Moldboard plowing, /acre",
    "Chisel plowing, /acre",
    "Disk/chiseling, /acre",
    "Vertical tillage, /acre",
    "Strip tillage, /acre",
    "Subsoiling (8 to 15 in. deep), /acre",
    "V-ripping (over 15 in. deep), /acre",
    "V-ripping with tandem disk, /acre",
    "Disking tandem, /acre",
    "Disking heavy or offset, /acre",
    "Harrowing or dragging, /acre",
    "Soil finishing, /acre",
    "Field cultivating, /acre",
    "Rock picking, /acre",
    "Cultivating conventional, /acre",
    "Cultivating ridge-till, /acre",
    "Rotary hoeing, /acre",
    "Land rolling, /acre"
  ],
  planting: [
    "Hybrid",
    "Product",
    "Planting with fertilizer & insecticide attachments",
    "Planting without attachments",
    "Planting with splitters & attachments",
    "Planting with high-speed planter ",
    "Planting - no-till planter",
    "Planting - no-till planter with splitters",
    "Planting - ridge till planter",
    "Planting – Extra charge for seed shut-offs",
    "Planting – Extra charge for variable rate seeding",
    "Planting – Extra charge for GPS use if used for analytics",
    "Seed tender",
    "Drilling soybeans",
    "Drilling soybeans no-till",
    "Drilling grass seed",
    "Drilling small grain",
    "Seeding grass broadcast with tractor",
    "Seeding grass broadcast with ATV"
  ],
  fertility: [
    "Product",
    "Ground, broadcast, tractor",
    "Ground, incorporated, tractor",
    "Ground, broadcast, self propelled",
    "Ground, banded, tractor",
    "Aerial"
  ],
  pesticide_application: [
    "Product",
    "Ground, broadcast, tractor",
    "Ground, incorporated, tractor",
    "Ground, broadcast, self propelled",
    "Ground, banded, tractor",
    "Aerial"
  ],
  harvest: [
    "Complete Corn Harvesting",
    "Complete Soybean Harvesting",
    "Corn combining",
    "Corn combining - with chopper head",
    "Soybean combining",
    "Soybean combining - with air reel",
    "Soybean combining - with draper head",
    "Small grain combining",
    "Picking ear corn (seed corn)",
    "Picking ear corn (farm use)"
  ],
  units: [
    "fluid ounces",
    "weight ounces",
    "pounds",
    "pints",
    "quarts",
    "gallons",
    "tons",
    "grams",
    "kilograms"
  ],

  /***********************NEW STUFF */
  fertilizer_products: [
    "MAP",
    "DAP",
    "Potash",
    "Lime",
    "AMS",
    "MES",
    "Elemental S",
    "10-34-0",
    "3-18-18",
    "6-24-6",
    "9-18-9",
    "32% UAN",
    "28% UAN",
    "ATS",
    "KTS",
    "Chelated Zn",
    "Triple Super Phospate",
    "Urea",
    "NH3"
  ],
  commodity_products: ["CORN", "SOYBEANS"],
  fungicide_products: [
    "Headline",
    "Headline AMP",
    "Stratego",
    "Stratego YLD",
    "Trivapro",
    "Miravis Neo",
    "Miravis Top",
    "Delaro",
    "Approach Prima",
    "Veltyma",
    "Revytek",
    "Quilt XL",
    "Proline",
    "Folicur",
    "Domark",
    "Fortix",
    "Lucento",
    "Priaxor"
  ],
  "cover crops_products": [
    "Cereal Rye",
    "Annual Rye",
    "Tillage Radish",
    "Oats"
  ],

  quickform: {
    "seed treatments": {
      Fungicide: { timing: ["Planting"] },
      Insecticide: { timing: ["Planting"] },
      "SDS Treatment": { timing: ["Planting"] },
      Inoculant: { timing: ["Planting"] }
    },
    tillage: {
      "Disk ripper/Chisel": {
        timing: ["Fall", "Spring"]
      },
      "Sub Tiller": {
        timing: ["Fall", "Spring"]
      },
      "Strip till": {
        timing: ["Fall", "Spring", "Fall and Spring"]
      },
      "No till": { timing: [] },
      "Field cultivate": { timing: ["Fall", "Spring"] },
      "Vertical tillage": { timing: ["Fall", "Spring"] }
    },
    "cover crops": {
      "cover crops": {
        timing: [
          "Fall plant – early spring kill",
          "Fall Plant – late spring kill",
          "Fall Plant – winter kill",
          "Summer plant – early spring kill",
          "Summer plant – late spring kill",
          "Summer plant – winter kill"
        ],
        products: {
          "Cereal Rye": "lbs",
          "Annual Rye": "lbs",
          "Tillage Radish": "lbs",
          Oats: "lbs"
        }
      }
    },
    foliar: {
      "Foliar application": {
        products: {
          N: "lbs",
          P: "lbs",
          K: "lbs",
          S: "lbs",
          Mg: "lbs",
          Zn: "lbs",
          B: "lbs",
          Fe: "lbs",
          Mn: "lbs",
          Al: "lbs",
          Mo: "lbs",
          Cu: "lbs",
          Cl: "lbs",
          "Humic Acid": "lbs",
          "Fulvic Acid": "lbs",
          PGR: "lbs",
          Sugar: "lbs"
        },
        timing: [
          "Soybean V stage",
          "Soybean R1",
          "Soybean R2",
          "Soybean R3",
          "Soybean R4",
          "Soybean R5",
          "Corn V1-V6",
          "Corn V7-V15",
          "Corn VT",
          "Corn R1-R3",
          "Corn R4-R5"
        ]
      }
    },
    fertilizer: {
      Anhydrous: {
        timing: ["Fall", "Spring"],

        products: {
          NH3: "lbs"
        }
      },
      "Dry Broadcast application": {
        timing: ["Fall", "Spring"],
        products: {
          MAP: "lbs",
          DAP: "lbs",
          Potash: "lbs",
          Lime: "lbs",
          AMS: "lbs",
          MES: "lbs",
          "Elemental S": "lbs",
          "Triple Super Phospate": "lbs",
          Urea: "lbs"
        }
      },
      "Dry Band": {
        timing: ["Fall", "Spring"],
        products: {
          MAP: "lbs",
          Potash: "lbs",
          "Elemental S": "lbs",
          AMS: "lbs",
          DAP: "lbs",
          MES: "lbs",
          "Triple Super Phospate": "lbs",
          Urea: "lbs"
        }
      },
      "Liquid band at planting": {
        timing: [],
        products: {
          "10-34-0": "gal",
          "3-18-18": "gal",
          "6-24-6": "gal",
          "9-18-9": "gal",
          "32% UAN": "gal",
          "28% UAN": "gal",
          ATS: "gal",
          "Chelated Zn": "qt"
        }
      },
      "Liquid soil application": {
        timing: ["Spring broadcast", "Y drop"],
        products: {
          "28% UAN": "gal",
          "32% UAN": "gal",
          ATS: "gal",
          KTS: "gal"
        }
      }
    },
    herbicide: {
      herbicide: {
        timing: ["Preplant/Preemerge", "Post Emerge", "Rescue"],
        products: []
      }
    },
    insecticide: {
      insecticide: {
        timing: [
          "Soybean V stage",
          "Soybean R1",
          "Soybean R2",
          "Soybean R3",
          "Soybean R4",
          "Soybean R5",
          "Corn V1-V6",
          "Corn V7-V15",
          "Corn VT",
          "Corn R1-R3",
          "Corn R4-R5"
        ],
        products: []
      }
    },

    fungicide: {
      fungicide: {
        timing: [
          "Soybean V stage",
          "Soybean R1",
          "Soybean R2",
          "Soybean R3",
          "Soybean R4",
          "Soybean R5",
          "Corn V1-V6",
          "Corn V7-V15",
          "Corn VT",
          "Corn R1-R3",
          "Corn R4-R5"
        ],
        products: {
          Headline: "oz",
          "Headline AMP": "oz",
          Stratego: "oz",
          "Stratego YLD": "oz",
          Trivapro: "oz",
          "Miravis Neo": "oz",
          "Miravis Top": "oz",
          Delaro: "oz",
          "Aproach Prima": "oz",
          Veltyma: "oz",
          Revytek: "oz",
          "Quilt XL": "oz",
          Proline: "oz",
          Folicur: "oz",
          Domark: "oz",
          Fortix: "oz",
          Lucento: "oz",
          Priaxor: "oz",
          "Delaro Complete": "oz",
          Tilt: "oz"
        }
      }
    }
  }
};

export default typeaheadOptions;
