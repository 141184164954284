// @flow

import React from "react";

import { getColorMapping, sortYieldEnvs } from "../../../../../utils";
import {
  Status,
  Header,
  HeatmapLegend
} from "../../../../../components";
import { SliderControl } from "./";

type Props = {};

const calculateVariableRateCost = (
  rates,
  nutrientPrices,
  nutrientRatio
) => {
  var variableRateCost = 0;
  var envCount = rates.length;
  if (rates && nutrientRatio && nutrientPrices) {
    rates.forEach((env) => {
      variableRateCost +=
        (env.N / nutrientRatio.N) * nutrientPrices.N;
      variableRateCost +=
        (env.P2O5 / nutrientRatio.P2O5) * nutrientPrices.P2O5;
      variableRateCost +=
        (env.K2O / nutrientRatio.K2O) * nutrientPrices.K2O;
    });
    variableRateCost = variableRateCost / envCount;
  }
  return variableRateCost.toFixed(2);
};

const calculateFlatRateCost = (
  rates,
  nutrientPrices,
  nutrientRatio
) => {
  var flatRateCost = 0;
  var envCount = rates.length;

  if (envCount && nutrientRatio && nutrientPrices) {
    flatRateCost +=
      (rates[envCount - 1].N / nutrientRatio.N) * nutrientPrices.N;
    flatRateCost +=
      (rates[envCount - 1].P2O5 / nutrientRatio.P2O5) *
      nutrientPrices.P2O5;
    flatRateCost +=
      (rates[envCount - 1].K2O / nutrientRatio.K2O) *
      nutrientPrices.K2O;
  }
  return flatRateCost.toFixed(2);
};

const calculateVariableRateSavings = (
  flatRateCost,
  variableRateCost
) => {
  return (flatRateCost - variableRateCost).toFixed(2);
};

class Rx extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    const { layer } = props;

    let rates = layer ? layer.data.rates.sort(sortYieldEnvs) : [];
    let nutrientPrices = layer
      ? layer.data.nutrientPrices
      : undefined;
    let nutrientRatio = layer ? layer.data.nutrientRatio : undefined;

    var flatRateCost = calculateFlatRateCost(
      rates,
      nutrientPrices,
      nutrientRatio
    );
    var variableRateCost = calculateVariableRateCost(
      rates,
      nutrientPrices,
      nutrientRatio
    );
    var variableRateSavings = calculateVariableRateSavings(
      flatRateCost,
      variableRateCost
    );
    this.state = {
      recommendedRates: [...rates],
      rates: [...rates],
      nutrientPrices: nutrientPrices,
      nutrientRatio: nutrientRatio,
      flatRateCost: flatRateCost,
      variableRateCost: variableRateCost,
      variableRateSavings: variableRateSavings,
      activeYieldEnv: undefined,
      activeFieldId: props.app.activeFieldId
    };

    this.calculateSavings();
  }

  static getDerivedStateFromProps(props, state) {
    //if field id is changing, and has Rx, recalculate values
    //if field id is changing., and NOT has Rx, set to 0
    //if field id is not changing, but has Rx already, don't recalculate
    //if field id is not changing, from no Rx to Rx available, recalculate
    let fieldIdChanged =
      props.app.activeFieldId !== state.activeFieldId;
    let hasRxAlready = state.rates.length > 0;

    const { layer } = props;

    //if field id is changing, and has Rx, recalculate values
    //if field id is not changing, from no Rx to Rx available, recalculate

    if (
      (fieldIdChanged && layer.data) ||
      (!fieldIdChanged && !hasRxAlready)
    ) {
      let rates = layer ? layer.data.rates.sort(sortYieldEnvs) : [];
      let nutrientPrices = layer
        ? layer.data.nutrientPrices
        : undefined;
      let nutrientRatio = layer
        ? layer.data.nutrientRatio
        : undefined;

      let flatRateCost = calculateFlatRateCost(
        rates,
        nutrientPrices,
        nutrientRatio
      );
      let variableRateCost = calculateVariableRateCost(
        rates,
        nutrientPrices,
        nutrientRatio
      );
      let variableRateSavings = calculateVariableRateSavings(
        flatRateCost,
        variableRateCost
      );

      if (rates.length > 0) {
        return {
          recommendedRates: [...rates],
          rates: [...rates],
          nutrientPrices: nutrientPrices,
          nutrientRatio: nutrientRatio,
          flatRateCost: flatRateCost,
          variableRateCost: variableRateCost,
          variableRateSavings: variableRateSavings,
          activeFieldId: props.app.activeFieldId
        };
      }
      //if field id is changing., and NOT has Rx, set to 0
    } else if (fieldIdChanged && !layer) {
      return {
        recommendedRates: [],
        rates: [],
        nutrientPrices: undefined,
        nutrientRatio: undefined,
        flatRateCost: undefined,
        variableRateCost: undefined,
        variableRateSavings: undefined,
        activeFieldId: props.app.activeFieldId
      };
    }
    //if field id is not changing, but has Rx already, don't recalculate

    return state;
  }

  modifyQuantities(yieldEnv, nutrient, value) {
    const { recommendedRates, rates } = this.state;

    var newRates = Object.assign(rates);

    if (typeof yieldEnv != "undefined") {
      newRates[yieldEnv] = {
        ...newRates[yieldEnv],
        [nutrient]:
          Math.round(recommendedRates[yieldEnv][nutrient] * value) /
          100
      };
    } else {
      this.state.rates.map((item, index) => {
        newRates[index] = {
          ...newRates[index],
          [nutrient]:
            Math.round(recommendedRates[index][nutrient] * value) /
            100
        };
        return true;
      });
    }

    this.setState(
      {
        rates: newRates
      },
      () => this.calculateSavings()
    );
  }

  calculateSavings() {
    const { rates, nutrientPrices, nutrientRatio } = this.state;

    var variableRateCost = calculateVariableRateCost(
      rates,
      nutrientPrices,
      nutrientRatio
    );
    var flatRateCost = calculateFlatRateCost(
      rates,
      nutrientPrices,
      nutrientRatio
    );
    var variableRateSavings = calculateVariableRateSavings(
      flatRateCost,
      variableRateCost
    );

    this.setState({
      variableRateSavings: variableRateSavings,
      flatRateCost: flatRateCost,
      variableRateCost: variableRateCost
    });
  }

  selectYieldEnvironment(env) {
    this.setState({
      activeYieldEnv:
        env === this.state.activeYieldEnv ? undefined : env
    });
  }

  render() {
    let props = this.props;
    const { activeFieldId, activeFieldName } = props.app;

    const { downloadPrescriptionShapefile } = props;

    const {
      download_prescription_shapefile
    } = props.fields.components;

    const {
      rates,
      recommendedRates,
      variableRateCost,
      flatRateCost,
      variableRateSavings,
      activeYieldEnv
    } = this.state;

    const colorMapping = getColorMapping(
      "yield",
      recommendedRates.length
    );

    return (
      <React.Fragment>
        <span className="ReportInfo">
          {activeFieldName ? (
            <React.Fragment>
              <span>Selected Field: </span>{" "}
              <span> {activeFieldName} </span>
            </React.Fragment>
          ) : (
            <div>Select a field to get started.</div>
          )}
        </span>
        {activeFieldId && recommendedRates.length > 0 ? (
          <React.Fragment>
            <span className="ReportInfo">
              <span>Selected Yield Environment: </span>{" "}
              <span>
                {" "}
                {typeof activeYieldEnv !== undefined
                  ? activeYieldEnv
                  : "ALL"}{" "}
              </span>{" "}
            </span>
            <span className="ReportInfo">
              Click on a yield environment below to adjust it
              individually.{" "}
            </span>

            <HeatmapLegend
              style={{ display: "grid", gridTemplateColumns: "1fr" }}
            >
              <div
                className="HeatmapLegendTitle"
                style={{
                  display: "grid",
                  gridTemplateColumns: "2em 1fr 1fr 1fr "
                }}
              >
                <span />
                <span>N</span>
                <span>P2O5</span>
                <span>K2O</span>

                {/* <span>S</span> */}
              </div>
              {rates.map((item, index) => (
                <div
                  onClick={() => this.selectYieldEnvironment(index)}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "2em 1fr 1fr 1fr "
                  }}
                  className={index === activeYieldEnv ? "active" : ""}
                >
                  <Status
                    backgroundColor={colorMapping[item.yield_env]}
                  />
                  <span
                    className={
                      item.N !== recommendedRates[index].N
                        ? "Modified"
                        : ""
                    }
                  >
                    {item.N}
                  </span>
                  <span
                    className={
                      item.P2O5 !== recommendedRates[index].P2O5
                        ? "Modified"
                        : ""
                    }
                  >
                    {item.P2O5}
                  </span>
                  <span
                    className={
                      item.K2O !== recommendedRates[index].K2O
                        ? "Modified"
                        : ""
                    }
                  >
                    {item.K2O}
                  </span>
                  {/* <span
                    className={
                      item.S !== recommendedRates[item.yield_env - 1].S
                        ? "Modified"
                        : ""
                    }
                  >
                    {item.S}
                  </span> */}
                </div>
              ))}
              <span style={{ fontStyle: "italic" }}>
                {" "}
                Rx given in lbs per acre.
              </span>
            </HeatmapLegend>

            <div className="SliderWrapper" key={activeFieldId}>
              {/* <Header title="Adjust Quantities" /> */}

              <span
                className="Slider"
                style={{ fontWeight: "bolder" }}
              >
                <span>Nut.</span>
                <span>Amt.</span>
                <span>Adjustment</span>
              </span>

              <SliderControl
                label="N"
                value={100}
                onChange={(val) =>
                  this.modifyQuantities(activeYieldEnv, "N", val)
                }
              />
              <SliderControl
                label="P2O5"
                value={100}
                onChange={(val) =>
                  this.modifyQuantities(activeYieldEnv, "P2O5", val)
                }
              />
              <SliderControl
                label="K2O"
                value={100}
                onChange={(val) =>
                  this.modifyQuantities(activeYieldEnv, "K2O", val)
                }
              />
              {/* <SliderControl
                label="S"
                value={100}
                onChange={val =>
                  this.modifyQuantities(activeYieldEnv, "S", val)
                }
              /> */}
            </div>
            <span className="ReportInfo">
              <span>Flat Rate Rx Cost (per acre): </span>{" "}
              <span>${flatRateCost} </span>
            </span>
            <span className="ReportInfo">
              <span>Variable Rate Rx Cost (per acre): </span>
              <span>${variableRateCost} </span>
            </span>
            <span className="ReportInfo">
              <span>Savings (per acre): </span>{" "}
              <span> ${variableRateSavings} </span>
            </span>
            <span className="ReportInfo">
              <input
                type="button"
                value="Download"
                onClick={() =>
                  downloadPrescriptionShapefile(
                    activeFieldId,
                    activeFieldName,
                    rates
                  )
                }
              />
              <Header
                loading={download_prescription_shapefile.loading}
                success={download_prescription_shapefile.success}
                error={download_prescription_shapefile.error}
              />
            </span>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

Rx.defaultProps = {};
export default Rx;
